import { toastr } from "react-redux-toastr";
import { client } from "utils/api";
import { removeCookie, setCookie } from "utils/cookies";

export const handleLogin = async (values) => {
  try {
    const { auth_token } = await client.post(
      `/users/sign_in`,
      values
    );

    await setCookie("token", auth_token);
    window.location.pathname = "/";
  } catch (error) {
    toastr.error(
      "Błąd",
      "Wystąpił błąd podzczas logowania"
    );
  }
};
export const handleLogout = async () => {
  await removeCookie("token");
  window.location.pathname = "/";
};
