import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "expano-components";
import { Modal, AsyncSelect } from "components";
import { addPropertySettingToProductGroup } from "actions/product_group/property_settings";
import getPropertyOptions from "utils/select_options/getPropertyOptions";

const AddPropertyModal = ({
  product_group_id,
  handleClose,
  addPropertySettingToProductGroup,
}) => {
  const [is_loading, setLoading] = useState(false);
  const [property_id, setPropertyId] = useState(null);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await addPropertySettingToProductGroup({
        product_group_id,
        property_id,
      });
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <Modal
      header={{ title: "Dodaj kolejną właściwość" }}
      handleClose={handleClose}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="add-green"
            disabled={!property_id || is_loading}
            text={is_loading ? "Proszę czekać..." : "Dodaj"}
            onClick={handleSubmit}
          />
        </>
      }>
      <AsyncSelect
        label="Właściwość"
        onChange={({ value }) => setPropertyId(value)}
        getData={getPropertyOptions}
      />
    </Modal>
  );
};

export default connect(
  ({ product_group }) => ({
    product_group_id: product_group?.data?.id,
  }),
  (dispatch) => ({
    addPropertySettingToProductGroup: (data) =>
      dispatch(addPropertySettingToProductGroup(data)),
  })
)(AddPropertyModal);
