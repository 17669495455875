import { toastr } from "react-redux-toastr";
import { client } from "utils/api";

export const getChannelProductGroup = (id) => async (
  dispatch,
  getState
) => {
  const { status } = getState().channel_product_group;
  try {
    if (status === "invalid") {
      dispatch({ type: "CHANNEL_PRODUCT_GROUP_LOADING" });
    }
    const { data } = await client.get(
      `/channel_product_groups/${id}?context=ui_product_group_cards`
    );
    dispatch({
      type: "CHANNEL_PRODUCT_GROUP_SUCCESS",
      data,
    });
  } catch (error) {
    dispatch({ type: "CHANNEL_PRODUCT_GROUP_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas pobierania"
    );
  }
};

export const resetChannelProductGroup = () => (dispatch) =>
  dispatch({ type: "CHANNEL_PRODUCT_GROUP_RESET" });

export const updateChannelProductGroup = (
  id,
  values
) => async (dispatch) => {
  try {
    await client.put(
      `/channel_product_groups/${id}`,
      values
    );
    dispatch(getChannelProductGroup(id));
    toastr.success("Sukces", "Zaktualizowano pomyślnie");
  } catch (error) {
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas aktualizowania"
    );
  }
};

export const updateChannelProduct = async (
  id,
  { name, subname, keyword_list }
) => {
  try {
    await client.put(`/channel_products/${id}`, {
      name,
      subname,
      keyword_list,
    });
  } catch (error) {
    console.log(error);
  }
};