const initialState = {
  status: "invalid",
  data: null,
};

const prepareProducts = (data) => {
  const products = [...data.products]
    .filter(({ status }) => status !== "discontinued")
    .sort((a, b) =>
      a.sku.localeCompare(b.sku, undefined, {
        numeric: true,
        sensitivity: "base",
      })
    );

  const channel_product_groups = [
    ...data.channel_product_groups,
  ].sort((a, b) =>
    a.channel?.identifier?.localeCompare(
      b.channel?.identifier
    )
  );

  return { ...data, channel_product_groups, products };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "PRODUCT_GROUP_RESET":
      return {
        status: "invalid",
      };
    case "PRODUCT_GROUP_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "PRODUCT_GROUP_SUCCESS":
      return {
        ...state,
        status: "success",
        data: prepareProducts(action.data),
      };
    case "PRODUCT_GROUP_UPDATE_MOCKUP":
      return {
        ...state,
        data: { ...state.data, mockup: action.mockup },
      };
    case "PRODUCT_GROUP_FAILURE":
      return {
        ...state,
        status: "failure",
        err: action.err,
      };

    default:
      return state;
  }
};
