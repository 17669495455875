import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ReactComponent as PIcon } from "icons/p-square.svg";
import { ReactComponent as PgIcon } from "icons/pg-square.svg";

const AsidePanel = ({
  product_group,
  history,
  match: {
    params: { product_group_id, type, type_id },
  },
}) => {
  return (
    <ul className="product-list">
      {product_group && (
        <li
          className={`product-list__item ${
            !type ? "active" : ""
          }`}
          onClick={() =>
            history.push(
              `/product_groups/${product_group_id}/images`
            )
          }>
          <div className="item-icon">
            <PgIcon />
          </div>
          <div className="item-text">
            <p className="title">{product_group?.name}</p>
            <p>{product_group?.sku}</p>
          </div>
        </li>
      )}
      {product_group.products?.length > 0 &&
        product_group.products.map(({ id, name, sku }) => (
          <li
            className={`product-list__item ${
              type === "products" && Number(type_id) === id
                ? "active"
                : ""
            }`}
            key={id}
            onClick={() =>
              history.push(
                `/product_groups/${product_group_id}/images/products/${id}`
              )
            }>
            <div className="item-icon">
              <PIcon />
            </div>
            <div className="item-text">
              <p className="title">{name}</p>
              <p>{sku}</p>
            </div>
          </li>
        ))}
    </ul>
  );
};

export default connect(
  ({ product_group: { data: product_group } }) => ({
    product_group,
  }),
  null
)(withRouter(AsidePanel));
