import React from "react";
import { Button } from "expano-components";
import { Images, Text, Header } from "../components";

const ImageTextSection = ({
  id,
  position,
  content,
  type,
  description_section_comments,
  description_section_products,
  handleDelete,
  disabled,
  inherited,
}) => {
  const images_data = {
    description_section_products: description_section_products?.filter(
      ({ product: { status } }) => status !== "discontinued"
    ),
  };

  return (
    <>
      <div
        className={`dashboard product_card_section ${
          disabled ? "disabled" : ""
        }`}>
        <Header
          id={id}
          attributes={{ position, type }}
          inherited={inherited}
        />
        <div className="product_card_section__content product_card_section__group">
          <div className="dashboard__form">
            <div className="product_card_section__wrapper">
              <Images {...images_data} />
              <div className="section-group__text">
                <Text
                  form={`image_text_${id}`}
                  content={content}
                  initialValues={{ content }}
                  id={id}
                  position={position}
                  description_section_comments={
                    description_section_comments
                  }
                  inherited={inherited}
                />
              </div>
            </div>
          </div>
          <div className="dashboard__form__footer">
            <div className="box">
              <Button
                type="delete"
                text="Usuń"
                onClick={() =>
                  handleDelete({ id, position })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageTextSection;
