import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { Button } from "expano-components";

import Upload from "./components/Upload";
import GalleryGrid from "./components/GalleryGrid";

import { ReactComponent as GalleryIcon } from "icons/gallery.svg";
import { ReactComponent as UploadIcon } from "icons/upload.svg";

const tabs = [
  {
    id: "gallery",
    icon: <GalleryIcon />,
    name: "Wybierz z bazy zdjęć",
  },
  {
    id: "new",
    icon: <UploadIcon />,
    name: "Prześlij nowe",
  },
];

const GalleryUpload = (props) => {
  const [active, setActive] = useState(false);
  const [active_tab, setTab] = useState("gallery");

  useEffect(() => {
    setTimeout(() => setActive(true), 250);
  }, []);

  return ReactDOM.createPortal(
    <div className="popup-wrapper">
      <div
        className={classNames("popup-gallery", { active })}>
        <div className="popup-gallery__container">
          <header className="popup-gallery__header">
            <ul className="popup-gallery__header__tabs">
              {tabs.map(({ id, name, icon }) => (
                <li
                  key={id}
                  className={`popup-gallery__header__tab ${
                    active_tab === id ? "active" : ""
                  }`}>
                  <button
                    onClick={() => setTab(id)}
                    type="button">
                    {icon}
                    <span>{name}</span>
                  </button>
                </li>
              ))}
            </ul>

            <Button
              type="close"
              className="btn btn-icon btn-close"
              onClick={props.handleClose}
            />
          </header>
          {active_tab === "gallery" ? (
            <GalleryGrid {...props} />
          ) : (
            <Upload {...props} />
          )}
        </div>
      </div>
    </div>,
    document.querySelector("#root")
  );
};
export default GalleryUpload;
