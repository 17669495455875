import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ProductPropertiesForm from "./components/ProductPropertiesForm";
import ProductPackageForm from "./components/ProductPackageForm";

const getBundleWeight = (product) => {
  if (product?.is_bundle && product?.components.length > 0) {
    return product.components
      .map((item) => {
        const weight = item.weight ? parseFloat(item.weight) : 0;
        const quantity =
          product.product_components_as_bundle.find(
            ({ component_id }) => component_id === item.id
          )?.quantity || 0;
        return quantity * weight;
      })
      .reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0)
      .toFixed(2);
  }

  return null;
};

const ProductPanel = ({
  products,
  match: {
    params: { type_id },
  },
}) => {
  const product = products.find(({ id }) => id === Number(type_id));

  if (!product) {
    return null;
  }

  const bundle_weight = getBundleWeight(product);

  return (
    <div className="create-table">
      {product.product_properties?.length > 0 ? (
        <ProductPropertiesForm data={product.product_properties} />
      ) : null}
      <ProductPackageForm
      key={type_id }
        form={`product-package-${product.id}`}
        product_id={product.id}
        bundle_weight={bundle_weight}
        enableReinitialize={true}
        initialValues={{
          weight: product.weight,
          package_height: product.package_height,
          package_width: product.package_width,
          package_depth: product.package_depth,
          gpc_code: product.gpc_code,
          price_group_label: product.price_group_label,
        }}
      />
    </div>
  );
};

export default connect(
  ({
    product_group: {
      data: { products },
    },
  }) => ({
    products,
  })
)(withRouter(ProductPanel));
