import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { ReactComponent as PIcon } from "icons/p-square.svg";
import { ReactComponent as PgIcon } from "icons/pg-square.svg";
import { ReactComponent as PlatformIcon } from "icons/platform.svg";

const AsidePanel = ({
  product_group,
  history,
  match: { params },
}) => {
  return (
    <ul className="product-list">
      <li
        className={`product-list__item ${
          params.type === "properties" ? "active" : ""
        }`}
        onClick={() =>
          history.push(
            `/product_groups/${product_group.id}/specification/properties`
          )
        }>
        <div className="item-icon">
          <PlatformIcon />
        </div>
        <div className="item-text">
          <p className="title">Właściwości</p>
        </div>
      </li>
      <li
        className={`product-list__item ${
          params.type === "product_group" ? "active" : ""
        }`}
        onClick={() =>
          history.push(
            `/product_groups/${product_group.id}/specification/product_group`
          )
        }>
        <div className="item-icon">
          <PgIcon />
        </div>
        <div className="item-text">
          <p className="title">{product_group.name}</p>
          <p>{product_group.sku}</p>
        </div>
      </li>
      {product_group.products.length > 0 &&
        product_group.products.map(({ id, name, sku }) => (
          <li
            className={`product-list__item ${
              params.type === "products" &&
              Number(params.type_id) === id
                ? "active"
                : ""
            }`}
            key={id}
            onClick={() =>
              history.push(
                `/product_groups/${product_group.id}/specification/products/${id}`
              )
            }>
            <div className="item-icon">
              <PIcon />
            </div>
            <div className="item-text">
              <p className="title">{name}</p>
              <p>{sku}</p>
            </div>
          </li>
        ))}
    </ul>
  );
};

export default connect(
  ({ product_group: { data: product_group } }) => ({
    product_group,
  }),
  null
)(withRouter(AsidePanel));
