import React from "react";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { PanelLoader } from "components";
import ProductExportLine from "./components/ProductExportLine";
import useQueryHook from "utils/useQueryHook";
import { stringifyQuery } from "utils/api";

const query = {
  context: "ui_export",
  "q[channel_platform_type_in]": [
    "Wsf::Platform",
    "Allegro::Platform",
  ],
  per_page: 30,
};

const ProductExport = ({
  match: {
    params: { type_id },
  },
}) => {
  const {
    is_loading,
    is_fetching,
    data: { data: channel_products },
    refetch,
  } = useQueryHook({
    query_keys: [type_id],
    path: `/channel_products?${stringifyQuery({
      ...query,
      "q[product_id_eq]": type_id,
    })}`,
  });

  if (is_loading) {
    return <PanelLoader />;
  }

  const product_channel_products = channel_products.sort(
    (item, next) => item.channel.id - next.channel.id
  );

  return (
    <div
      className={classNames("dashboard box-full", {
        fetching: is_fetching,
      })}>
      <div className="table-export-status">
        <table className="table">
          <thead className="table__header table-head">
            <tr>
              <th>Kanał</th>
              <th>Synchronizacja oferty</th>
              <th width="180px">Sprzedaż</th>
              <th width="35px" />
            </tr>
          </thead>
          {product_channel_products.length > 0 && (
            <tbody className="table__body table-body">
              {product_channel_products
                .sort((a, b) =>
                  a.channel?.platform?.label_text?.localeCompare(
                    b.channel?.platform?.label_text,
                    undefined,
                    {
                      numeric: true,
                      sensitivity: "base",
                    }
                  )
                )
                .map((item) => (
                  <ProductExportLine
                    refetch={refetch}
                    key={item.id}
                    {...item}
                  />
                ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default withRouter(ProductExport);
