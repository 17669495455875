import React from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import {
  AsidePanel,
  PropertySettingsPanel,
  ProductGroupPanel,
  ProductPanel,
} from "./components";

const Panels = ({ type, product_group_id }) => {
  switch (type) {
    case "properties":
      return <PropertySettingsPanel />;
    case "product_group":
      return <ProductGroupPanel />;
    case "products":
      return <ProductPanel />;
    default:
      return (
        <Redirect
          to={`/product_groups/${product_group_id}/specification/properties`}
        />
      );
  }
};

const ProductGroupSpecification = ({
  match: { params },
}) => {
  return (
    <div className="main-wrapper">
      <AsidePanel />
      <Panels {...{ ...params }} />
    </div>
  );
};

export default connect(({ product_group }) => ({
  product_group,
}))(withRouter(ProductGroupSpecification));
