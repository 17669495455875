import { toastr } from "react-redux-toastr";
import { client } from "utils/api";
import { getChannelProductGroup } from "actions/product_group/channel_product_group";

export const createChannelProductGalleryImages =
  (data) => async (dispatch, getState) => {
    try {
      const { id: channel_product_group_id } =
        getState().channel_product_group.data;

      for (const item_index in data) {
        await client.post(`/channel_product_gallery_images`, data[item_index]);
      }

      dispatch(getChannelProductGroup(channel_product_group_id));

      toastr.success(
        "Sukces",
        data.length > 1 ? "Zdjęcia zostały dodane" : "Zdjęcie zostało dodane"
      );
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd podczas dodawania zdjęć");
    }
  };

export const updateGallerySectionProductImage =
  (id, data) => async (dispatch, getState) => {
    try {
      const { id: channel_product_group_id } =
        getState().channel_product_group.data;

      await client.put(`/channel_product_gallery_images/${id}`, data);

      dispatch(getChannelProductGroup(channel_product_group_id));

      toastr.success("Sukces", "Zdjęcie zostało zaktualizowane");
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd podczas aktualizowania zdjęcia");
    }
  };

export const deleteGallerySectionProductImage =
  (id) => async (dispatch, getState) => {
    try {
      const { id: channel_product_group_id } =
        getState().channel_product_group.data;
      await client.delete(`/channel_product_gallery_images/${id}`);

      dispatch(getChannelProductGroup(channel_product_group_id));

      toastr.success("Sukces", "Zdjęcie zostało usunięte");
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd podczas usuwania zdjęcia");
    }
  };

export const deleteAllGallerySectionProductImages =
  (ids) => async (dispatch, getState) => {
    try {
      const { id: channel_product_group_id } =
        getState().channel_product_group.data;

      for (const id of ids) {
        await client.delete(`/channel_product_gallery_images/${id}`);
      }

      dispatch(getChannelProductGroup(channel_product_group_id));

      toastr.success("Sukces", "Zdjęcia zostały usunięte");
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd podczas usuwania zdjęć");
    }
  };

export const markAsFeedProductImage = (id) => async (dispatch, getState) => {
  try {
    const { id: channel_product_group_id } =
      getState().channel_product_group.data;

    await client.post(`/channel_product_gallery_images/${id}/mark_as_feed`);

    dispatch(getChannelProductGroup(channel_product_group_id));
    toastr.success("Sukces", "Zdjęcie zostało oznaczone jako feed");
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd");
  }
};

export const copyGalleryImagesFromChannelProductGroup =
  ({ channel_product_group_id, cloned_channel_product_group_id }) =>
  async (dispatch) => {
    try {
      await client.post(
        `/channel_product_groups/${channel_product_group_id}/copy_gallery_from_channel_product_group`,
        {
          cloned_channel_product_group_id,
        }
      );
      dispatch(getChannelProductGroup(channel_product_group_id));
      toastr.success("Sukces", "Import przebiegł pomyślnie");
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd podzczas importowania");
      throw error;
    }
  };
