import React from "react";
import { Switch, Route } from "react-router-dom";

import {
  ProductsList,
  ProductImageRedirect,
  ProductSpecificationRedirect,
  ProductGroupsEdit,
  //
  PropertiesList,
  PropertiesEdit,
  PropertiesNew,
  //
  PrototypesList,
  PrototypesEdit,
  PrototypesNew,
  //
  CategoriesMain,
  CategoriesList,
  //
  SettingsList,
  SettingsEdit,
} from "../pages";

const Router = () => {
  return (
    <Switch>
      <Route
        exact={true}
        path="/products"
        component={ProductsList}
      />
      <Route
        exact={true}
        path="/product_image_by_sku"
        component={ProductImageRedirect}
      />
      <Route
        exact={true}
        path="/product_specification_by_sku"
        component={ProductSpecificationRedirect}
      />
      <Route
        exact={true}
        path={[
          "/product_groups/:product_group_id/:tab/:type/:type_id*",
          "/product_groups/:product_group_id/:tab/:type*",
          "/product_groups/:product_group_id/:tab*",
        ]}
        component={ProductGroupsEdit}
      />
      <Route
        exact={true}
        path="/properties"
        component={PropertiesList}
      />
      <Route
        exact={true}
        path="/properties/new"
        component={PropertiesNew}
      />
      <Route
        exact={true}
        path="/properties/:id"
        component={PropertiesEdit}
      />
      <Route
        exact={true}
        path="/prototypes"
        component={PrototypesList}
      />
      <Route
        exact={true}
        path="/prototypes/new"
        component={PrototypesNew}
      />
      <Route
        exact={true}
        path="/prototypes/:id"
        component={PrototypesEdit}
      />
      <Route
        exact={true}
        path="/platforms/platform_taxons"
        component={CategoriesMain}
      />
      <Route
        exact={true}
        path="/platforms/:platform_id/platform_taxons"
        component={CategoriesList}
      />
      <Route
        exact={true}
        path="/settings"
        component={SettingsList}
      />
      <Route
        exact={true}
        path="/settings/:channel_type/:id"
        component={SettingsEdit}
      />
    </Switch>
  );
};

export default Router;
