import React from "react";
import { Link } from "react-router-dom";
import { PagePanel } from "expano-components";

const Main = ({ children, page }) => (
  <div className="main-dashboard">
    <PagePanel app="pim" Link={Link} data={page} />
    {children}
  </div>
);

export default Main;
