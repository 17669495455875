import React, { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import ModalContainer from "./components/ModalContainer";

const ModalRotate = (props) => {
  const [is_active, setActive] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setActive(true);
    }, 250);
  }, []);

  return (
    <div className="popup-wrapper">
      <div
        className={`${
          is_active ? "active" : ""
        } popup-rotate active`}>
        <DndProvider backend={Backend}>
          <ModalContainer {...props} />
        </DndProvider>
      </div>
    </div>
  );
};

export default ModalRotate;
