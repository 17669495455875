import React from "react";
import { useDrop } from "react-dnd";
import { url } from "utils/api";

const getImageName = (name) => {
  try {
    const words = name.split("/");
    return words[words.length - 1].split(".")[0];
  } catch (error) {
    console.log(error);
    return "";
  }
};

const GridImage = ({
  accept,
  data,
  lastDroppedItem,
  onDrop,
  removeOrder,
}) => {
  const [{ isOver }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  return (
    <div className="grid-item" ref={drop}>
      {lastDroppedItem && (
        <button
          className="popup-rotate-box order-label"
          onClick={() => removeOrder(lastDroppedItem)}>
          <span>{lastDroppedItem.order + 1}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24">
            <rect
              id="Rectangle_378"
              width="24"
              height="24"
              fill="none"
            />
            <path
              d="M6,8H18l-.893,11.615A1.5,1.5,0,0,1,15.611,21H8.389a1.5,1.5,0,0,1-1.5-1.385Z"
              fill="white"
            />
            <path
              d="M14,4.5v-1a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5v1H5.5A.5.5,0,0,0,5,5v.5a.5.5,0,0,0,.5.5h13a.5.5,0,0,0,.5-.5V5a.5.5,0,0,0-.5-.5Z"
              fill="white"
              opacity="0.3"
            />
          </svg>
        </button>
      )}
      <div
        className="image"
        style={{
          borderColor: isOver ? "#597dff" : "#e2e5ec",
          backgroundImage: `url(${url}${data.image})`,
        }}
      />
      <p className="name">{getImageName(data.image)}</p>
    </div>
  );
};
export default GridImage;
