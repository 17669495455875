import axios from "axios";
import Jsona from "jsona";
import queryString from "query-string";
import { getCookie } from "./cookies";

const dataFormatter = new Jsona();

export const url = "https://api.pim.tradesk.pl"
// export const url = "https://api.pim.tradesk.pl";
export const api_url = `${url}/v2`;

axios.defaults.headers.common[
  "Authorization"
] = `${getCookie("token")}`;

export const client = axios.create({ baseURL: api_url });

export const apiFormatter = (data) => {
  return dataFormatter.deserialize(data);
};

export const handleErrors = (data) => {
  const errors = {};
  try {
    data.map(
      ({ detail, source: { pointer } }) =>
        (errors[
          pointer.split("/")[pointer.split("/").length - 1]
        ] = detail)
    );
    return errors;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const createCategories = (data) => {
  try {
    const options = [];

    const create = (data) =>
      data.forEach(
        ({ id, attributes: { name, children } }) => {
          options.push({
            label: name,
            value: id,
          });
          if (children.length > 0) {
            return create(children);
          }
          return;
        }
      );
    create(data);
    return options;
  } catch (error) {
    return [];
  }
};

export const parseQuery = (data) =>
  queryString.parse(data, { arrayFormat: "bracket" });
export const stringifyQuery = (data) =>
  queryString.stringify(data, { arrayFormat: "bracket" });

export const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: "base",
});

export const checkProductCardView = ({ type, channel_type }) => {
  const amazon = "Amazon::Channel";
  const wsf = "Wsf::Channel";
  const shoplo = "Shoplo::Channel";
  switch (type) {
    case "bullet_points":
      return [amazon, wsf, shoplo].includes(channel_type);
    case "product_group_title":
    case "product_group_gallery":
    case "description_section_product_group_image":
      return false;
    case "gallery_360":
      return channel_type === wsf;
    default:
      return true;
  }
};


export const getCountryCode = (code) => {
  if (code === "EN") {
    return "gb";
  }
  return code?.toLowerCase();
};