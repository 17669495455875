import React, { useState, useEffect } from "react";
import { Select } from "expano-components";
import { connect } from "react-redux";
import WarningModal from "./components/WarningModal";
import CopyDescriptionSectionModal from "./components/CopyDescriptionSectionModal";
import section_type_options from "utils/select_options/section_type_options";

import {
  copyDescriptionSection,
  updateDescriptionSection,
  moveUpDescriptionSection,
  moveDownDescriptionSection,
} from "actions/product_group/description_sections";

import { ReactComponent as BulletPointIcon } from "icons/bullet_point.svg";
import { ReactComponent as ArrowUpIcon } from "icons/arrow_up.svg";
import { ReactComponent as ArrowDownIcon } from "icons/arrow_down.svg";

const Header = ({
  id,
  attributes,
  copyDescriptionSection,
  updateDescriptionSection,
  moveUpDescriptionSection,
  moveDownDescriptionSection,
}) => {
  const [show_warning_modal, showWarningModal] = useState(
    false
  );

  const [show_copy_modal, showCopyModal] = useState(false);

  const [description_type, setDescriptionType] = useState(
    null
  );

  const handleUpdate = (type) =>
    updateDescriptionSection(id, { type });

  useEffect(() => {
    if (description_type) {
      showWarningModal(true);
    }
  }, [description_type]);

  return (
    <>
      {show_warning_modal && (
        <WarningModal
          handleClose={() => showWarningModal(false)}
          handleSubmit={() =>
            handleUpdate(description_type)
          }
        />
      )}
      {show_copy_modal && (
        <CopyDescriptionSectionModal
          handleClose={() => showCopyModal(false)}
          handleCopy={(copy_from_id) =>
            copyDescriptionSection(id, copy_from_id)
          }
        />
      )}
      <header className="product_card_section__header">
        <div className="product_card_section__header__title">
          <BulletPointIcon />
          <p className="section_name">
            Sekcja {attributes.position}
          </p>
          <div className="order-buttons">
            {attributes.position > 0 && (
              <button
                type="button"
                className="order-button up"
                onClick={() =>
                  moveUpDescriptionSection(id)
                }>
                <ArrowUpIcon />
              </button>
            )}
            <button
              type="button"
              className="order-button down"
              onClick={() =>
                moveDownDescriptionSection(id)
              }>
              <ArrowDownIcon />
            </button>
          </div>
        </div>
        <div className="product_card_section__header__action">
          <div className="select-group">
            <Select
              options={section_type_options}
              value={section_type_options.find(
                ({ value }) => value === attributes.type
              )}
              handleChange={(value) => {
                if (Boolean(attributes.type)) {
                  setDescriptionType(value);
                } else {
                  handleUpdate(value);
                }
              }}
            />
          </div>
          {!Boolean(attributes.type) && (
            <button
              className="btn btn-sm btn-blue m-l-15"
              onClick={() => showCopyModal(true)}>
              <BulletPointIcon />
              <span>Kopiuj sekcję</span>
            </button>
          )}
        </div>
      </header>
    </>
  );
};

export default connect(null, (dispatch) => ({
  updateDescriptionSection: (id, values) =>
    dispatch(updateDescriptionSection(id, values)),
  copyDescriptionSection: (id, copy_from_id) =>
    dispatch(copyDescriptionSection(id, copy_from_id)),
  moveUpDescriptionSection: (id) =>
    dispatch(moveUpDescriptionSection(id)),
  moveDownDescriptionSection: (id) =>
    dispatch(moveDownDescriptionSection(id)),
}))(Header);
