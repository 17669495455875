import React, { useState } from "react";
import { connect } from "react-redux";
import { ModalDelete } from "expano-components";
import { deleteAllGallerySectionProductImages } from "actions/product_group/channel_product_gallery_image";

const DeleteBox = ({
  data,
  images,
  deleteAllGallerySectionProductImages,
}) => {
  const [is_open, setOpen] = useState(false);
  const ids = images.map(({ id }) => id);

  const handleDeleteAllImages = async () => {
    await deleteAllGallerySectionProductImages(ids);
    setOpen(false);
  };

  if (images.length < 1) return null;

  return (
    <>
      {is_open && (
        <ModalDelete
          name={`usunąć wszystkie zdjęcia z produktu ${data.name} [${data.sku}]?`}
          handleDelete={handleDeleteAllImages}
          handleClose={() => setOpen(false)}
        />
      )}
      <button
        type="button"
        className="grid-item delete-image"
        onClick={() => setOpen(true)}>
        <p>usuń wszystkie</p>
      </button>
    </>
  );
};

export default connect(null, (dispatch) => ({
  deleteAllGallerySectionProductImages: (ids) =>
    dispatch(deleteAllGallerySectionProductImages(ids)),
}))(DeleteBox);
