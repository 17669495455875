import React from "react";
import classNames from "classnames";

import TableSort from "./components/TableSort";
import TableFilters from "./components/TableFilters";
import TableRows from "./components/TableRows";

import { useMainList } from "components/MainList/context/provider";
import Loader from "components/Loader";

const Table = () => {
  const { is_loading, is_fetching } = useMainList();
  if (is_loading) {
    return (
      <div className="main-list__loader">
        <Loader />
      </div>
    );
  }
  return (
    <div
      className={classNames("main-list__table", {
        loading: is_fetching,
      })}>
      <table>
        <TableSort />
        <TableFilters />
        <TableRows />
      </table>
    </div>
  );
};

export default Table;
