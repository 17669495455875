import React from "react";
import { Button } from "expano-components";
import { Text, Header } from "../components";

const TextSection = ({
  id,
  position,
  content,
  type,
  description_section_comments,
  handleDelete,
  disabled,
  inherited,
}) => {
  return (
    <div
      className={`dashboard product_card_section ${
        disabled ? "disabled" : ""
      }`}>
      <Header
        id={id}
        attributes={{ position, type }}
        inherited={inherited}
      />
      <div className="product_card_section__content product_card_section__text">
        <div className="dashboard__form">
          <Text
            form={`text_${id}`}
            initialValues={{ content }}
            content={content}
            id={id}
            position={position}
            description_section_comments={
              description_section_comments
            }
            inherited={inherited}
          />
        </div>
        <div className="dashboard__form__footer">
          <div className="box">
            <Button
              type="delete"
              text="Usuń"
              onClick={() => handleDelete({ id, position })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextSection;
