import getCategories from "utils/select_options/getCategories";
import getProducers from "utils/select_options/getProducers";

export const sort = [
  {
    label: "",
    name: "image",
    style: { width: 70 },
    sortable: false,
  },
  {
    label: "SKU",
    name: "sku",
    style: { width: 200 },
  },
  { label: "Nazwa", name: "name" },
  {
    label: "Kategoria",
    name: "root_taxon_name",
    sortable: false,
    style: { width: 300 },
  },
  {
    label: "Producent",
    name: "producer_name",
    sortable: false,
    style: { width: 200 },
  },
];

export const filters = [
  {
    type: "input",
    name: "sku_or_product_group_sku",
    sort_id: "sku",
    search_type: "matches",
  },
  {
    type: "input",
    name: "name",
    sort_id: "name",
    search_type: "matches",
  },
  {
    type: "async_select",
    name: "producer_name",
    sort_id: "producer_name",
    search_type: "in",
    getData: getProducers,
  },
  {
    type: "async_select",
    name: "root_taxon_name",
    sort_id: "root_taxon_name",
    search_type: "in",
    getData: getCategories,
  },
];
