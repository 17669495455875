const initialState = {
  status: "invalid",
  data: null,
};

const prepareData = (data) => {
  const channel_products = [...data.channel_products]
    .filter(
      ({ product: { status } }) => status !== "discontinued"
    )
    .sort((a, b) =>
      a.product.sku.localeCompare(
        b.product.sku,
        undefined,
        {
          numeric: true,
          sensitivity: "base",
        }
      )
    );
    
  const description_sections = [
    ...data.description_sections,
  ].sort((a, b) => a.position - b.position);

  return {
    ...data,
    channel_products,
    description_sections,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "CHANNEL_PRODUCT_GROUP_RESET":
      return {
        status: "invalid",
      };
    case "CHANNEL_PRODUCT_GROUP_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "CHANNEL_PRODUCT_GROUP_SUCCESS":
      return {
        ...state,
        status: "success",
        data: prepareData(action.data),
      };
    case "CHANNEL_PRODUCT_GROUP_FAILURE":
      return {
        ...state,
        status: "failure",
        err: action.err,
      };
    default:
      return state;
  }
};
