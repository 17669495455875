import React from "react";
import { Button } from "expano-components";
import { Modal } from "components";

import { ReactComponent as DangerIcon } from "icons/danger.svg";

const WarningModal = ({ handleClose, handleSubmit }) => (
  <Modal
    handleClose={handleClose}
    header={{ title: "Uwaga!", icon: <DangerIcon /> }}
    footer={
      <>
        <Button
          type="cancel"
          text="Nie"
          onClick={handleClose}
        />
        <button
          type="button"
          style={{ width: 100, justifyContent: "center" }}
          className="btn btn-sm btn-green"
          onClick={handleSubmit}>
          <span>Tak</span>
        </button>
      </>
    }>
    <p>
      Zmiana typ sekcji spowoduje usunięcie uzupełnionego
      contentu!
    </p>
    <p className="p-t-10">Czy na pewno chcesz to zrobić?</p>
  </Modal>
);

export default WarningModal;
