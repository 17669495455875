import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Label } from "expano-components";

import { ReactComponent as MockupIcon } from "icons/mockup.svg";
import { getCountryCode } from "utils/helpers";

const groupPlaceholders = (data) => {
  if (!data) return [];
  const groups = [
    {
      name: "Placeholdery główna karta",
      data: [],
    },
    {
      name: "Placeholdery aliasów",
      data: [],
    },
  ];
  data.forEach((item) => {
    if (
      item.code.includes("produkt_") ||
      item.code.includes("parametr:")
    ) {
      groups[0].data.push(item);
    } else {
      groups[1].data.push(item);
    }
  });
  return groups;
};

const Aside = ({
  channel_product_groups,
  placeholders,
  match: {
    params: { product_group_id, type, type_id },
  },
}) => {
  return (
    <div className="box-300">
      <div className="dashboard fh br">
        <ul className="aside-tabs">
          <li
            className={classNames("aside-tabs__tab", {
              active: type === "mockup",
              warning: false,
              success: false,
            })}>
            <Link
              to={`/product_groups/${product_group_id}/product_cards/mockup`}>
              <div className="icon-holder">
                <MockupIcon />
              </div>
              <div className="text-holder">
                <p>Makieta</p>
              </div>
            </Link>
          </li>
          {channel_product_groups.map(({ id, channel }) => (
            <li
              className={classNames("aside-tabs__tab", {
                active:
                  type === "channel_product_groups" &&
                  Number(type_id) === id,
                warning: false,
                success: false,
              })}
              key={id}>
              <Link
                to={`/product_groups/${product_group_id}/product_cards/channel_product_groups/${id}`}>
                <div className="label-holder">
                  <Label
                    type="double"
                    left={{
                      text: channel.shop?.label_text,
                      color: `#${channel.shop?.label_color}`,
                    }}
                    right={{
                      text: channel.platform?.label_text,
                      color: `#${channel.platform?.label_color}`,
                    }}
                  />
                </div>
                <div className="text-holder">
                  <p>
                    <span
                      className={`mr-10 flag-icon flag-icon-${getCountryCode(
                        channel?.platform?.country_code
                      )}`}
                    />
                    <span>{channel?.identifier}</span>
                  </p>
                </div>
              </Link>
            </li>
          ))}
        </ul>
        {type !== "mockup" && (
          <div className="placeholders-box">
            {groupPlaceholders(placeholders).map(
              ({ name, data }, index) => (
                <div
                  className="placeholders-box__group"
                  key={index}>
                  <p className="group-title">{name}</p>
                  <div className="group-items">
                    {data?.map(({ code }) => (
                      <p key={code}>{code}</p>
                    ))}
                  </div>
                </div>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(
  ({
    product_group: {
      data: { channel_product_groups, placeholders },
    },
  }) => ({
    channel_product_groups,
    placeholders,
  })
)(withRouter(Aside));
