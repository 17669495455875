import React from "react";
import LazyLoad from "react-lazy-load";

import { MainList, Main } from "components";
import { sort, filters } from "./const/data";

import { ReactComponent as ProductIcon } from "icons/product.svg";

const ProductsList = ({ history }) => {
  return (
    <Main
      page={{
        name: "Produkty",
        icon: <ProductIcon />,
        breadcrumbs: [
          {
            name: "Produkty",
          },
        ],
      }}>
      <MainList
        query_key="products"
        context="ui_index"
        api_path="/products"
        sort_column="created_at"
        sort_direction="desc"
        api_ransack="q[status_not_eq]=2"
        sort={sort}
        filters={filters}
        handleNavigate={(item, e) =>
          e?.button === 1
            ? window.open(
                `/product_groups/${item.product_group.id}`,
                "_blank"
              )
            : history.push(
                `/product_groups/${item.product_group.id}`
              )
        }
        renderItem={({
          sku,
          global_image_url,
          name,
          root_taxon,
          producer,
        }) => (
          <>
            <td>
              {global_image_url && (
                <LazyLoad height={40}>
                  <img
                    alt={name}
                    src={global_image_url}
                    style={{
                      width: 40,
                      height: 40,
                    }}
                  />
                </LazyLoad>
              )}
            </td>
            <td>{sku}</td>
            <td>{name}</td>
            <td>{root_taxon?.name}</td>
            <td>{producer?.name}</td>
          </>
        )}
      />
    </Main>
  );
};

export default ProductsList;
