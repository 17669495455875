const initialState = {
  status: "invalid",
  data: [{ id: "root", name: "Root" }],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "PLATFORMS_RESET":
      return initialState;
    case "PLATFORMS_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "PLATFORMS_SUCCESS":
      return {
        ...state,
        status: "success",
        data: [...state.data, ...action.data].sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
      };
    case "PLATFORMS_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    default:
      return state;
  }
};
