import { toastr } from "react-redux-toastr";
import { client, handleErrors } from "utils/api";

export const getProperty = (id) => async (
  dispatch,
  getState
) => {
  try {
    const {
      property: { status },
    } = getState();

    if (status === "invalid") {
      dispatch({ type: "PROPERTY_LOADING" });
    }

    const { data } = await client.get(`/properties/${id}?context=ui_show`);

    dispatch({
      type: "PROPERTY_SUCCESS",
      data,
    });
  } catch (error) {
    console.log(error);
    dispatch({ type: "PROPERTY_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podzczas pobierania"
    );
  }
};

export const createProperty = (values) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        data: { id },
      } = await client.post(`/properties`, values);
      toastr.success("Sukces", "Dodano właściwość");
      resolve(id);
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd");
      reject(handleErrors(error.response.data.errors));
    }
  });

export const updateProperty = (id, values) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      await client.put(`/properties/${id}`, values);
      dispatch(getProperty(id));

      toastr.success(
        "Sukces",
        "Właściwość została zaktualizowana"
      );
      resolve();
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd");
      reject(handleErrors(error.response.data.errors));
    }
  });

export const deleteProperty = (id) =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/properties/${id}`);
      toastr.success("Sukces", "Usunięto właściwość");
      resolve(id);
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd");
      reject(error.response.data.error);
    }
  });

export const resetProperty = () => (dispatch) =>
  dispatch({ type: "PROPERTY_RESET" });

export const createPropertyOption = (values) => (
  dispatch,
  getState
) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        property: {
          data: { id: property_id },
        },
      } = getState();
      await client.post(`/property_options`, values);
      await dispatch(getProperty(property_id));

      toastr.success("Sukces", "Dodano wartość właściwość");
      resolve();
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd");
      reject(handleErrors(error.response.data.errors));
    }
  });

export const updatePropertyOption = (id, values) => (
  dispatch,
  getState
) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        property: {
          data: { id: property_id },
        },
      } = getState();
      await client.put(`/property_options/${id}`, values);
      await dispatch(getProperty(property_id));
     
      toastr.success(
        "Sukces",
        "Zaktualizowano wartość właściwość"
      );
      resolve();
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd");

      reject(handleErrors(error.response.data.errors));
    }
  });

export const deletePropertyOption = (id) => (
  dispatch,
  getState
) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        property: {
          data: { id: property_id },
        },
      } = getState();
      await client.delete(`/property_options/${id}`);
      await dispatch(getProperty(property_id));
      toastr.success(
        "Sukces",
        "Usunięto wartość właściwości"
      );
      resolve(id);
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd");
      reject(handleErrors(error.response.data.errors));
    }
  });
