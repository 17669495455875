import { client } from "utils/api";

const getPropertiesOptions = (q) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.get(
        `/properties?context=list&per_page=100${
          q ? `&q[internal_name_matches]=%25${q}%25` : ""
        }`
      );

      const options = data.map(
        ({ id: value, internal_name: label }) => ({
          label,
          value,
        })
      );
      resolve(options);
    } catch (error) {
      console.log(error);
      reject([]);
    }
  });

export default getPropertiesOptions;
