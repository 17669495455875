import React, { useState } from "react";
import Dropzone from "react-dropzone-uploader";
import { Button } from "expano-components";

import { ReactComponent as DropzoneIcon } from "icons/dropzone.svg";

const Upload = ({
  accept = "image/*",
  handleClose,
  handleUpload,
}) => {
  const [files, setFiles] = useState([]);
  const handleChangeStatus = ({ file }, status) => {
    if (status === "done") {
      setFiles([...files, file]);
    }
  };

  return (
    <>
      <section
        className="popup__body"
        style={{ padding: 40 }}>
        <Dropzone
          onChangeStatus={handleChangeStatus}
          SubmitButtonComponent={null}
          maxFiles={100}
          multiple={true}
          canCancel={false}
          inputContent={() => (
            <>
              <DropzoneIcon />
              <p
                style={{
                  fontSize: "15px",
                  lineHeight: "23px",
                  fontWeight: 300,
                  color: "#595D6E",
                }}>
                Przenieś plik tutaj lub kliknij, aby go
                dodać.
              </p>
              <p
                style={{
                  fontSize: "13px",
                  lineHeight: "20px",
                  fontWeight: 300,
                  color: "#595D6E",
                }}>
                Przenieś plik tutaj lub kliknij, aby go
                dodać.
              </p>
            </>
          )}
          inputWithFilesContent="Dodaj"
          styles={{
            dropzone: {
              width: 425,
              minHeight: 140,
              padding: 10,
              border: "2px dashed #EBEDF2",
              borderRadius: 0,
              flexDirection: "row",
              flexFlow: "wrap",
              justifyContent: "center",
            },
            inputLabel: {
              flexDirection: "column",
              justifyContent: "center",
            },
            previewImage: {
              maxHeight: "initial",
              maxWidth: "calc(100% - 15px)",
            },
            preview: {
              padding: 10,
              border: "none",
              display: "inline-flex",
              width: "auto",
              alignItems: "flex-start",
            },
            inputLabelWithFiles: {
              margin: "auto",
              backgroundColor: "#44B2F1",
              color: "#fff",
              borderRadius: "5px",
            },
          }}
          accept={accept}
          disabled={(files) =>
            files.some((f) =>
              [
                "preparing",
                "getting_upload_params",
                "uploading",
              ].includes(f.meta.status)
            )
          }
        />
      </section>
      <div
        className="popup__footer"
        style={{
          padding: "10px 15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}>
        <Button type="cancel" onClick={handleClose} />
        <Button
          type="add-green"
          disabled={files.length < 1}
          text="Dodaj"
          onClick={() =>
            handleUpload(files).then(() => handleClose())
          }
        />
      </div>
    </>
  );
};

export default Upload;
