import { client } from "utils/api";

const getPropertyOptions = (q) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.get(
        `/properties?context=list&per_page=100${
          q ? `&q[internal_name_matches]=%25${q}%25` : ""
        }`
      );
      const options = data.map(({ id, internal_name }) => ({
        label: internal_name,
        value: id,
      }));
      resolve(options);
    } catch (error) {
      console.log(error);
      reject([]);
    }
  });

export default getPropertyOptions;
