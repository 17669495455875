import React from "react";
import Loader from "react-loader-spinner";

const PageLoader = () => {
  return (
    <div className="page-loader">
      <div className="loader-holder">
        <Loader
          type="Watch"
          color="#36bcff"
          height={40}
          width={40}
        />
      </div>
    </div>
  );
};

export default PageLoader;
