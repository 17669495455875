import { toastr } from "react-redux-toastr";
import { client } from "utils/api";

import { getChannelProductGroup } from "actions/product_group/channel_product_group";

export const updateChannelProductsPrice = async (data) => {
  try {
    await Promise.all(
      data.map(({ id, price }) =>
        client.put(`/channel_products/${id}`, {
          price,
        })
      )
    );

    toastr.success("Sukces", "Ceny zostały zaktualizowane");
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd");
  }
};

export const updateChannelProduct = (id, values) =>
  new Promise(async (resolve, reject) => {
    try {
      await client.put(`/channel_products/${id}`, values);
      toastr.success("Sukces", "Status został zaktualizowany");
      resolve();
    } catch (error) {
      toastr.error("Błąd", "Nie udało się zaktualizować statusu");
      reject(error);
    }
  });

export const exportPriceToChannel = async (id) => {
  try {
    const { message } = await client.post(
      `/channel_products/${id}/export_price`
    );

    toastr.success("Sukces", message || "Pomyślnie wyeksportowano");
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd podzczas eksportowania");
  }
};

export const autoAssignChannelProduct360Image =
  (id) => async (dispatch, getState) => {
    try {
      const { id: channel_product_group_id } =
        getState().channel_product_group.data;

      await client.post(`/channel_products/${id}/auto_assign_360_images`);

      dispatch(getChannelProductGroup(channel_product_group_id));

      toastr.success("Sukces", "Przypisano automatycznie");
    } catch (error) {
      toastr.error("Błąd", error?.response?.data?.error || "Wystąpił błąd");
    }
  };

export const getPrompt = async (id, mode) => {
  try {
    const data = await client.get(
      `/channel_products/${id}/fetch_ai_title_prompt${
        mode ? `?mode=${mode}` : ""
      }`
    );
    toastr.success("Sukces", "Prompt został wygenerowany");
    return data;
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd podczas generowania prompt");
    throw error;
  }
};

export const createAiTitle =
  ({ id, mode, prompt }) =>
  async (dispatch, getState) => {
    try {
      const { id: channel_product_group_id } =
        getState().channel_product_group.data;

      await client.post(`/channel_products/${id}/generate_ai_title`, {
        mode,
        prompt,
      });

      await dispatch(getChannelProductGroup(channel_product_group_id));

      toastr.success("Sukces", "Opis został wygenerowany");
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd podczas generowania opisu");
      throw error;
    }
  };

