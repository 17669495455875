import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { ModalDelete } from "expano-components";
import { useMainList } from "components/MainList/context/provider";
import { deletePrototype } from "actions/prototypes";

const ModalDeletePrototype = ({
  name,
  id,
  handleClose,
}) => {
  const { refetch } = useMainList();
  const query = useQueryClient();
  const handleDelete = () =>
    deletePrototype(id)
      .then(async () => {
        refetch();
        query.refetchQueries(["prototypes"]);
        handleClose();
      })
      .catch((err) => console.log(err));

  return (
    <ModalDelete
      name={`prototyp ${name}?`}
      handleClose={handleClose}
      handleDelete={handleDelete}
    />
  );
};

export default ModalDeletePrototype;
