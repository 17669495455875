import React, { useState } from "react";
import {
  Button,
  ModalDelete,
  ModalUpload,
  ModalPreview,
} from "expano-components";
import { url } from "utils/api";
import GlobalImageModal from "./GlobalImageModal";

import { ReactComponent as GlobalImageIcon } from "icons/global-image.svg";
import classNames from "classnames";

const getImageName = (path) => {
  try {
    const path_arr = path.split("/");
    if (path_arr[path_arr.length - 1].length > 40) {
      return decodeURI(
        [
          ...path_arr[path_arr.length - 1].split("").splice(0, 40),
          ["..."],
        ].join("")
      );
    } else {
      return path_arr[path_arr.length - 1];
    }
  } catch (error) {
    console.log(error);
    return "";
  }
};

const SingleImageRow = ({
  id,
  image,
  handleDelete,
  handleUpdate,
  handleSetGlobalImage,
  handleSetProductNeutralImage,
  handleSetProductForBundleImage,
  is_global,
  product_data,
  handleSetActiveImage,
  is_checked,
  is_neutral,
  is_for_bundle,
}) => {
  const [is_loading_for_bundle, setLoadingForBundle] = useState(false);
  const [is_loading_neutral, setLoadingNeutral] = useState(false);
  const [show_global_image_modal, showGlobalImageModal] = useState(false);
  const [show_delete_image_modal, showDeleteModal] = useState(false);
  const [show_upload_image_modal, showUploadModal] = useState(false);
  const [show_preview_image_modal, showPreviewModal] = useState(false);

  const image_url = `${url}${image}`;

  const toggleNeutral = async () => {
    try {
      setLoadingNeutral(true);
      await handleSetProductNeutralImage(id, !is_neutral);
      setLoadingNeutral(false);
    } catch (error) {
      setLoadingNeutral(false);
    }
  };

  const toggleForBundle = async () => {
    try {
      setLoadingForBundle(true);
      await handleSetProductForBundleImage(id, !is_for_bundle);
      setLoadingForBundle(false);
    } catch (error) {
      setLoadingForBundle(false);
    }
  };


  
  return (
    <>
      {show_global_image_modal && (
        <GlobalImageModal
          handleClose={() => showGlobalImageModal(false)}
          handleSubmit={() => handleSetGlobalImage(id)}
          product_data={product_data}
          image={image_url}
        />
      )}
      {show_preview_image_modal && (
        <ModalPreview
          src={`${image_url}`}
          handleClose={() => showPreviewModal(false)}
        />
      )}
      {show_delete_image_modal && (
        <ModalDelete
          handleDelete={() =>
            handleDelete(id).then(() => showDeleteModal(false))
          }
          handleClose={() => showDeleteModal(false)}
          name={getImageName(image)}
        />
      )}
      {show_upload_image_modal && (
        <ModalUpload
          headerText="Wgraj zdjęcie"
          handleUpload={(image) => handleUpdate(id, image)}
          handleClose={() => showUploadModal(false)}
          name={`logo`}
          limit={1}
        />
      )}
      <tr>
        <td>
          <div className="form-group">
            <div className="check-group" style={{ margin: 0 }}>
              <input
                id={`image_${id}`}
                type="checkbox"
                checked={is_checked}
                onChange={() => handleSetActiveImage(id)}
              />
              <label
                htmlFor={`image_${id}`}
                className="label-form"
                style={{ minHeight: 16, margin: 0 }}
              />
            </div>
          </div>
        </td>
        <td>
          <button
            type="button"
            className="btn-preview"
            onClick={() => showPreviewModal(true)}
            style={{
              backgroundImage: `url(${image_url})`,
            }}
          />
        </td>
        <td>{id}</td>
        <td>{getImageName(image_url)}</td>
        <td>
          <div className="df v-center h-end p-r-20">
            <button
              className={classNames("btn-neutral-image m-r-10", {
                active: is_for_bundle,
              })}
              disabled={is_loading_for_bundle}
              type="button"
              onClick={toggleForBundle}
            >
              {is_loading_for_bundle ? ".." : "B"}
            </button>
            <button
              className={classNames("btn-neutral-image m-r-10", {
                active: is_neutral,
              })}
              disabled={is_loading_neutral}
              type="button"
              onClick={toggleNeutral}
            >
              {is_loading_neutral ? ".." : "N"}
            </button>
            <button
              className="btn-global-image m-r-10"
              style={is_global ? { opacity: "1" } : {}}
              type="button"
              onClick={() => {
                if (!is_global) {
                  showGlobalImageModal(true);
                }
              }}
            >
              <GlobalImageIcon />
            </button>
            <Button type="upload-icon" onClick={() => showUploadModal(true)} />
            <Button type="delete-bin" onClick={() => showDeleteModal(true)} />
          </div>
        </td>
      </tr>
    </>
  );
};

export default SingleImageRow;
