import React from "react";
import { Main } from "components";

import { ReactComponent as SettingsIcon } from "icons/settings.svg";

import Allegro from "./channels/Allegro";

const renderPanel = (channel_type) => {
  switch (channel_type) {
    case "allegro_channel":
      return <Allegro />;
    default:
      return null;
  }
};

const SettingsEdit = ({
  match: {
    params: { channel_type },
  },
}) => (
  <Main
    page={{
      name: "Ustawienia sprzedaży",
      icon: <SettingsIcon />,
      breadcrumbs: [
        { name: "Ustawienia sprzedaży", path: "/settings" },
        { name: `Edytuj kanał` },
      ],
    }}>
    {renderPanel(channel_type)}
  </Main>
);

export default SettingsEdit;
