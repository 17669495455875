import { client, authorizeApi } from "utils/api";
import { getCookie } from "utils/cookies";

export const getCurrentUser = () => async (dispatch) => {
  dispatch({ type: "CURRENT_USER_LOADING" });

  try {
    const token = getCookie("token");

    if (!token) {
      throw new Error("token missing");
    }

    const { data } = await client.get("/users/my_account", {
      headers: { Authorization: `Bearer ${token}` },
    });

    authorizeApi(token);

    dispatch({ type: "CURRENT_USER_SUCCESS", data });
  } catch (err) {
    dispatch({ type: "CURRENT_USER_FAILURE", err });
  }
};
