const getPlatformColor = (name) => {
  const lower_name = name.toLowerCase();
  switch (true) {
    case new RegExp("allegro").test(lower_name):
      return "#F5600A";
    case new RegExp("amazon").test(lower_name):
      return "rgb(18, 49, 35)";
    case new RegExp("ebay").test(lower_name):
      return "#0064D2";
    case new RegExp("wsf").test(lower_name):
      return "rgb(11, 160, 94)";
    case new RegExp("root").test(lower_name):
      return "#FD397A";
    default:
      return "#74788d";
  }
};

export default getPlatformColor;
