import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { Pagination } from "expano-components";
import { PanelLoader } from "components";
import useQueryHook from "utils/useQueryHook";
import { stringifyQuery, parseQuery } from "utils/api";

const query = {
  context: "ui_aliases",
  per_page: 10,
};

const AliasesList = ({
  products,
  history,
  location,
  match: {
    params: { type_id },
  },
}) => {
  const product = products.find(
    ({ id }) => id === Number(type_id)
  );

  const { page } = parseQuery(location.search);

  const {
    is_loading,
    is_fetching,
    data: { data, meta },
  } = useQueryHook({
    query_keys: [`product_aliases_${type_id}`, page],
    path: `/product_aliases?${stringifyQuery({
      ...query,
      page,
      "q[product_id_eq]": type_id,
    })}`,
  });

  if (!product) {
    return null;
  }

  if (is_loading || (data.length === 0 && is_fetching)) {
    return <PanelLoader />;
  }

  return (
    <div
      className={classNames("dashboard box-full", {
        fetching: is_fetching,
      })}>
      {data.length > 0 ? (
        <table className="table">
          <thead className="table__header table-head ntb">
            <tr>
              <th width="120px">ID</th>
              <th>SKU</th>
              <th>Nazwa</th>
            </tr>
          </thead>
          <tbody className="table__body table-body">
            {data.map(({ id, name, sku }) => (
              <tr key={id}>
                <td>{id}</td>
                <td>{sku}</td>
                <td>{name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="p-20">Brak aliasów</p>
      )}
      <Pagination
        data={meta}
        history={history}
        location={location}
        handlePaginate={() => null}
      />
    </div>
  );
};

export default connect(
  ({
    product_group: {
      data: { products },
    },
  }) => ({ products })
)(withRouter(AliasesList));
