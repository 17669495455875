import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Label } from "expano-components";
import { PageLoader, Main, Dashboard } from "components";
import { getSettings } from "actions/settings";
import { ReactComponent as SettingsIcon } from "icons/settings.svg";

import Channels from "./components/Channels";

const Settings = ({ settings, getSettings }) => {
  useEffect(() => {
    getSettings();
  }, []);

  if (
    ["invalid", "loading", "failure"].includes(
      settings.status
    )
  ) {
    return <PageLoader />;
  }
  const { platforms, shops } = settings.data;
  return (
    <Main
      page={{
        name: "Ustawienia sprzedaży",
        icon: <SettingsIcon />,
        breadcrumbs: [{ name: "Ustawienia sprzedaży" }],
      }}>
      <div className="dashboard-wrapper-flex">
        <div className="column-2">
          <Channels />
        </div>
        <div className="column-1">
          <Dashboard
            header={{ icon: "shop", title: "Sklepy" }}>
            <table className="table">
              <tbody className="table__body table-body">
                {shops.map(
                  ({
                    id,
                    name,
                    label_color,
                    label_text,
                  }) => (
                    <tr key={id}>
                      <td width="120px">
                        <Label
                          type="fill"
                          text={label_text}
                          color={`${label_color}`}
                        />
                      </td>
                      <td>{name}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </Dashboard>
          <Dashboard
            header={{
              icon: "platform",
              title: "Platformy",
            }}>
            <table className="table">
              <tbody className="table__body table-body">
                {platforms.map(
                  ({
                    id,
                    name,
                    label_color,
                    label_text,
                  }) => (
                    <tr key={id}>
                      <td width="120px">
                        <Label
                          type="bordered"
                          text={label_text}
                          color={`#${label_color}`}
                        />
                      </td>
                      <td>{name}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </Dashboard>
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = ({ settings }) => ({ settings });

const mapDispatchToProps = (dispatch) => ({
  getSettings: () => dispatch(getSettings()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);
