import React, { useState } from "react";
import { Button, ModalPreview } from "expano-components";
import { url } from "utils/api";
import { ReactComponent as ZoomIcon } from "icons/zoom.svg";

const Multi = ({ images, handleClose, handleSubmit }) => {
  const [is_saving, setSaving] = useState(false);
  const [active_items, setActiveItems] = useState([]);
  const [image_url, setImageUrl] = useState(null);

  const getName = (path) => {
    try {
      const arr = path.split("/");
      return arr[arr.length - 1];
    } catch (error) {
      console.log(error);
      return "";
    }
  };

  const handleConfirm = async () => {
    try {
      setSaving(true);
      await handleSubmit(active_items);
      handleClose();
    } catch (error) {
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      {Boolean(image_url) && (
        <ModalPreview
          src={`${image_url}`}
          handleClose={() => setImageUrl(null)}
        />
      )}
      <section
        className="popup__body"
        style={{ padding: 40 }}>
        {images?.length > 0 && (
          <div className="popup-gallery-grid">
            {images.map(({ id, image }) => {
              const is_active = active_items.includes(id);
              const active_index = is_active
                ? active_items.indexOf(
                    active_items.find(
                      (active_item_id) =>
                        active_item_id === id
                    )
                  )
                : null;
              return (
                <div
                  onClick={() => {
                    setActiveItems(
                      is_active
                        ? active_items.filter(
                            (active_item_id) =>
                              active_item_id !== id
                          )
                        : [...active_items, id]
                    );
                  }}
                  key={id}
                  className={`popup-gallery-grid__item
                ${is_active ? "active" : ""}
                `}>
                  <div
                    className="popup-gallery-grid__item__image"
                    style={{
                      backgroundImage: `url(${url}${image})`,
                    }}>
                    {is_active && (
                      <div className="image-order">
                        {active_index + 1}
                      </div>
                    )}
                    <button
                      type="button"
                      className="btn-zoom"
                      onClick={(e) => {
                        e.stopPropagation();
                        setImageUrl(`${url}${image}`);
                      }}>
                      <ZoomIcon />
                    </button>
                  </div>
                  <p className="popup-gallery-grid__item__name">
                    {getName(image)}
                  </p>
                </div>
              );
            })}
          </div>
        )}
      </section>
      <div
        className="popup__footer"
        style={{
          padding: "10px 15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}>
        <Button type="cancel" onClick={handleClose} />
        <Button
          type="save"
          text="Zapisz"
          disabled={is_saving || active_items?.length < 1}
          onClick={handleConfirm}
        />
      </div>
    </>
  );
};

export default Multi;
