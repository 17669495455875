import React from "react";
import { connect } from "react-redux";

import { reset, reduxForm } from "redux-form";
import { Button, Input } from "expano-components";
import { AsyncSelectDelayData } from "components";
import { updateProductGroupProperties } from "actions/product_group/properties";
import getPropertyOptionOptions from "utils/select_options/getPropertyOptionOptions";

const form_name = "product-group-property-settings";

const validate = (values) => {
  let errors = {};
  Object.keys(values).forEach((value) => {
    if (!values[value].kind) {
      errors = {
        ...errors,
        [value]: { kind: "Pole wymagane" },
      };
    }
  });

  return errors;
};

const ProductGroupPanel = ({
  product_group,
  change,
  valid,
  pristine,
  submitting,
  handleSubmit,
  updateProductGroupProperties,
}) => {
  const onSubmit = async (values, dispatch) => {
    try {
      await updateProductGroupProperties(
        Object.keys(values).map((key) => ({
          id: values[key].id,
          property_option_id: values[key].value,
        }))
      );
      dispatch(reset(form_name));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form
      className="create-table"
      onSubmit={handleSubmit(onSubmit)}>
      <table>
        <thead>
          <tr>
            <th>Nazwa właściwości</th>
            <th>Wartość właściwości</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {product_group?.producer && (
            <tr>
              <td>Producent</td>
              <td>
                <Input
                  input={{
                    name: "producer",
                    value:
                      product_group.producer.name || "",
                  }}
                  disabled
                  meta={{ touched: false, valid: true }}
                />
              </td>
              <td />
              <td />
            </tr>
          )}
          {product_group.product_group_properties.map(
            ({ id, property, property_option }) => {
              return (
                <tr key={id}>
                  <td>{property?.internal_name}</td>
                  <td>
                    <AsyncSelectDelayData
                      onChange={({ value }) =>
                        change(
                          `product_group_property_${id}`,
                          {
                            value,
                            id,
                          }
                        )
                      }
                      getData={() =>
                        getPropertyOptionOptions(
                          property.id
                        )
                      }
                      initValue={
                        property_option?.id
                          ? {
                              label:
                                property_option.value?.pl ||
                                "-",
                              value: property_option.id,
                            }
                          : null
                      }
                    />
                  </td>
                  <td />
                  <td />
                </tr>
              );
            }
          )}
        </tbody>
      </table>
      <div className="create-table__footer">
        <Button
          type="save"
          text="Zapisz"
          disabled={pristine || !valid || submitting}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </form>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateProductGroupProperties: (id, property_option_id) =>
    dispatch(
      updateProductGroupProperties(id, property_option_id)
    ),
});

export default connect(
  ({ product_group: { data: product_group } }) => ({
    product_group,
  }),
  mapDispatchToProps
)(
  reduxForm({
    form: form_name,
    validate,
    enableReinitialize: true,
  })(ProductGroupPanel)
);
