import React from "react";
import { withRouter } from "react-router-dom";
import {
  AsidePanel,
  ProductImagesList,
  ProductGroupImagesList,
} from "./components";

const ProductImages = ({
  match: {
    params: { type },
  },
}) => {
  return (
    <div className="main-wrapper">
      <AsidePanel />
      {!type && <ProductGroupImagesList />}
      {type === "products" && <ProductImagesList />}
    </div>
  );
};

export default withRouter(ProductImages);
