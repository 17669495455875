import { client, stringifyQuery } from "utils/api";

const getPropertyOptionOptions = (property_id) =>
  new Promise(async (resolve, reject) => {
    try {
      const query = stringifyQuery({
        "q[property_id_eq]": property_id,
        context: "list",
        per_page: 500,
      });
      const { data } = await client.get(
        `/property_options?${query}`
      );
      const options = data.map(({ id, value }) => ({
        label: value?.pl,
        value: id,
      }));
      resolve(options);
    } catch (error) {
      console.log(error);
      reject([]);
    }
  });

export default getPropertyOptionOptions;
