import React from "react";

import { Button } from "expano-components";
import { Modal } from "components";

import { ReactComponent as DangerIcon } from "icons/danger.svg";

const GlobalImageModal = ({
  handleSubmit,
  handleClose,
  product_data,
  image,
}) => {
  return (
    <Modal
      header={{
        title: "Uwaga!",
        icon: <DangerIcon />,
      }}
      footer={
        <>
          <Button
            type="cancel"
            text="Nie"
            onClick={handleClose}
          />
          <button
            type="button"
            style={{ width: 100, justifyContent: "center" }}
            className="btn btn-sm btn-green"
            onClick={async (id) =>
              await handleSubmit(id).then(handleClose)
            }>
            <span>Tak</span>
          </button>
        </>
      }
      handleClose={handleClose}>
      <div className="global_image_modal-box">
        <div className="image">
          <img src={image} alt="" />
        </div>
        <div className="text">
          <p>Zdjęcie zostanie ustawione jako główne dla:</p>
          <p className="product-name">
            {product_data?.name}
          </p>
          <p className="sku">{product_data?.sku}</p>
        </div>
      </div>
      <p>Czy na pewno chcesz to zrobić?</p>
    </Modal>
  );
};

export default GlobalImageModal;
