import React from "react";

import { useMainList } from "components/MainList/context/provider";

import FilterValues from "./components/FilterValues";
import Input from "./components/Input";
import Select from "./components/Select";
import AsyncSelect from "./components/AsyncSelect";
// import Date from "./components/Date";

const renderFilterComponent = ({
  filter,
  setFilterValue,
}) => {
  switch (filter?.type) {
    case "input": {
      return (
        <Input
          {...filter}
          handleSubmit={(value) =>
            setFilterValue({
              name: filter.name,
              value,
              label: value,
            })
          }
        />
      );
    }
    case "select": {
      return (
        <Select
          {...filter}
          handleSubmit={({ value, label }) =>
            setFilterValue({
              name: filter.name,
              value,
              label,
            })
          }
        />
      );
    }
    case "async_select": {
      return (
        <AsyncSelect
          {...filter}
          handleSubmit={({ value, label }) =>
            setFilterValue({
              name: filter.name,
              value,
              label,
            })
          }
        />
      );
    }
    // case "date": {
    //   return null;
    //   return (
    //     <Date
    //       {...filter}
    //       handleSubmit={({ value, label }) =>
    //         setFilterValue({
    //           name: filter.name,
    //           value,
    //           label,
    //         })
    //       }
    //     />
    //   );
    // }
    default:
      return null;
  }
};

const TableFilters = () => {
  const {
    state: { filters },
    setFilterValue,
  } = useMainList();
  if (filters?.filter(Boolean)?.length < 1) return null;
  return (
    <thead className="table-filters">
      <tr>
        {filters.map((filter, index) => (
          <th key={filter?.name || index}>
            {renderFilterComponent({
              filter,
              index,
              setFilterValue,
            })}
            {filter?.values?.length > 0 && (
              <FilterValues
                name={filter.name}
                data={filter.values}
              />
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableFilters;
