import React, { useState } from "react";
import { Button, ModalDelete } from "expano-components";
import { deletePlatformTaxonFromProductGroup } from "actions/product_group/platform_taxons";

const PlatformTaxonItem = ({
  id,
  platform_taxon,
  index,
  refetch,
}) => {
  const [
    is_open_delete_modal,
    handleOpenDeleteModal,
  ] = useState(false);

  const ancestry = platform_taxon?.path_names?.split(">");

  return (
    <li className="platform-taxons-list__item">
      <p className="item-label">Kategoria {index}</p>
      {is_open_delete_modal && (
        <ModalDelete
          name={`kategorię ${platform_taxon?.name}?`}
          handleDelete={async () => {
            await deletePlatformTaxonFromProductGroup(id);
            handleOpenDeleteModal(false);
            refetch();
          }}
          handleClose={() => handleOpenDeleteModal(false)}
        />
      )}
      <div className="item-wrapper">
        <ul className="taxons-list">
          {ancestry?.length > 0 &&
            ancestry.map((item, index) => (
              <li
                className={`taxons-list__item ${
                  index === ancestry.length - 1
                    ? "current"
                    : ""
                }`}>
                {item}
              </li>
            ))}
        </ul>
        <div className="taxons-remove-box">
          <Button
            type="delete-bin"
            onClick={() => handleOpenDeleteModal(true)}
          />
        </div>
      </div>
    </li>
  );
};

export default PlatformTaxonItem;
