import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { PanelLoader } from "components";
import {
  getChannelProductGroup,
  resetChannelProductGroup,
} from "actions/product_group/channel_product_group";
import {
  BasicDataForm,
  Gallery,
  DescriptionSections,
  BulletPoints,
} from "./components";
import { checkProductCardView } from "utils/helpers";

const ProductGroupSections = ({
  channel_product_group,
  match: {
    params: { type_id: channel_product_group_id },
  },
  getChannelProductGroup,
  resetChannelProductGroup,
}) => {
  useEffect(() => {
    getChannelProductGroup(channel_product_group_id);
    return () => resetChannelProductGroup();
    // eslint-disable-next-line
  }, [channel_product_group_id]);

  if (
    ["invalid", "loading", "failure"].includes(
      channel_product_group.status
    )
  ) {
    return <PanelLoader />;
  }

  const { channel } = channel_product_group.data;

  const show_bullet_points = checkProductCardView({
    type: "bullet_points",
    channel_type: channel?.type,
  });

  return (
    <div className="column-2 product-card-container">
      <BasicDataForm />
      <Gallery />
      {show_bullet_points && <BulletPoints />}
      <DescriptionSections />
    </div>
  );
};

const mapStateToProps = ({ channel_product_group }) => ({
  channel_product_group,
});
const mapDispatchToProps = (dispatch) => ({
  getChannelProductGroup: (id) =>
    dispatch(getChannelProductGroup(id)),
  resetChannelProductGroup: () =>
    dispatch(resetChannelProductGroup()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProductGroupSections));
