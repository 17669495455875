import React from "react";
import Select from "react-select/async";
import debounce from "debounce-promise";

const AsyncSelect = ({
  isDisabled,
  getData,
  label,
  meta,
  onChange,
  initValue,
}) => {
  const isError = meta && meta.touched && meta.invalid;
  const customStyles = {
    control: (styles) => ({
      ...styles,
      borderColor: isError ? "#ff0000" : "#E2E5EC",
      minHeight: 30,
    }),
    option: (provided) => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0 10px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
      padding: 0,
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
    }),
  };
  return (
    <div className={`form-group ${isError ? "error" : ""}`}>
      <div className="inp-wrapper">
        {label && <label>{label}</label>}
        <Select
          onChange={onChange}
          styles={customStyles}
          defaultOptions
          cacheOptions
          allowCreateWhileLoading
          isDisabled={isDisabled}
          placeholder={"Wybierz..."}
          theme={(theme) => ({
            ...theme,
            fontFamily: "Poppins",
            borderRadius: 4,
            colors: {
              ...theme.colors,
              neutral20: "#E2E5EC",
            },
            error: meta && meta.touched && meta.invalid,
          })}
          loadOptions={debounce(
            (a, b) => getData(a, b),
            500,
            {
              leading: false,
              accumulate: false,
            }
          )}
          defaultValue={initValue}
        />
        {isError && meta.error && (
          <p className="inp-error">{meta.error}</p>
        )}
      </div>
    </div>
  );
};

export default AsyncSelect;
