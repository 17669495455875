import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SectionBox from "./components/SectionBox";
import { createDescriptionSection } from "actions/product_group/description_sections";

import { ReactComponent as BulletPointIcon } from "icons/bullet_point.svg";

const DescriptionSections = ({
  match: {
    params: { type_id: channel_product_group_id },
  },
  description_sections,
  channel,
  createDescriptionSection,
}) => {
  const [is_loading, setLoading] = useState(false);
  return (
    <>
      {description_sections.length > 0 &&
        description_sections.map((section) => (
          <SectionBox
            key={section.id}
            {...section}
            items={description_sections.length}
          />
        ))}
      <div className="product_card_section__add">
        <button
          className="btn btn-sm btn-blue"
          disabled={is_loading}
          onClick={async () => {
            try {
              setLoading(true);
              await createDescriptionSection(
                { channel_product_group_id },
                channel.id
              );
            } catch (error) {
              console.log(error);
            } finally {
              setLoading(false);
            }
          }}>
          {is_loading ? (
            "Proszę czekać..."
          ) : (
            <>
              <BulletPointIcon />
              <span>Dodaj sekcję</span>
            </>
          )}
        </button>
      </div>
    </>
  );
};

export default connect(
  ({
    channel_product_group: {
      data: { description_sections, channel },
    },
  }) => ({
    description_sections,
    channel,
  }),
  (dispatch) => ({
    createDescriptionSection: (data) =>
      dispatch(createDescriptionSection(data)),
  })
)(withRouter(DescriptionSections));
