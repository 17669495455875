import React from "react";
import { connect } from "react-redux";
import { Dashboard } from "components";
import ChannelListItem from "./components/ChannelListItem";

const Channels = ({ settings }) => {
  const { channels } = settings.data;

  return (
    <Dashboard
      header={{ icon: "channel", title: "Kanały" }}>
      <table className="table">
        <tbody className="table__body table-body">
          {channels.map((item) => (
            <ChannelListItem key={item.id} {...item} />
          ))}
        </tbody>
      </table>
    </Dashboard>
  );
};

export default connect(({ settings }) => ({ settings }))(
  Channels
);
