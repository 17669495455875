import React, { useState, useRef } from "react";
import { Button, ModalPreview } from "expano-components";
import { url } from "utils/api";
import { ReactComponent as ZoomIcon } from "icons/zoom.svg";

const Single = ({ images, handleSubmit, handleClose }) => {
  const click_ref = useRef(null);
  const [is_saving, setSaving] = useState(false);
  const [active_item, setActiveItem] = useState(null);
  const [image_url, setImageUrl] = useState(null);

  const getName = (path) => {
    try {
      const arr = path.split("/");
      return arr[arr.length - 1];
    } catch (error) {
      console.log(error);
      return "";
    }
  };

  const handleConfirm = async () => {
    try {
      setSaving(true);
      await handleSubmit([active_item]);
      handleClose();
    } catch (error) {
    } finally {
      setSaving(false);
    }
  };

  const handleClick = (e, id) => {
    switch (e.detail) {
      case 1:
        setActiveItem(id);
        break;
      case 2:
        handleConfirm()
        break;
      default:
        break;
    }
  };

  return (
    <>
      {Boolean(image_url) && (
        <ModalPreview
          src={`${image_url}`}
          handleClose={() => setImageUrl(null)}
        />
      )}
      <section
        className="popup__body"
        style={{ padding: 40 }}>
        {images?.length > 0 && (
          <div className="popup-gallery-grid">
            {images.map(({ id, image }) => (
              <div
                ref={click_ref}
                onClick={(e) => {
                  handleClick(e, id);
                }}
                key={id}
                className={`popup-gallery-grid__item
                ${active_item === id ? "active" : ""}
                `}>
                <div
                  className="popup-gallery-grid__item__image"
                  style={{
                    backgroundImage: `url(${url}${image})`,
                  }}>
                  <button
                    type="button"
                    className="btn-zoom"
                    onClick={(e) => {
                      e.stopPropagation();
                      setImageUrl(`${url}${image}`);
                    }}>
                    <ZoomIcon />
                  </button>
                </div>
                <p className="popup-gallery-grid__item__name">
                  {getName(image)}
                </p>
              </div>
            ))}
          </div>
        )}
      </section>
      <div
        className="popup__footer"
        style={{
          padding: "10px 15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}>
        <Button type="cancel" onClick={handleClose} />
        <Button
          type="save"
          text="Zapisz"
          disabled={is_saving || !active_item}
          onClick={handleConfirm}
        />
      </div>
    </>
  );
};

export default Single;
