import { toastr } from "react-redux-toastr";
import { client } from "utils/api";

export const addPlatformTaxonToProductGroup = (values) =>
  new Promise(async (resolve, reject) => {
    try {
      await client.post(
        `/platform_taxon_product_groups?include=platform_taxon`,
        values
      );
      toastr.success("Sukces", "Kategoria została dodana");
      resolve();
    } catch (error) {
      toastr.error(
        "Błąd",
        "Wystąpił błąd podzczas dodawania kategorii"
      );
      reject(error);
    }
  });

export const deletePlatformTaxonFromProductGroup = (id) =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(
        `/platform_taxon_product_groups/${id}`
      );
      toastr.success(
        "Sukces",
        "Kategoria została usunięta"
      );
      resolve();
    } catch (error) {
      toastr.error(
        "Błąd",
        "Wystąpił błąd podzczas usuwania kategorii"
      );
      reject(error);
    }
  });
