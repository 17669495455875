import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import { Select, Label } from "expano-components";
import { Statuses, ActionsGroup } from "components";
import { productExport } from "actions/product_group/export";
import SalesStatusModal from "./SalesStatusModal";
import sales_status_options from "../const/sales_status_options";

const ProductExportLine = (data) => {
  const [
    is_open_sale_status_modal,
    handleOpenSaleStatusModal,
  ] = useState(false);

  const [sales_status, setSalesStatus] = useState(null);

  return (
    <tr key={data.id}>
      <td>
        <Label
          left={{
            color: data?.channel?.shop?.label_color,
            text: data?.channel?.shop?.label_text,
          }}
          right={{
            color: `#${data?.channel?.platform?.label_color}`,
            text: data?.channel?.platform?.label_text,
          }}
          type="double"
        />
        <span>{data.channel?.identifier}</span>
      </td>
      <td>
        {!!data.offer_url ? (
          <>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={data.offer_url}
              data-tip="Przejdź do oferty">
              <Statuses text="Aktywna" status="success" />
            </a>
            <ReactTooltip
              arrowColor="#ebecf3"
              backgroundColor="#ebecf3"
              textColor="#74788d"
              place="left"
              type="light"
              effect="float"
            />
          </>
        ) : (
          <Statuses text="Nie dodano" status="default" />
        )}
      </td>
      <td>
        <Select
          searchable={false}
          value={
            sales_status_options.find(
              ({ value }) => value === data.sales_status
            ) || {}
          }
          options={sales_status_options}
          handleChange={(value) => {
            setSalesStatus(value);
            handleOpenSaleStatusModal(true);
          }}
        />
      </td>
      <td>
        <ActionsGroup
          options={[
            {
              label:
                data.export_type === "products"
                  ? "Eksportuj produkt"
                  : "Eksportuj aliasy produktowe",
              icon: null,
              onClick: () => productExport(data.id),
            },
          ]}
        />
      </td>
      {is_open_sale_status_modal && (
        <SalesStatusModal
          product={data?.product}
          id={data?.id}
          refetch={data.refetch}
          sales_status={sales_status}
          handleClose={() => {
            handleOpenSaleStatusModal(false);
            setSalesStatus(null);
          }}
        />
      )}
    </tr>
  );
};

export default ProductExportLine;
