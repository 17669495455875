import { useQuery } from "@tanstack/react-query";
import { toastr } from "react-redux-toastr";
import { client } from "utils/api";

const useQueryHook = ({
  query_keys = [],
  path,
  enabled = true,
}) => {
  const {
    isLoading: is_loading,
    isFetching: is_fetching,
    data,
    refetch,
  } = useQuery(query_keys, () => client.get(path), {
    onError: (err) =>
      toastr.error(
        "Błąd",
        err?.response?.data?.message ||
          "Wystąpił błąd podczas pobierania"
      ),
    enabled,
    initialData: { data: [], meta: {} },
    placeholderData: { data: [], meta: {} },
    keepPreviousData: true,
  });

  return { is_loading, is_fetching, data, refetch };
};

export default useQueryHook;
