import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { checkProductCardView } from "utils/helpers";
import Item from "./components/Item";

const Images = ({
  channel,
  description_section_products,
  ...rest
}) => {
  const show_description_section_products = checkProductCardView(
    {
      type: "description_section_product_image",
      channel_type: channel?.type,
    }
  );
  return (
    <div className="images-box section-group__image">
      <div className="images-box__header">
        <p>Grafika</p>
      </div>
      <ul className="images-box__list">
        {show_description_section_products &&
          description_section_products?.length > 0 &&
          description_section_products
            .sort((a, b) =>
              a.product.sku.localeCompare(
                b.product.sku,
                undefined,
                {
                  numeric: true,
                  sensitivity: "base",
                }
              )
            )
            .map((product) => (
              <Item
                key={product.id}
                data={product}
                image={product?.product_image?.image}
                {...rest}
              />
            ))}
      </ul>
    </div>
  );
};

export default connect(
  ({
    channel_product_group: {
      data: { channel },
    },
  }) => ({ channel })
)(withRouter(Images));
