import React from "react";

import { Button, Textarea } from "expano-components";
import { Modal } from "components";

const KeywordPreviewModal = ({ data, handleClose }) => {
  const keywords = data.map(({ name }) => name).join("\n");

  return (
    <Modal
      header={{ title: "Kopiuj slowa kluczowe" }}
      handleClose={handleClose}
      footer={
        <Button type="cancel" text="Zamknij" onClick={handleClose} />
      }>
      <Textarea
        meta={{}}
        input={{
          name: "keywords",
          value: keywords,
          readOnly: true,
        }}
      />
    </Modal>
  );
};

export default KeywordPreviewModal;
