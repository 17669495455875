import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Label, ModalUpload } from "expano-components";
import { Button } from "components";
import { importShoploVariants } from "actions/settings";

import { ReactComponent as UploadIcon } from "icons/upload.svg";

const ChannelListItem = ({
  id,
  identifier,
  type,
  shop,
  platform,
}) => {
  const [is_open_upload, handleOpenUpload] = useState(
    false
  );
  return (
    <>
      {is_open_upload &&
        ReactDOM.createPortal(
          <ModalUpload
            accept="text/csv"
            headerText="Importuj produkty z Shoplo"
            handleUpload={(file) =>
              importShoploVariants(id, file)
            }
            handleClose={() => handleOpenUpload(false)}
          />,
          document.querySelector("#root")
        )}
      <tr key={id}>
        <td width="200px">
          <Label
            type="double"
            left={{
              text: shop?.label_text,
              color: `#${shop?.label_color}`,
            }}
            right={{
              text: platform?.label_text,
              color: `#${platform?.label_color}`,
            }}
          />
        </td>
        <td>{identifier}</td>
        <td width="100px">
          <div className="df h-end p-r-25">
            {type === "Shoplo::Channel" && (
              <button
                type="button"
                className="btn btn-icon"
                onClick={() => handleOpenUpload(true)}>
                <UploadIcon />
              </button>
            )}
            {type && (
              <Button
                type="edit"
                path={`/settings/${type
                  .toLowerCase()
                  .split("::")
                  .join("_")}/${id}`}
              />
            )}
          </div>
        </td>
      </tr>
    </>
  );
};

export default ChannelListItem;
