import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

import { AsidePanel, ProductExport } from "./components";
import { connect } from "react-redux";

const ExportPanel = ({
  products,
  history,
  match: {
    params: { product_group_id, type_id, type },
  },
}) => {
  useEffect(() => {
    if (!type_id && products.length > 0) {
      history.push(
        `/product_groups/${product_group_id}/statuses/products/${products[0].id}`
      );
    }
  }, [type_id]);

  return (
    <div className="main-wrapper">
      <AsidePanel />
      {type === "products" && Boolean(type_id) && (
        <ProductExport />
      )}
    </div>
  );
};

export default connect(
  ({
    product_group: {
      data: { products },
    },
  }) => ({ products })
)(withRouter(ExportPanel));
