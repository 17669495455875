import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ReactComponent as PlusIcon } from "icons/plus.svg";
import AddKeywordModal from "./components/AddKeywordModal";
import KeywordPreviewModal from "./components/KeywordPreviewModal";
import Item from "./components/Item";

const countries = [
  "PL",
  "UK",
  "DE",
  "ES",
  "FR",
  "IT",
  "SE",
  "NL"
];

const ProductGroupPanel = ({
  history,
  product_group_country_keywords,
  match: {
    params: { product_group_id, type },
  },
}) => {
  const [
    is_open_keyword_modal,
    handleOpenKeywordModal,
  ] = useState(false);
  const [
    is_open_keywords_preview,
    setOpenKeywordsPreview,
  ] = useState(false);

  const product_group_country_keywords_per_country_code = product_group_country_keywords.filter(
    ({ country_code }) => country_code === type
  );

  return (
    <>
      <div className="create-table">
        {is_open_keyword_modal && (
          <AddKeywordModal
            product_group_id={product_group_id}
            country_code={type}
            handleClose={() =>
              handleOpenKeywordModal(false)
            }
          />
        )}
        {is_open_keywords_preview && (
          <KeywordPreviewModal
            data={
              product_group_country_keywords_per_country_code
            }
            handleClose={() =>
              setOpenKeywordsPreview(false)
            }
          />
        )}
        <ul className="panel-tabs">
          {countries.map((name) => (
            <li
              key={name}
              className={`tab dashboard-tab-text ${
                name === type ? "active" : ""
              }`}
              onClick={() =>
                history.push(
                  `/product_groups/${product_group_id}/country_keywords/${name}`
                )
              }>
              {name}
            </li>
          ))}
        </ul>
        <table>
          <thead>
            <tr>
              <th>Nazwa</th>
              <th>Wolumen</th>
              <th>
                <button
                  disabled={
                    product_group_country_keywords_per_country_code.length ===
                    0
                  }
                  className="btn btn-sm btn-blue"
                  onClick={() =>
                    setOpenKeywordsPreview(true)
                  }>
                  <span>Kopiuj</span>
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {product_group_country_keywords_per_country_code.length >
              0 &&
              product_group_country_keywords_per_country_code.map(
                (data) => (
                  <Item
                    {...data}
                    product_group_id={product_group_id}
                    key={data.id}
                  />
                )
              )}
            <tr>
              <td>
                <button
                  className="create-table-btn"
                  type="button"
                  onClick={() =>
                    handleOpenKeywordModal(true)
                  }>
                  <PlusIcon />
                  <span>Dodaj słowo kluczowe</span>
                </button>
              </td>
              <td />
              <td />
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default connect(
  ({
    product_group: {
      data: { product_group_country_keywords },
    },
  }) => ({
    product_group_country_keywords,
  })
)(withRouter(ProductGroupPanel));
