import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  createChannelProductGalleryImages,
  updateGallerySectionProductImage,
  deleteGallerySectionProductImage,
} from "actions/product_group/channel_product_gallery_image";
import { CircleLabel } from "components";
import {
  ImageBox,
  NewBox,
  DeleteBox,
  RotateGrid,
} from "./components";
import { checkProductCardView } from "utils/helpers";

const GalleryItem = ({
  channel,
  data,
  images,
  gallery360,
  id,
  createChannelProductGalleryImages,
  updateGallerySectionProductImage,
  deleteGallerySectionProductImage,
}) => {
  const [is_open, setOpen] = useState(false);

  const handleAdd = async (ids) => {
    if (!ids.length) return;

    await createChannelProductGalleryImages(
      ids.map((product_image_id) => ({
        channel_product_id: id,
        product_image_id,
      }))
    );
  };

  const show_gallery_360 = checkProductCardView({
    type: "gallery_360",
    channel_type: channel?.type,
  });

  return (
    <div
      className={`gallery-item ${is_open ? "open" : ""}`}>
      <div
        className="gallery-item__header"
        onClick={() => setOpen(!is_open)}>
        <div className="gallery-item__header__title">
          <button className="btn-collapse" type="button" />
          <CircleLabel type="p" />
          <span className="sku">{data.sku}</span>
          <span className="name">{data.name}</span>
        </div>
        <div className="gallery-item__header__info">
          <p>Ilość zdjęć:</p>
          <span
            className={`label ${
              images.length === 0 ? "danger" : ""
            }`}>
            {images.length}
          </span>
          {show_gallery_360 && (
            <span
              className={`label ${
                gallery360.length < 24 ? "danger" : ""
              }`}>
              360&#176;
            </span>
          )}
        </div>
      </div>
      <div className="gallery-item__body">
        <div className="gallery-item__images">
          {images
            .sort(
              (item, next) => item.position - next.position
            )
            .map((item) => (
              <ImageBox
                key={item.id}
                type="p"
                data={data}
                is_feed={item.is_feed}
                image={item.product_image.image}
                image_id={item.id}
                id={data.id}
                handleSubmit={(active) =>
                  updateGallerySectionProductImage(
                    item.id,
                    {
                      product_image_id: active[0],
                    }
                  )
                }
                handleDelete={() =>
                  deleteGallerySectionProductImage(item.id)
                }
                isMulti={false}
              />
            ))}
          <NewBox
            type="p"
            data={data}
            id={data.id}
            handleSubmit={handleAdd}
            isMulti={true}
          />
          <DeleteBox data={data} images={images} />
        </div>
        {show_gallery_360 && (
          <RotateGrid
            channel_product_id={id}
            id={data.id}
            gallery360={gallery360}
          />
        )}
      </div>
    </div>
  );
};

export default connect(
  ({
    channel_product_group: {
      data: { channel },
    },
  }) => ({ channel }),
  (dispatch) => ({
    createChannelProductGalleryImages: (images) =>
      dispatch(createChannelProductGalleryImages(images)),
    updateGallerySectionProductImage: (id, data) =>
      dispatch(updateGallerySectionProductImage(id, data)),
    deleteGallerySectionProductImage: (id) =>
      dispatch(deleteGallerySectionProductImage(id)),
  })
)(withRouter(GalleryItem));
