import { client } from "utils/api";

const getPlatformTaxonOptions = (q = null, platform_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.get(
        `/platform_taxons?context=list&q[platform_id_eq]=${platform_id}&per_page=15${
          q ? `&q[name_matches]=%25${q}%25` : ""
        }`
      );
      const options = data.map(({ id, name }) => ({
        label: name,
        value: id,
      }));
      resolve(options);
    } catch (error) {
      console.log(error);
      reject([]);
    }
  });
};

export default getPlatformTaxonOptions;
