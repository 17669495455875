import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { AsidePanel, AliasesList } from "./components";

const Alias = ({
  products,
  history,
  match: {
    params: { product_group_id, type_id },
  },
}) => {
  useEffect(() => {
    if (!type_id && products.length > 0) {
      history.push(
        `/product_groups/${product_group_id}/aliases/products/${products[0].id}`
      );
    }
  }, [type_id]);

  return (
    <div className="main-wrapper">
      <AsidePanel />
      <AliasesList />
    </div>
  );
};

export default connect(
  ({
    product_group: {
      data: { products },
    },
  }) => ({
    products,
  })
)(withRouter(Alias));
