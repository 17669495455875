import { toastr } from "react-redux-toastr";
import { client } from "utils/api";

const getPlatform = async (id) => {
  if (id === "root") {
    return {
      id: "root",
      name: "Root",
      type: "Root",
    };
  }
  try {
    const { data } = await client.get(
      `/platforms/${id}?context=ui_platform_taxons`
    );
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getPlatformTaxons = ({
  id,
  page = 1,
  per_page = 10,
  q = null,
}) => async (dispatch) => {
  try {
    dispatch({ type: "PLATFORM_TAXONS_LOADING" });

    const platform = await getPlatform(id);

    const { data: taxons, meta } = await client.get(
      `/${
        id === "root"
          ? "root_taxons?"
          : `platform_taxons?&q[platform_id_eq]=${id}&`
      }context=ui_index&q[ancestry_blank]=true&page=${page}&per_page=${per_page}${
        q ? `&q[name_matches]=%25${q}%25` : ""
      }`
    );

    dispatch({
      type: "PLATFORM_TAXONS_SUCCESS",
      data: {
        taxons,
        platform,
      },
      meta,
    });
  } catch (error) {
    dispatch({ type: "PLATFORM_TAXONS_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas pobierania"
    );
  }
};

export const getPlatformTaxon = (
  platform_id,
  platform_taxon_id
) => async (dispatch) => {
  dispatch({ type: "PLATFORM_TAXON_LOADING" });

  try {
    const platform = await getPlatform(platform_id);

    const { data } = await client.get(
      `/${
        platform_id === "root"
          ? "root_taxons"
          : "platform_taxons"
      }/${platform_taxon_id}?context=ui_show`
    );

    dispatch({
      type: "PLATFORM_TAXON_SUCCESS",
      data: { ...data, platform },
    });
  } catch (error) {
    dispatch({ type: "PLATFORM_TAXON_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas pobierania"
    );
  }
};
