import "./styles.scss";

import React from "react";
import Loaders from "react-loader-spinner";

const Loader = () => (
  <div className="loader">
    <Loaders
      type="Watch"
      height={40}
      width={40}
      color="#36bcff"
      visible={true}
      secondaryColor="#36bcff"
      strokeWidth={2}
      strokeWidthSecondary={2}
    />
  </div>
);

export default Loader;
