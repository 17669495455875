import React, { useState } from "react";
import { CircleLabel } from "components";
import { updateDescriptionSectionProductImage } from "actions/product_group/description_sections";

import ImageBox from "./components/ImageBox";
import NewBox from "./components/NewBox";
import { connect } from "react-redux";

const Item = ({
  data,
  image,
  updateDescriptionSectionProductImage,
}) => {
  const [is_open, open] = useState(false);

  const handleSubmit = (active_items) =>
    updateDescriptionSectionProductImage(data.id, {
      product_image_id: active_items?.[0],
    });
  try {
    return (
      <li
        className={`images-box__list__item ${
          is_open ? "open" : ""
        }`}>
        <div
          className="images-box__list__item__header"
          onClick={() => open(!is_open)}>
          <button className="btn-collapse" type="button" />
          <CircleLabel type="p" />
          <div className="product-name">
            <p className="name">{data.product.name}</p>
            <p className="sku">{data.product.sku}</p>
          </div>
        </div>
        <div className="images-box__list__item__body">
          <div className="gallery-item__images">
            {image ? (
              <ImageBox
                handleSubmit={handleSubmit}
                image={image}
                data={data}
                id={data.product.id}
                isMulti={false}
              />
            ) : (
              <NewBox
                handleSubmit={handleSubmit}
                data={data}
                id={data.product.id}
                isMulti={false}
              />
            )}
          </div>
        </div>
      </li>
    );
  } catch (error) {
    console.log(error);
    return null;
  }
};

export default connect(null, (dispatch) => ({
  updateDescriptionSectionProductImage: (
    id,
    data,
    type_id
  ) =>
    dispatch(
      updateDescriptionSectionProductImage(
        id,
        data,
        type_id
      )
    ),
}))(Item);
