import React, { useEffect } from "react";
import { Field, reduxForm, reset } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Button, Select, Input } from "expano-components";

import { PageLoader } from "components";
import {
  resetChannelSettings,
  getAllegroChannelSettings,
  updateAllegroChannelSettings,
} from "actions/setting";

import export_options from "utils/select_options/export_options";
import Header from "../../components/Header";

const SelectField = (props) => <Select {...props} />;
const InputField = (props) => <Input {...props} />;

const AllegroForm = ({
  dispatch,
  getAllegroChannelSettings,
  updateAllegroChannelSettings,
  resetChannelSettings,
  match: {
    params: { id },
  },
  submitting,
  handleSubmit,
  pristine,
  setting,
  initialValues,
  change,
}) => {
  useEffect(() => {
    getAllegroChannelSettings(id);
    return () => {
      resetChannelSettings();
      dispatch(reset("edit-allegro-channel"));
    };
  }, [id]);

  const { status, data } = setting;

  const onSubmit = (values) =>
    updateAllegroChannelSettings(id, values);

  if (["invalid", "loading", "failure"].includes(status))
    return <PageLoader />;

  return (
    <div className="dashboard">
      <Header {...data} />
      <form className="dashboard__form">
        <div className="allegro_channel_form">
          <div className="box-sm">
            <Field
              name={`export_type`}
              defaultValue={export_options.find(
                ({ value }) =>
                  value === initialValues.export_type
              )}
              component={SelectField}
              label="Typ eksportu"
              options={export_options}
              handleChange={(value) =>
                change("export_type", value)
              }
            />
            <Field
              name="baselinker_category_id"
              component={InputField}
              label="Baselinker id kategorii"
            />
          </div>
        </div>
      </form>
      <div className="dashboard__form__footer">
        <Button
          type="save"
          text="Zapisz"
          disabled={pristine || submitting}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ setting }) => ({
  setting,
  initialValues: {
    export_type: setting.data?.export_type,
    baselinker_category_id:
      setting.data?.baselinker_category_id,
  },
});
const mapDispatchToProps = (dispatch) => ({
  getAllegroChannelSettings: (id) =>
    dispatch(getAllegroChannelSettings(id)),
  updateAllegroChannelSettings: (id, values) =>
    dispatch(updateAllegroChannelSettings(id, values)),
  resetChannelSettings: () =>
    dispatch(resetChannelSettings()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "edit-allegro-channel",
    enableReinitialize: true,
  })(withRouter(AllegroForm))
);
