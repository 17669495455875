import React, { useEffect } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { Search, Pagination, Label } from "expano-components";
import { Main, PageLoader, Dashboard } from "components";

import { getPlatformTaxons } from "actions/platform_taxons";

import { ReactComponent as CategoryIcon } from "icons/category.svg";

import Tree from "./components/Tree";

const available_platforms = [
  "Spree::Platform",
  "Ebay::Platform",
  "Allegro::Platform",
  "Amazon::Platform",
  "Wsf::Platform",
  "Shoplo::Platform",
  "Root",
];

const CategoryTaxons = ({
  location,
  match: {
    params: { platform_id },
  },
  history,
  platform_taxons,
  getPlatformTaxons,
}) => {
  const { q, page, per_page } = queryString.parse(location.search);
  useEffect(() => {
    getPlatformTaxons({
      id: platform_id,
      page,
      per_page,
      q,
    });
  }, [platform_id, location.search]);

  const updateSearch = (page, per_page, q) =>
    history.push(
      `?${queryString.stringify({
        page: undefined,
        per_page: undefined,
        q,
      })}`
    );

  if (["invalid", "loading", "failure"].includes(platform_taxons.status)) {
    return <PageLoader />;
  }

  const {
    data: { platform, taxons },
    meta,
  } = platform_taxons;

  return (
    <Main
      page={{
        name: platform?.name,
        icon: <CategoryIcon />,
        label: "asd",
        breadcrumbs: [
          {
            name: "Kategorie",
            path: "/platforms/platform_taxons",
          },
          { name: platform?.name },
        ],
        buttons: (
          <Label
            text={platform?.label_text}
            type="bordered"
            color={platform?.label_color}
          />
        ),
      }}
    >
      <Dashboard>
        <Search defaultValue={q} action={updateSearch} />
        {available_platforms.includes(platform_taxons.data?.platform?.type) ? (
          <>
            <Tree
              head={
                platform_taxons.data?.platform?.type === "Amazon::Platform"
                  ? ["Nazwa", "Typ produktu amazon"]
                  : ["Nazwa"]
              }
              data={taxons}
              row_data={
                platform_taxons.data?.platform?.type === "Amazon::Platform"
                  ? ["amazon_product_type_definitions"]
                  : []
              }
            />
            <Pagination
              data={{ ...meta, query: q }}
              history={history}
              location={location}
            />
          </>
        ) : (
          <p style={{ padding: "0 30px" }}>Niezidentyfikowana platforma</p>
        )}
      </Dashboard>
    </Main>
  );
};

export default connect(
  ({ platform_taxons }) => ({
    platform_taxons,
  }),
  (dispatch) => ({
    getPlatformTaxons: (data) => dispatch(getPlatformTaxons(data)),
  })
)(CategoryTaxons);
