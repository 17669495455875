export const CHANGE_SORT = "CHANGE_SORT";
export const SET_FILTER_VALUE = "SET_FILTER_VALUE";
export const REMOVE_FILTER_VALUE = "REMOVE_FILTER_VALUE";
export const SET_PAGINATION_META = "SET_PAGINATION_META";

const mainListReducer = (state, { type, payload }) => {
  switch (type) {
    case CHANGE_SORT: {
      return {
        ...state,
        active_sort: payload,
      };
    }
    case SET_FILTER_VALUE: {
      const filters = [...state.filters];

      const index = filters.indexOf(
        filters?.find(
          (filter) => filter?.name === payload.name
        )
      );
      if (index > -1) {
        filters[index].values = [
          ...filters[index].values.filter(
            ({ value }) => value !== payload.value
          ),
          { value: payload.value, label: payload.label },
        ];
      }
      return {
        ...state,
        meta: { ...state.meta, page: 1 },
        filters,
      };
    }
    case REMOVE_FILTER_VALUE: {
      const filters = [...state.filters];
      const index = filters.indexOf(
        filters.find(
          (filter) => filter?.name === payload.name
        )
      );
      if (index > -1) {
        filters[index].values = [
          ...filters[index].values.filter(
            ({ value }) => value !== payload.value
          ),
        ];
      }
      return { ...state, filters };
    }
    case SET_PAGINATION_META: {
      return { ...state, meta: { ...payload } };
    }
    default:
      return state;
  }
};

export default mainListReducer;
