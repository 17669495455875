import React, { useState } from "react";
import { Button, MainList, Main } from "components";

import { ReactComponent as PropertyIcon } from "icons/property.svg";

import { sort, filters } from "./const/data";
import ModalDeleteProperty from "./components/ModalDeleteProperty";

const default_state = { type: null, data: null };

const PropertiesList = ({ history }) => {
  const [property_modal, setPropertyModal] = useState(
    default_state
  );

  const onClose = () => setPropertyModal(default_state);

  return (
    <Main
      page={{
        name: "Właściwości",
        icon: <PropertyIcon />,
        breadcrumbs: [
          {
            name: "Właściwości",
          },
        ],
        buttons: (
          <Button
            type="add"
            text="Dodaj właściwość"
            path="/properties/new"
          />
        ),
      }}>
      <MainList
        query_key="properties"
        api_path="/properties"
        context="ui_index"
        sort_column="id"
        sort_direction="desc"
        sort={sort}
        filters={filters}
        handleNavigate={(item) =>
          history.push(`/properties/${item.id}`)
        }
        renderItem={({ id, internal_name }) => (
          <>
            <td>{id}</td>
            <td>{internal_name}</td>
          </>
        )}
        renderActions={(item) => [
          {
            type: "remove",
            handleClick: () =>
              setPropertyModal({
                type: "remove",
                data: item,
              }),
          },
        ]}>
        {property_modal?.type === "remove" && (
          <ModalDeleteProperty
            handleClose={onClose}
            {...property_modal.data}
          />
        )}
      </MainList>
    </Main>
  );
};

export default PropertiesList;
