import { toastr } from "react-redux-toastr";
import { client } from "utils/api";
import { getProductGroup } from "actions/product_group";

export const updateProductGroupProperties = (
  data
) => async (dispatch, getState) => {
  try {
    const {
      product_group: {
        data: { id: product_group_id },
      },
    } = getState();

    await Promise.all(
      data.map(({ id, property_option_id }) =>
        client.put(`/product_group_properties/${id}`, {
          property_option_id,
        })
      )
    );
    await dispatch(getProductGroup(product_group_id));

    toastr.success(
      "Sukces",
      "Produkt grupowy został zaktualizowany"
    );
  } catch (error) {
    toastr.error(
      "Błąd",
      error?.response?.data?.message || "Wystąpił błąd"
    );
  }
};
