import React, { useState } from "react";
import { Button, ModalDelete } from "expano-components";
import { url } from "utils/api";
import { GalleryUpload } from "components";
import ModalFeedImage from "./components/ModalFeedImage";

import { uploadProductImages } from "actions/product_group/product_images";

const ImageBox = ({
  id,
  data,
  image,
  image_id,
  is_feed,
  handleSubmit,
  isMulti,
  handleDelete,
}) => {
  const [show_delete_modal, showDeleteModal] = useState(
    false
  );
  const [show_upload_modal, showUploadModal] = useState(
    false
  );
  const [show_feed_modal, showFeedModal] = useState(false);

  const handleUploadImage = async (files) => {
    try {
      await uploadProductImages({ product_id: id, files });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {show_delete_modal && (
        <ModalDelete
          handleDelete={async () => {
            await handleDelete();
            showDeleteModal(false);
          }}
          handleClose={() => showDeleteModal(false)}
          name="zdjęcie?"
        />
      )}
      {show_upload_modal && (
        <GalleryUpload
          id={id}
          data={data}
          isMulti={isMulti}
          handleClose={() => showUploadModal(false)}
          handleUpload={handleUploadImage}
          limit={1}
          handleSubmit={handleSubmit}
        />
      )}
      {show_feed_modal && (
        <ModalFeedImage
          id={image_id}
          product_data={data}
          image={`${url}${image}`}
          handleClose={() => showFeedModal(false)}
        />
      )}
      <div
        className={`grid-item single-image with_feed ${
          is_feed ? "is_feed" : ""
        }`}
        style={{
          backgroundImage: `url(${url}${image})`,
        }}>
        {is_feed && (
          <img
            className="feed-icon"
            alt=""
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0Ij4KICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzE3IC01NDEpIj4KICAgIDxjaXJjbGUgZmlsbD0iIzBhYmU4YyIgY3g9IjciIGN5PSI3IiByPSI3IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzMTcgNTQxKSIgLz4KICAgIDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMxNyA1NDApIj4KICAgICAgPHJlY3QgZmlsbD0iI2ZmZiIgd2lkdGg9IjQuMTI1IiBoZWlnaHQ9IjEuMjUiIHJ4PSIwLjYyNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNS40MzggNC42MjUpIiAvPgogICAgICA8cmVjdCBmaWxsPSIjZmZmIiB3aWR0aD0iNy4xMjUiIGhlaWdodD0iMS4yNSIgcng9IjAuNjI1IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1LjQzOCAxMS44MTIpIHJvdGF0ZSgtOTApIiAvPgogICAgICA8cmVjdCBmaWxsPSIjZmZmIiB3aWR0aD0iMy4xMjUiIGhlaWdodD0iMS4yNSIgcng9IjAuNjI1IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1LjQzOCA3LjEyNSkiIC8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4="
          />
        )}
        <div className="overlay">
          <Button
            type="edit"
            className="edit"
            onClick={() => showUploadModal(true)}
          />
          <div className="row">
            <Button
              type="delete-bin"
              className="remove"
              onClick={() => showDeleteModal(true)}
            />
            <button
              type="button"
              className="success"
              onClick={() => showFeedModal(true)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20">
                <path
                  fill="#fff"
                  opacity="0.3"
                  d="M5.548,2h6.566a1.25,1.25,0,0,1,.807.3l3.97,3.358a1.25,1.25,0,0,1,.443.954V17.069c0,1.492-.017,1.6-1.548,1.6H5.548c-1.531,0-1.548-.105-1.548-1.6V3.6C4,2.105,4.017,2,5.548,2Z"
                  transform="translate(-0.667 -0.333)"
                />
                <rect
                  fill="#fff"
                  width="5.5"
                  height="1.667"
                  rx="0.833"
                  transform="translate(7.25 6.167)"
                />
                <rect
                  fill="#fff"
                  width="9.5"
                  height="1.667"
                  rx="0.833"
                  transform="translate(7.25 15.75) rotate(-90)"
                />
                <rect
                  fill="#fff"
                  width="4.167"
                  height="1.667"
                  rx="0.833"
                  transform="translate(7.25 9.5)"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageBox;
