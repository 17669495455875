import { toastr } from "react-redux-toastr";
import { client } from "utils/api";

export const addDescriptionSectionComment = async (
  values
) => {
  try {
    await client.post(
      `/description_section_comments`,
      values
    );
    toastr.success("Sukces", "Komentarz została dodany");
  } catch (error) {
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas dodawania komentarza"
    );
  }
};

export const deleteDescriptionSectionComment = async (
  id
) => {
  try {
    await client.delete(
      `/description_section_comments/${id}`
    );
    toastr.success("Sukces", "Komentarz został usunięty");
  } catch (error) {
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas usuwania komentarza"
    );
  }
};
