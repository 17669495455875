import React, { useState } from "react";
import { GalleryUpload } from "components";
import { uploadProductImages } from "actions/product_group/product_images";

import { ReactComponent as PlusGalleryIcon } from "icons/plus_gallery.svg";

const NewBox = ({ data, isMulti, id, handleSubmit }) => {
  const [
    show_gallery_modal,
    handleOpenGalleryModal,
  ] = useState(false);
  const handleUploadImage = async (files) => {
    try {
      await uploadProductImages({ product_id: id, files });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {show_gallery_modal && (
        <GalleryUpload
          handleUpload={handleUploadImage}
          data={data}
          id={id}
          isMulti={isMulti}
          handleClose={() => handleOpenGalleryModal(false)}
          handleSubmit={handleSubmit}
          limit={1}
        />
      )}
      <button
        type="button"
        className="grid-item new-image"
        onClick={() => handleOpenGalleryModal(true)}>
        <PlusGalleryIcon />
        <p>dodaj zdjęcie</p>
      </button>
    </>
  );
};

export default NewBox;
