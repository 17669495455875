import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Loader from "react-loader-spinner";
import { client } from "utils/api";

const getTaxonsPath = ({
  platform_id,
  ancestry,
  id,
}) => {
  const is_root = platform_id === "root";
  const path = is_root
    ? `/root_taxons?context=ui_index&q[ancestry_eq]=${
        ancestry ? `${ancestry}/` : ""
      }${id}`
    : `/platform_taxons?context=ui_index&q[platform_id_eq]=${platform_id}&q[ancestry_eq]=${
        ancestry ? `${ancestry}/` : ""
      }${id}`;
  return path;
};

const ListItem = ({
  ancestry,
  id,
  match,
  row_data,
  children,
  history,
  level,
  ...rest
}) => {
  const [is_open, setOpen] = useState(false);
  const [is_loading, setLoading] = useState(true);
  const [is_loaded, setLoaded] = useState(false);
  const [categories, setCategories] = useState(null);
  const [has_children, setHasChildren] = useState(true);

  const getTaxons = async () => {
    if (is_loaded) return false;

    const path = getTaxonsPath({
      platform_id: match.params.platform_id,
      ancestry,
      id,
    });

    try {
      const { data } = await client.get(path);
      setCategories(data);
      setHasChildren(data.length > 0);
    } catch (error) {
      setCategories(null);
      setHasChildren(false);
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };
  return (
    <li className="tree__item">
      <table className="table">
        {level !== 0 && (
          <div
            className="tree__line tree__line--horizontal"
            style={{ left: `${level * 32 - 10}px` }}
          />
        )}
        <tbody className="table__body">
          <tr>
            <td
              style={{
                paddingLeft: `${level === 0 ? 18 : level * 32 + 18}px`,
                width: `${100 / (row_data.length + 2)}%`,
              }}
            >
              {has_children ? (
                <>
                  <button
                    className="button button__tree"
                    onClick={() => {
                      setOpen(!is_open);
                      if (!is_open) {
                        getTaxons();
                      }
                    }}
                  >
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="9"
                        height="9"
                        fill="white"
                        stroke="#5D78FF"
                      />
                      <line x1="3" y1="5" x2="7" y2="5" stroke="#5D78FF" />
                      {!is_open && (
                        <line x1="5" y1="7" x2="5" y2="3" stroke="#5D78FF" />
                      )}
                    </svg>
                  </button>
                  <span className="tree-span tree-span--hide tree-span-after">
                    {rest.name}
                  </span>
                </>
              ) : (
                rest.name
              )}
            </td>
            {row_data.map((attr, index) => (
              <td
                key={index}
                style={{
                  width: `${100 / (row_data.length + 2)}%`,
                }}
              >
                {rest[attr]}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
      {is_open && categories?.length > 0 && !is_loading && (
        <ul className={`tree__list`}>
          <div
            className="tree__line tree__line--vertical"
            style={{
              left: `${level * 32}px`,
              height: categories.length === 1 ? "65px" : "initial",
            }}
          />
          {categories.map((list_el) => (
            <ListItem
              key={list_el.id}
              name={list_el.name}
              match={match}
              id={list_el.id}
              row_data={row_data}
              level={level + 1}
              history={history}
              ancestry={list_el.ancestry}
              amazon_product_type_definitions={list_el.amazon_product_type_definitions}
            />
          ))}
        </ul>
      )}
      {is_open && is_loading && (
        <ul className={`tree__list`}>
          <li className="tree__item">
            <table className="table">
              {level !== 0 && (
                <div
                  className="tree__line tree__line--horizontal"
                  style={{ left: `${level * 32 - 10}px` }}
                />
              )}
              <tbody className="table__body">
                <tr>
                  <td
                    style={{
                      width: `100%`,
                      textAlign: "center",
                    }}
                  >
                    <Loader
                      type="Watch"
                      color="#fd397a"
                      height={20}
                      width={20}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </li>
        </ul>
      )}
    </li>
  );
};

export default withRouter(ListItem);
