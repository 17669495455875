import React from "react";

const CircleLabel = ({ type }) => {
  const renderIcon = () => {
    switch (type) {
      case "pg":
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
            <g transform="translate(-2794.5 -1051.5)">
              <circle
                stroke="#5d78ff"
                opacity="0.3"
                fill="#5d78ff"
                cx="10"
                cy="10"
                r="10"
                transform="translate(2795 1052)"
              />
              <path
                fill="#5d78ff"
                d="M.744,0A.192.192,0,0,1,.6-.06.192.192,0,0,1,.544-.2V-5.392A.209.209,0,0,1,.6-5.54.188.188,0,0,1,.744-5.6H3.008a2.551,2.551,0,0,1,1.632.472,1.664,1.664,0,0,1,.592,1.384A1.608,1.608,0,0,1,4.64-2.38a2.631,2.631,0,0,1-1.632.452H2.1V-.2a.2.2,0,0,1-.056.14A.188.188,0,0,1,1.9,0ZM2.968-3.072a.736.736,0,0,0,.516-.172.654.654,0,0,0,.188-.508.727.727,0,0,0-.172-.5.679.679,0,0,0-.532-.192h-.9v1.376ZM8.32.08A2.7,2.7,0,0,1,6.5-.484a2.124,2.124,0,0,1-.7-1.612Q5.8-2.32,5.8-2.824t.008-.736a2.011,2.011,0,0,1,.7-1.564A2.745,2.745,0,0,1,8.32-5.68a3.347,3.347,0,0,1,1.34.248,2.156,2.156,0,0,1,.872.636,1.382,1.382,0,0,1,.316.8.169.169,0,0,1-.052.124.169.169,0,0,1-.124.052H9.432a.231.231,0,0,1-.124-.028.313.313,0,0,1-.084-.084.944.944,0,0,0-.308-.384.972.972,0,0,0-.6-.16.866.866,0,0,0-.96.96q-.008.224-.008.688t.008.7q.032,1.016.976,1.016a1.1,1.1,0,0,0,.736-.232.874.874,0,0,0,.272-.7V-2.2H8.632a.2.2,0,0,1-.148-.06.2.2,0,0,1-.06-.148v-.624a.2.2,0,0,1,.06-.148.2.2,0,0,1,.148-.06h2.032a.2.2,0,0,1,.148.06.2.2,0,0,1,.06.148V-2.1a2.122,2.122,0,0,1-.316,1.172,2.015,2.015,0,0,1-.892.752A3.24,3.24,0,0,1,8.32.08Z"
                transform="translate(2799.456 1064.68)"
              />
            </g>
          </svg>
        );

      case "p":
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <g transform="translate(-316 -540)">
              <circle opacity="0.15" fill="#5d78ff" cx="8" cy="8" r="8" transform="translate(316 540)" />
              <path
                fill="#5d78ff"
                opacity="0.5"
                d="M.744,0A.192.192,0,0,1,.6-.06.192.192,0,0,1,.544-.2V-5.392A.209.209,0,0,1,.6-5.54.188.188,0,0,1,.744-5.6H3.008a2.551,2.551,0,0,1,1.632.472,1.664,1.664,0,0,1,.592,1.384A1.608,1.608,0,0,1,4.64-2.38a2.631,2.631,0,0,1-1.632.452H2.1V-.2a.2.2,0,0,1-.056.14A.188.188,0,0,1,1.9,0ZM2.968-3.072a.736.736,0,0,0,.516-.172.654.654,0,0,0,.188-.508.727.727,0,0,0-.172-.5.679.679,0,0,0-.532-.192h-.9v1.376Z"
                transform="translate(321.456 550.6)"
              />
            </g>
          </svg>
        );

      case "danger":
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <rect fill="none" width="20" height="20" />
            <path
              fillRule="evenodd"
              opacity="0.3"
              fill="#fd397a"
              d="M9.756,4.414,2.8,16.926a.833.833,0,0,0,.728,1.238h14.6a.833.833,0,0,0,.711-1.268L11.2,4.384a.833.833,0,0,0-1.439.03Z"
              transform="translate(-0.45 -0.664)"
            />
            <rect fill="#fd397a" width="1.667" height="5.833" rx="0.833" transform="translate(9.167 7.5)" />
            <rect fill="#fd397a" width="1.667" height="1.667" rx="0.833" transform="translate(9.167 14.167)" />
          </svg>
        );
      default:
        return null;
    }
  };

  return <span className="circle-box">{renderIcon()}</span>;
};
export default CircleLabel;
