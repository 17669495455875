import axios from "axios";
import queryString from "query-string";

export const url =
  process.env.NODE_ENV === "production"
    ? "https://api.pim.tradesk.pl"
    : "http://0.0.0.0:3000";

export const api_url = `${url}/v3`;

export const client = axios.create({ baseURL: api_url });

client.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const authorizeApi = (token) => {
  client.defaults.headers.common["Authorization"] = token;
};

export const handleErrors = (data) => {
  try {
    const errors = {};

    data.forEach(({ detail, source: { pointer } }) => {
      errors[pointer.split("/")[pointer.split("/").length - 1]] = detail;
    });
    return errors;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const stringifyQuery = (data) =>
  decodeURI(queryString.stringify(data, { arrayFormat: "bracket" }));

export const parseQuery = (data) =>
  queryString.parse(data, { arrayFormat: "bracket" });
