import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { Link, withRouter } from "react-router-dom";
import { Label } from "expano-components";

import { getCountryCode } from "utils/helpers";

const Aside = ({
  platforms,
  match: {
    params: { product_group_id, type_id },
  },
}) => {
  return (
    <div className="dashboard fh br">
      <ul className="aside-tabs">
        {platforms
          .filter(({ id }) => id !== "root")
          .map(
            ({
              id,
              name,
              country_code,
              label_color,
              label_text,
            }) => (
              <li
                className={classNames("aside-tabs__tab", {
                  active: Number(type_id) === id,
                  warning: false,
                  success: false,
                })}
                key={id}>
                <Link
                  to={`/product_groups/${product_group_id}/platform_taxons/platforms/${id}`}>
                  <div className="label-holder">
                    <Label
                      type="bordered"
                      color={`#${label_color}`}
                      text={label_text}
                    />
                  </div>
                  <div className="text-holder">
                    <p>
                      <span
                        className={`mr-10 flag-icon flag-icon-${getCountryCode(
                          country_code
                        )}`}
                      />
                      {name}
                    </p>
                  </div>
                </Link>
              </li>
            )
          )}
      </ul>
    </div>
  );
};

export default connect(
  ({ platforms: { data: platforms } }) => ({
    platforms,
  })
)(withRouter(Aside));
