import React from "react";

import { useMainList } from "../../context/provider";

const Header = () => {
  const { title } = useMainList();
  if (!title) return null;
  
  return (
    <div className="main-list__header">
      <div className="header-wrapper">
        <h2 className="header-title">{title}</h2>
      </div>
    </div>
  );
};

export default Header;
