import React, { useState } from "react";

import { Button } from "expano-components";
import { Modal, AsyncSelect } from "components";
import { addPlatformTaxonToProductGroup } from "actions/product_group/platform_taxons";
import getPlatformTaxonOptions from "utils/select_options/getPlatformTaxonOptions";
import { ReactComponent as CategoryIcon } from "icons/category.svg";

const AddPlatformTaxon = ({
  platform_id,
  product_group_id,
  refetch,
  handleClose,
}) => {
  const [platform_taxon_id, setPlatformTaxonId] = useState(
    null
  );
  return (
    <Modal
      size="lg"
      handleClose={handleClose}
      header={{
        title: "Dodaj kategorię",
        icon: <CategoryIcon />,
      }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="add-green"
            disabled={!platform_taxon_id}
            text="Dodaj"
            onClick={() =>
              addPlatformTaxonToProductGroup({
                product_group_id,
                platform_taxon_id,
              }).then(() => {
                handleClose();
                refetch();
              })
            }
          />
        </>
      }>
      <AsyncSelect
        label="Kategoria"
        onChange={({ value }) => setPlatformTaxonId(value)}
        getData={(q) =>
          getPlatformTaxonOptions(q, platform_id)
        }
      />
    </Modal>
  );
};

export default AddPlatformTaxon;
