import React, { useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Button, Checkbox, Input } from "expano-components";
import { Modal } from "components";
import { createProductGroupCountryKeyword } from "actions/product_group/country_keywords";

const validate = (values) => {
  let errors = {};
  if (!values.name) {
    errors.name = "Pole wymagane";
  }

  return errors;
};

const AddKeywordModal = ({
  product_group_id,
  country_code,
  valid,
  pristine,
  handleClose,
  handleSubmit,
  createProductGroupCountryKeyword,
}) => {
  const [is_loading, setLoading] = useState(false);

  const onSubmit = async ({
    name,
    volumen,
    is_title,
    is_used,
  }) => {
    try {
      setLoading(true);
      await createProductGroupCountryKeyword({
        product_group_id,
        country_code,
        name,
        volumen,
        is_title,
        is_used,
      });
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Modal
      header={{ title: "Dodaj slowo kluczowe" }}
      handleClose={handleClose}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="add-green"
            disabled={!valid || pristine || is_loading}
            text={is_loading ? "Proszę czekać..." : "Dodaj"}
            onClick={handleSubmit(onSubmit)}
          />
        </>
      }>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="submit" style={{ display: "none" }} />
        <Field
          label="Nazwa"
          name="name"
          component={Input}
        />
        <Field
          type="number"
          label="Wolumen"
          name="volumen"
          component={Input}
        />
        <Field
          label="Czy tytułowe?"
          name="is_title"
          component={Checkbox}
        />
        <Field
          label="Czy użyte?"
          name="is_used"
          component={Checkbox}
        />
      </form>
    </Modal>
  );
};

export default connect(
  () => ({
    initialValues: {
      name: "",
      volumen: "",
      is_title: false,
      is_used: false,
    },
  }),
  (dispatch) => ({
    createProductGroupCountryKeyword: (data) =>
      dispatch(createProductGroupCountryKeyword(data)),
  })
)(
  reduxForm({
    form: "create-product-group-country-keyword",
    validate,
    enableReinitialize: true,
  })(AddKeywordModal)
);
