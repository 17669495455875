import { toastr } from "react-redux-toastr";
import { client } from "utils/api";
import { getProductGroup } from "actions/product_group";

export const createProductGroupCountryKeyword = (
  values
) => async (dispatch) => {
  try {
    await client.post(
      "/product_group_country_keywords",
      values
    );
    await dispatch(
      getProductGroup(values.product_group_id)
    );
    toastr.success(
      "Sukces",
      "Słowo kluczowe zostało dodane"
    );
  } catch (error) {
    toastr.error(
      "Błąd",
      "Wystąpił błąd podzczas dodawania słowa kluczowego"
    );
    throw error;
  }
};

export const updateProductGroupCountryKeyword = (
  id,
  product_group_id,
  values
) => async (dispatch) => {
  try {
    await client.put(
      `/product_group_country_keywords/${id}`,
      values
    );
    await dispatch(getProductGroup(product_group_id));

    toastr.success(
      "Sukces",
      "Słowo kluczowe zostało zaktualizowane"
    );
  } catch (error) {
    toastr.error(
      "Błąd",
      "Wystąpił błąd podzczas aktualizacji słowa kluczowego"
    );
    throw error;
  }
};

export const deleteProductGroupCountryKeyword = ({
  id,
  product_group_id,
}) => async (dispatch) => {
  try {
    await client.delete(
      `/product_group_country_keywords/${id}`
    );
    await dispatch(getProductGroup(product_group_id));

    toastr.success(
      "Sukces",
      "Słowo kluczowe zostało usnięte"
    );
  } catch (error) {
    toastr.error(
      "Błąd",
      "Wystąpił błąd podzczas usuwania słowa kluczowego"
    );
    throw error;
  }
};
