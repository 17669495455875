import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { ReactComponent as PgIcon } from "icons/pg-square.svg";

const AsidePanel = ({ product_group, history }) => {
  return (
    <ul className="product-list">
      <li
        className="product-list__item active"
        onClick={() =>
          history.push(
            `/product_groups/${product_group.id}/specification/product_group`
          )
        }>
        <div className="item-icon">
          <PgIcon />
        </div>
        <div className="item-text">
          <p className="title">{product_group.name}</p>
          <p>{product_group.sku}</p>
        </div>
      </li>
    </ul>
  );
};

export default connect(
  ({ product_group: { data: product_group } }) => ({
    product_group,
  }),
  null
)(withRouter(AsidePanel));
