import { toastr } from "react-redux-toastr";
import { client } from "utils/api";
import { getProductGroup } from "actions/product_group";

export const assignPropertiesFromPrototype =
  ({ product_group_id, prototype_id }) =>
  (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        await client.post(
          `/product_groups/${product_group_id}/assign_properties_from_prototype`,
          {
            prototype_id,
          }
        );
        await dispatch(getProductGroup(product_group_id));
        resolve();
      } catch (error) {
        console.log(error);
        reject(error);
        toastr.error("Błąd", "Wystąpił błąd podzczas pobierania");
      }
    });

export const assignPropertiesFromProductGroup =
  ({ product_group_id, cloned_product_group_id }) =>
  async (dispatch) => {
    try {
      await client.post(
        `/product_groups/${product_group_id}/assign_properties_from_product_group`,
        {
          cloned_product_group_id,
        }
      );
      await dispatch(getProductGroup(product_group_id));
      toastr.success("Sukces", "Import przebiegł pomyślnie");
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd podzczas importowania");
      throw error;
    }
  };

export const addPropertySettingToProductGroup =
  (values) => (dispatch, getState) =>
    new Promise(async (resolve, reject) => {
      try {
        const {
          product_group: {
            data: { id: product_group_id },
          },
        } = getState();

        await client.post(`/property_settings`, values);
        await dispatch(getProductGroup(product_group_id));

        toastr.success("Sukces", "Właściwość została dodana");
        resolve();
      } catch (error) {
        reject(error);
        toastr.error("Błąd", "Wystąpił błąd podzczas dodawania właściwości");
      }
    });

export const updateProductGroupPropertySetting =
  (id, values) => (dispatch, getState) =>
    new Promise(async (resolve, reject) => {
      try {
        const {
          product_group: {
            data: { id: product_group_id },
          },
        } = getState();
        await client.put(`/property_settings/${id}`, values);
        await dispatch(getProductGroup(product_group_id));

        toastr.success("Sukces", "Właściwość została zaktualizowana");
        resolve();
      } catch (error) {
        reject(error);
        toastr.error("Błąd", "Wystąpił błąd podzczas aktualiozwania");
      }
    });

export const deleteProductGroupPropertySetting = (id) => (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        product_group: {
          data: { id: product_group_id },
        },
      } = getState();
      await client.delete(`/property_settings/${id}`);
      await dispatch(getProductGroup(product_group_id));

      toastr.success("Sukces", "Właściwość została usunięta");
      resolve();
    } catch (error) {
      reject(error);
      toastr.error("Błąd", "Wystąpił błąd podczas usuwania");
    }
  });
