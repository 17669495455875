import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { ModalDelete } from "expano-components";
import { useMainList } from "components/MainList/context/provider";
import { deleteProperty } from "actions/properties";

const ModalDeleteProperty = ({
  internal_name,
  id,
  handleClose,
}) => {
  const { refetch } = useMainList();
  const query = useQueryClient();
  const handleDelete = () =>
    deleteProperty(id)
      .then(async () => {
        refetch();
        query.refetchQueries(["properties"]);
        handleClose();
      })
      .catch((err) => console.log(err));

  return (
    <ModalDelete
      name={`właściwość ${internal_name}?`}
      handleClose={handleClose}
      handleDelete={handleDelete}
    />
  );
};

export default ModalDeleteProperty;
