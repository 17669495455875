import React, { useState } from "react";
import { connect } from "react-redux";

import {
  TextSection,
  ImageSection,
  ImageTextSection,
  TextImageSection,
  DefaultSection,
} from "./section_types";

import { deleteDescriptionSection } from "actions/product_group/description_sections";
import { ModalDelete } from "expano-components";

const Sections = ({
  data,
  type,
  disabled,
  inherited = null,
}) => {
  switch (type) {
    case "DescriptionSection::TextSection":
      return (
        <TextSection
          {...data}
          disabled={disabled}
          inherited={inherited}
        />
      );
    case "DescriptionSection::ImageSection":
      return (
        <ImageSection
          {...data}
          disabled={disabled}
          inherited={inherited}
        />
      );
    case "DescriptionSection::TextImageSection":
      return (
        <TextImageSection
          {...data}
          disabled={disabled}
          inherited={inherited}
        />
      );
    case "DescriptionSection::ImageTextSection":
      return (
        <ImageTextSection
          {...data}
          disabled={disabled}
          inherited={inherited}
        />
      );

    default:
      return <DefaultSection {...data} disabled={false} />;
  }
};

const SectionBox = ({
  deleteDescriptionSection,
  ...props
}) => {
  const [delete_modal, setDeleteModal] = useState(null);

  const handleDelete = (id, position) =>
    setDeleteModal(id, position);

  return (
    <>
      {delete_modal?.id && (
        <ModalDelete
          handleDelete={() =>
            deleteDescriptionSection(delete_modal.id)
          }
          handleClose={() => setDeleteModal(null)}
          name={`Sekcje ${delete_modal.position}?`}
        />
      )}
      <Sections
        data={{ ...props, handleDelete }}
        type={props.type}
        disabled={false}
      />
    </>
  );
};

export default connect(null, (dispatch) => ({
  deleteDescriptionSection: (id) =>
    dispatch(deleteDescriptionSection(id)),
}))(SectionBox);
