import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "expano-components";
import { Modal, AsyncSelect } from "components";

import { assignPropertiesFromProductGroup } from "actions/product_group/property_settings";
import getProductGroupOptions from "utils/select_options/getProductGroupOptions";

const ImportPropertiesModal = ({
  product_group_id,
  handleClose,
  assignPropertiesFromProductGroup,
}) => {
  const [is_importing, setImporting] = useState(false);
  const [cloned_product_group_id, setClonedProductGroupId] = useState(null);

  const handleImport = async () => {
    try {
      setImporting(true);
      await assignPropertiesFromProductGroup({
        product_group_id,
        cloned_product_group_id,
      });
      setImporting(false);
      handleClose();
    } catch (error) {
      setImporting(false);
    }
  };

  return (
    <Modal
      header={{ title: "Importuj właściwości" }}
      handleClose={handleClose}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="save"
            disabled={is_importing || !cloned_product_group_id}
            text={is_importing ? "Proszę czekać..." : "Importuj"}
            onClick={handleImport}
          />
        </>
      }
    >
      <AsyncSelect
        label="Wybierz produkt grupowy"
        onChange={({ value }) => setClonedProductGroupId(value)}
        getData={getProductGroupOptions}
      />
    </Modal>
  );
};

export default connect(
  ({ product_group }) => ({
    product_group_id: product_group.data?.id,
  }),
  (dispatch) => ({
    assignPropertiesFromProductGroup: (values) =>
      dispatch(assignPropertiesFromProductGroup(values)),
  })
)(ImportPropertiesModal);
