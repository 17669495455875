import React from "react";
import { Button } from "expano-components";
import { Header } from "../components";

const DefaultSection = ({
  id,
  position,
  type,
  handleDelete,
}) => {
  return (
    <div className="dashboard product_card_section">
      <Header
        id={id}
        attributes={{ position, type }}
      />
      <div
        className="dashboard__form__footer"
        style={{ borderTop: "none" }}>
        <div className="box">
          <Button
            type="delete"
            text="Usuń"
            onClick={() => handleDelete({ id, position })}
          />
        </div>
      </div>
    </div>
  );
};

export default DefaultSection;
