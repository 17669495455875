import { toastr } from "react-redux-toastr";
import { client } from "utils/api";
import { getChannelProductGroup } from "actions/product_group/channel_product_group";

export const setChanelProduct360ImagesOrder = (
  data
) => async (dispatch, getState) => {
  try {
    const {
      id: channel_product_group_id,
    } = getState().channel_product_group.data;

    await Promise.all(
      data.map(({ id, product_image_id }) =>
        client.put(`/channel_product360_images/${id}`, {
          product_image_id,
        })
      )
    );
    toastr.success("Sukces", "Zmiany zostały dodane");
    dispatch(
      getChannelProductGroup(channel_product_group_id)
    );
  } catch (error) {
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas przypisywania zdjęć"
    );
    throw error;
  }
};
