import React, { useState } from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { Button, ModalDelete } from "expano-components";
import { url } from "utils/api";
import { uploadProductImages } from "actions/product_group/product_images";
import { GalleryUpload } from "components";
import {
  updateDescriptionSectionProductGroupImage,
  updateDescriptionSectionProductImage,
} from "actions/product_group/description_sections";

const ImageBox = ({
  data,
  image,
  type,
  isMulti,
  id,
  updateDescriptionSectionProductImage,
  uploadProductGroupImage,
  handleSubmit,
}) => {
  const handleDelete = async () => {
    try {
      await updateDescriptionSectionProductImage(data.id, {
        product_image_id: null,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploadImage = (files) => {
    return new Promise((resolve, reject) => {
      try {
        let promises = [];
        switch (type) {
          case "pg":
            files.forEach((file) => {
              promises.push(
                uploadProductGroupImage(
                  createBody("product_group", file)
                )
              );
            });
            break;
          case "p":
            files.forEach((file) => {
              promises.push(
                uploadProductImages(
                  createBody("product", file)
                )
              );
            });
            break;
          default:
            break;
        }
        Promise.all(promises)
          .then(() =>
            toastr.success(
              "Sukces",
              "Zdjęcia zostały dodane"
            )
          )
          .catch((error) =>
            toastr.error("Błąd", JSON.stringify(error))
          );
      } catch (error) {
        toastr.error(
          "Błąd",
          "Wystąpił błąd podczas wgrywania zdjęcia"
        );
        reject();
      }
    });
  };

  const createBody = (type, file) => {
    let body = new FormData();
    body.append(`image`, file);
    body.append(`${type}_id`, id);
    return body;
  };

  const [
    show_delete_modal,
    handleOpenDeleteModal,
  ] = useState(false);
  const [
    show_gallery_modal,
    handleOpenGalleryModal,
  ] = useState(false);

  return (
    <>
      {show_delete_modal && (
        <ModalDelete
          handleDelete={handleDelete}
          handleClose={() => handleOpenDeleteModal(false)}
          name={`zdjęcie`}
        />
      )}
      {show_gallery_modal && (
        <GalleryUpload
          headerText="Prześlij nowe"
          type={type}
          id={id}
          data={data}
          handleUpload={handleUploadImage}
          handleClose={() => handleOpenGalleryModal(false)}
          limit={1}
          isMulti={isMulti}
          handleSubmit={handleSubmit}
        />
      )}
      <div
        className="grid-item single-image"
        style={{
          backgroundImage: `url(${url}${image})`,
        }}>
        <div className="overlay">
          <Button
            type="edit"
            className="edit"
            onClick={() => handleOpenGalleryModal(true)}
          />
          <Button
            type="delete-bin"
            className="remove"
            onClick={() => handleOpenDeleteModal(true)}
          />
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateDescriptionSectionProductGroupImage: (id, data) =>
    dispatch(
      updateDescriptionSectionProductGroupImage(id, data)
    ),
  updateDescriptionSectionProductImage: (id, data) =>
    dispatch(
      updateDescriptionSectionProductImage(id, data)
    ),
});

export default connect(null, mapDispatchToProps)(ImageBox);
