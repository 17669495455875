import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Select,
  Button,
  ModalDelete,
} from "expano-components";

import {
  updateProductGroupPropertySetting,
  deleteProductGroupPropertySetting,
} from "actions/product_group/property_settings";

const kind_options = [
  { value: "product_group", label: "Globalna" },
  { value: "product", label: "Różnicująca" },
];

const PropertySettingLine = ({
  updateProductGroupPropertySetting,
  deleteProductGroupPropertySetting,
  property,
  id,
  kind,
  is_important,
}) => {
  const [
    is_open_delete_modal,
    handleOpenDeleteModal,
  ] = useState(false);

  return (
    <>
      {is_open_delete_modal && (
        <ModalDelete
          name={`właściwość ${property.internal_name}?`}
          handleClose={() => handleOpenDeleteModal(false)}
          handleDelete={() =>
            deleteProductGroupPropertySetting(id)
          }
        />
      )}
      <tr>
        <td className="box-name">
          {property.internal_name}
        </td>
        <td>
          <Select
            defaultValue={kind_options.find(
              ({ value }) => value === kind
            )}
            options={kind_options}
            handleChange={(kind) =>
              updateProductGroupPropertySetting(id, {
                kind,
              })
            }
          />
        </td>
        <td>
          {kind === "product" && (
            <div className="check-group">
              <input
                type="checkbox"
                name={`is_important_${id}`}
                id={`is_important_${id}`}
                checked={is_important}
                onChange={({ target: { checked } }) =>
                  updateProductGroupPropertySetting(id, {
                    is_important: checked,
                  })
                }
              />
              <label
                className="label-form"
                htmlFor={`is_important_${id}`}>
                Kluczowa
              </label>
            </div>
          )}
        </td>
        <td className="box-delete">
          <Button
            type="delete-bin"
            onClick={() => handleOpenDeleteModal(true)}
          />
        </td>
      </tr>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  deleteProductGroupPropertySetting: (id) =>
    dispatch(deleteProductGroupPropertySetting(id)),
  updateProductGroupPropertySetting: (id, data) =>
    dispatch(updateProductGroupPropertySetting(id, data)),
});

export default connect(
  ({ product_group }) => ({
    product_group,
  }),
  mapDispatchToProps
)(PropertySettingLine);
