import React from "react";

import { ReactComponent as InfoIcon } from "icons/info.svg";
import { ReactComponent as ChannelIcon } from "icons/channel.svg";
import { ReactComponent as ShopIcon } from "icons/shop.svg";
import { ReactComponent as PlatformIcon } from "icons/platform.svg";
import { ReactComponent as PropertyIcon } from "icons/property.svg";

const getIcon = (icon) => {
  switch (icon) {
    case "info":
      return <InfoIcon />;
    case "channel":
      return <ChannelIcon />;
    case "shop":
      return <ShopIcon />;
    case "platform":
      return <PlatformIcon />;
    case "property":
      return <PropertyIcon />;
    default:
      return null;
  }
};

const Dashboard = ({ header, children, footer }) => {
  const icon = getIcon(header?.icon);
  return (
    <div className="dashboard">
      {header?.title && (
        <header className="dashboard__header">
          <div className="icon__container">{icon}</div>
          <h2 className="dashboard__title heading">
            {header.title}
          </h2>
        </header>
      )}
      {children}
      {footer && (
        <div className="dashboard__footer">{footer}</div>
      )}
    </div>
  );
};

export default Dashboard;
