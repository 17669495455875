import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

import ProductGroupSections from "./components/ProductGroupSections";
import ProductGroupMockup from "./components/ProductGroupMockup";
import Aside from "./components/Aside";

const ProductGroupCards = ({
  history,
  match: {
    params: { product_group_id, type },
  },
}) => {
  useEffect(() => {
    if (!type) {
      history.push(`/product_groups/${product_group_id}/product_cards/mockup`);
    }
  }, []);

  return (
    <div className="main-wrapper">
      <Aside />
      <div className="box-full">
        {type === "mockup" && <ProductGroupMockup />}
        {type === "channel_product_groups" && <ProductGroupSections />}
      </div>
    </div>
  );
};

export default withRouter(ProductGroupCards);
