export const sort = [
  {
    label: "ID",
    name: "id",
    style: { width: 130 },
  },
  { label: "Nazwa", name: "internal_name" },
];

export const filters = [
  {
    type: "input",
    name: "internal_name",
    sort_id: "internal_name",
    search_type: "matches",
  },
];
