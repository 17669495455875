const initialState = {
  status: "invalid",
  data: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "PLATFORM_TAXON_LOADING":
      return {
        status: "loading",
      };
    case "PLATFORM_TAXON_SUCCESS":
      return {
        ...state,
        status: "success",
        data: action.data,
      };
    case "PLATFORM_TAXON_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    default:
      return state;
  }
};
