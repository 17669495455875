import React, { useState } from "react";
import Select from "react-select";

const AsyncSelectDelayData = ({
  isDisabled,
  getData,
  label,
  meta,
  onChange,
  initValue,
}) => {
  const [options, setOptions] = useState([]);
  const [is_loading, setLoading] = useState(false);

  const isError = meta && meta.touched && meta.invalid;
  const customStyles = {
    control: (styles) => ({
      ...styles,
      borderColor: isError ? "#ff0000" : "#E2E5EC",
      minHeight: 30,
    }),
    option: (provided) => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0 10px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
      padding: 0,
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
    }),
  };
  return (
    <div className={`form-group ${isError ? "error" : ""}`}>
      <div className="inp-wrapper">
        {label && <label>{label}</label>}
        <Select
          onFocus={async () => {
            if (options.length < 1) {
              try {
                setLoading(true);
                const data = await getData();
                setOptions(data);
              } catch (error) {
              } finally {
                setLoading(false);
              }
            }
          }}
          onChange={onChange}
          styles={customStyles}
          isDisabled={isDisabled}
          isLoading={is_loading}
          options={options}
          placeholder={"Wybierz..."}
          theme={(theme) => ({
            ...theme,
            fontFamily: "Poppins",
            borderRadius: 4,
            colors: {
              ...theme.colors,
              neutral20: "#E2E5EC",
            },
            error: meta && meta.touched && meta.invalid,
          })}
          defaultValue={initValue}
        />
        {isError && meta.error && (
          <p className="inp-error">{meta.error}</p>
        )}
      </div>
    </div>
  );
};

export default AsyncSelectDelayData;
