import React from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Modal, AsyncSelect } from "components";
import { Button } from "expano-components";
import { createPropertyPrototype } from "actions/prototypes";
import getPropertiesOptions from "utils/select_options/getPropertiesOptions";
import { ReactComponent as PropertyIcon } from "icons/property.svg";

const AddPropertyPrototype = ({
  handleClose,
  handleSubmit,
  change,
  pristine,
  prototype_id,
  createPropertyPrototype,
}) => {
  const onSubmit = async (values) => {
    try {
      await createPropertyPrototype({
        ...values,
        prototype_id,
      });
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal
      handleClose={handleClose}
      header={{
        icon: <PropertyIcon />,
        title: "Dodaj właściwość",
      }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="add-green"
            text="Dodaj"
            disabled={pristine}
            onClick={handleSubmit(onSubmit)}
          />
        </>
      }>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <AsyncSelect
          label="Właściwość"
          getData={getPropertiesOptions}
          onChange={({ value }) =>
            change("property_id", value)
          }
        />
      </form>
    </Modal>
  );
};

export default connect(null, (dispatch) => ({
  createPropertyPrototype: (data) =>
    dispatch(createPropertyPrototype(data)),
}))(
  reduxForm({ form: "add-property-prototype" })(
    AddPropertyPrototype
  )
);
