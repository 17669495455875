import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, ModalDelete } from "expano-components";

import { deleteProductGroupCountryKeyword } from "actions/product_group/country_keywords";
import EditKeywordModal from "./EditKeywordModal";

const Item = ({
  deleteProductGroupCountryKeyword,
  id,
  name,
  volumen,
  is_title,
  is_used,
  product_group_id,
}) => {
  const [
    is_open_delete_modal,
    handleOpenDeleteModal,
  ] = useState(false);
  const [
    is_open_edit_modal,
    handleOpenEditModal,
  ] = useState(false);

  return (
    <>
      {is_open_delete_modal && (
        <ModalDelete
          name={`słowo kluczowe ${name}?`}
          handleClose={() => handleOpenDeleteModal(false)}
          handleDelete={() =>
            deleteProductGroupCountryKeyword({
              id,
              product_group_id,
            })
          }
        />
      )}
      {is_open_edit_modal && (
        <EditKeywordModal
          id={id}
          product_group_id={product_group_id}
          initialValues={{
            name,
            volumen,
            is_title,
            is_used,
          }}
          handleClose={() => handleOpenEditModal(false)}
        />
      )}
      <tr
        style={
          is_title
            ? { backgroundColor: "rgba(10, 190, 140,0.1)" }
            : is_used
            ? { backgroundColor: "rgba(242, 174, 73, 0.2)" }
            : {}
        }>
        <td className="box-name">{name}</td>
        <td className="box-name">
          {volumen ? parseInt(volumen) : "-"}
        </td>
        <td className="box-delete">
          <Button
            type="edit"
            onClick={() => handleOpenEditModal(true)}
          />
          <Button
            type="delete-bin"
            onClick={() => handleOpenDeleteModal(true)}
          />
        </td>
      </tr>
    </>
  );
};

export default connect(null, (dispatch) => ({
  deleteProductGroupCountryKeyword: (data) =>
    dispatch(deleteProductGroupCountryKeyword(data)),
}))(Item);
