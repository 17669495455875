import React, { useState } from "react";
import { Button } from "expano-components";
import { updateChannelProduct } from "actions/product_group/channel_product";
import { Modal } from "components";
import sales_status_options from "../const/sales_status_options";

const SalesStatusModal = ({
  id,
  product,
  sales_status,
  handleClose,
  refetch,
}) => {
  const [is_loading, setLoading] = useState(false);
  const handleChangeStatus = async (id, sales_status) => {
    try {
      setLoading(true);
      await updateChannelProduct(id, {
        sales_status,
      });
      await refetch();
      setLoading(false);

      handleClose();
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <Modal
      header={{ title: "Uwaga" }}
      handleClose={handleClose}
      footer={
        <>
          <Button
            type="cancel"
            text="Nie"
            onClick={handleClose}
          />
          <button
            disabled={is_loading}
            className="btn-sm btn-green"
            style={{ minWidth: 100 }}
            onClick={() =>
              handleChangeStatus(id, sales_status)
            }>
            <span>
              {is_loading ? "Proszę czekać..." : "Tak"}
            </span>
          </button>
        </>
      }>
      <div className="popup-text" style={{ padding: 0 }}>
        <p className="text-normal">
          Zmieniasz status sprzedaży produktu
        </p>
        <p className="text-normal text-light">
          {product?.name}
        </p>
        <p className="text-normal text-light">
          {product?.sku}
        </p>
        <p className="text-normal">
          na{" "}
          <strong>
            {
              sales_status_options.find(
                ({ value }) => value === sales_status
              )?.label
            }
          </strong>
        </p>
      </div>
    </Modal>
  );
};

export default SalesStatusModal;
