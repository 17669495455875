import React from "react";
import { PanelLoader } from "components";
import { Single, Multi } from "./components";
import useQueryHook from "utils/useQueryHook";
import { stringifyQuery } from "utils/api";
const query = {
  per_page: 100,
  context: "ui_product_group",
};

const GalleryGrid = ({
  handleClose,
  id,
  isMulti,
  handleSubmit,
}) => {
  const {
    is_loading,
    is_fetching,
    data: { data: images },
  } = useQueryHook({
    query_keys: [`product_images_${id}`],
    path: `/product_images?${stringifyQuery({
      ...query,
      "q[product_id_eq]": id,
    })}`,
  });

  if (is_loading || is_fetching) {
    return (
      <div className="popup__body" style={{ padding: 40 }}>
        <PanelLoader />
      </div>
    );
  }

  return (
    <>
      {isMulti && images?.length > 0 ? (
        <Multi
          images={images}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
        />
      ) : (
        <Single
          images={images}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

export default GalleryGrid;
