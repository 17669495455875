import React from "react";
import { withRouter } from "react-router-dom";
import { ReactComponent as PriceLabelIcon } from "icons/price-label.svg";
import ProductsPricesForm from "./components/ProductsPricesForm";

const ProductGroupPricesPanel = ({
  match: {
    params: { type_id },
  },
}) => {
  if (type_id) {
    return null;
  }

  // const hide_prices =
  //   !product.purchase_price_netto ||
  //   parseFloat(product?.purchase_price_netto) === 0;

  return (
    <div className="dashboard box-full">
      <header className="dashboard__header dashboard__header--edit">
        <div className="icon__container">
          <PriceLabelIcon />
        </div>
        <h2 className="dashboard__title heading">Ceny produktów</h2>
      </header>
      <ProductsPricesForm />
    </div>
  );
};

export default withRouter(ProductGroupPricesPanel);
