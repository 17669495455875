import React from "react";
import { Button, Textarea } from "expano-components";
import { Field, reduxForm } from "redux-form";
import { PanelLoader, Modal } from "components";
import {
  deleteDescriptionSectionComment,
  addDescriptionSectionComment,
} from "actions/product_group/description_section_comments";

import useQueryHook from "utils/useQueryHook";
import { stringifyQuery } from "utils/api";

import { ReactComponent as CommentIcon } from "icons/comment.svg";

const query = {
  context: "ui_show",
  per_page: 100,
};

const TextAreaField = (props) => (
  <Textarea
    {...props}
    input={{
      ...props.input,
      placeholder: "Uwaga do opisu sekcji ...",
    }}
  />
);

const CommentsModal = ({
  id,
  handleClose,
  handleSubmit,
  content,
  submitting,
  pristine,
  position,
}) => {
  const {
    is_loading,
    data: { data },
    refetch,
  } = useQueryHook({
    query_keys: [`description_section_comments_${id}`],
    path: `/description_section_comments?${stringifyQuery({
      ...query,
      "q[description_section_id_eq]": id,
    })}`,
  });

  const onSubmit = async ({ content }, fn, { reset }) => {
    await addDescriptionSectionComment({
      description_section_id: id,
      content,
    });
    refetch();
    reset();
  };

  const handleDelete = async (id) => {
    await deleteDescriptionSectionComment(id);
    refetch();
  };

  return (
    <Modal
      size="lg"
      handleClose={handleClose}
      header={{
        title: "Uwagi",
        icon: <CommentIcon />,
      }}>
      <div className="popup-comments__wrapper">
        <div className="popup-comments__list">
          {is_loading ? (
            <PanelLoader />
          ) : data.length > 0 ? (
            <ul>
              {data.map(({ id, content }) => (
                <li
                  className="popup-comments__list__item"
                  key={id}>
                  <p>{content}</p>
                  <Button
                    type="delete-bin"
                    onClick={() => handleDelete(id)}
                  />
                </li>
              ))}
            </ul>
          ) : null}

          <Field component={TextAreaField} name="content" />
          <div className="popup-comments__list__footer">
            <Button type="cancel" onClick={handleClose} />
            <Button
              type="add-green"
              disabled={submitting || pristine}
              text="Dodaj"
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
        <div className="popup-comments__content">
          <div className="popup-comments__content__header">
            <CommentIcon />
            <p>Sekcja {position}</p>
          </div>
          <div
            className="popup-comments__content__text"
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default reduxForm({
  form: "description-section-comments-modal",
  enableReinitialize: true,
})(CommentsModal);
