import { toastr } from "react-redux-toastr";
import { client } from "utils/api";

export const getAllegroChannelSettings = (id) => (
  dispatch
) => {
  dispatch({ type: "SETTING_LOADING" });
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.get(
        `/allegro/channels/${id}?context=ui_show`
      );
      dispatch({
        type: "SETTING_SUCCESS",
        data,
      });
      resolve();
    } catch (error) {
      dispatch({ type: "SETTING_FAILURE" });
      toastr.error(
        "Błąd",
        "Wystąpił błąd podczas pobierania"
      );
      reject();
    }
  });
};

export const updateAllegroChannelSettings = (
  id,
  values
) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      await client.put(`/allegro/channels/${id}`, values);
      const { data } = await client.get(
        `/allegro/channels/${id}?context=ui_show`
      );
      
      dispatch({
        type: "SETTING_SUCCESS",
        data,
      });
      
      toastr.success(
        "Sukces",
        "Ustawienia kanału zostały zaktualizowane"
      );
      resolve();
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd podczas edycji");
      reject();
    }
  });
};

export const resetChannelSettings = () => async (
  dispatch
) => {
  dispatch({ type: "SETTING_RESET" });
};
