import React, { useState } from "react";
import { Select, Textarea } from "expano-components";
import {
  Field,
  reduxForm,
  formValueSelector,
} from "redux-form";
import { PanelLoader, Modal } from "components";
import {
  getPrompt,
  createAiDescription,
} from "actions/product_group/description_sections";

import { ReactComponent as CommentIcon } from "icons/comment.svg";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";

const mode_options = [
  { label: "Intro", value: "intro" },
  { label: "Bulletpoints", value: "bulletpoints" },
];

const TextAreaField = (props) => (
  <Textarea
    {...props}
    input={{
      ...props.input,
      placeholder: "Prompt",
      rows: 7,
    }}
  />
);
const SelectField = (props) => <Select {...props} />;

const AiModal = ({
  id,
  handleClose,
  prompt,
  handleSubmit,
  change,
  createAiDescription,
}) => {
  const [is_getting_prompt, setGettingPrompt] = useState(
    false
  );

  const [
    is_getting_description,
    setGettingDescription,
  ] = useState(false);

  const handleGetPrompt = async (value) => {
    try {
      setGettingPrompt(true);
      const ai_prompt = await getPrompt(id, value);
      change("prompt", ai_prompt);
      setGettingPrompt(false);
    } catch (error) {
      setGettingPrompt(false);
    }
  };

  const onSubmit = async ({ prompt, mode }) => {
    try {
      setGettingDescription(true);
      await createAiDescription({ id, mode, prompt });
      handleClose();
      setGettingDescription(false);
    } catch (error) {
      setGettingDescription(false);
    }
  };

  return (
    <Modal
      size="xl"
      handleClose={handleClose}
      header={{
        title: "Opis AI",
        icon: <CommentIcon />,
      }}
    >
      {is_getting_description ? (
        <PanelLoader />
      ) : (
        <>
          <div className="row v-end m-b-15">
            <div className="box-6">
              <Field
                name="mode"
                component={SelectField}
                label="Mode"
                options={mode_options}
                handleChange={(value) => {
                  change("mode", value);
                  handleGetPrompt(value);
                }}
              />
            </div>
            {is_getting_prompt && (
              <div className="box-6">
                <Loader
                  type="Watch"
                  color="#36bcff"
                  height={30}
                  width={30}
                />
              </div>
            )}
          </div>
          <Field component={TextAreaField} name="prompt" />
          <div className="row m-t-15 h-end no-space">
            <button
              type="button"
              disabled={!prompt || is_getting_description}
              onClick={handleSubmit(onSubmit)}
              className="btn btn-sm btn-green"
            >
              <span>
                {is_getting_description
                  ? "Trwa generowanie..."
                  : "Generuj"}
              </span>
            </button>
          </div>
        </>
      )}
    </Modal>
  );
};

const form_name = "description-section-ai-modal";
const selector = formValueSelector(form_name);

export default connect(
  (state) => ({
    initialValues: {
      prompt: "",
      mode: null,
    },
    prompt: selector(state, "prompt"),
  }),
  (dispatch) => ({
    createAiDescription: (data) =>
      dispatch(createAiDescription(data)),
  })
)(
  reduxForm({
    form: form_name,
    enableReinitialize: true,
  })(AiModal)
);
