import React from "react";
import { connect } from "react-redux";
import { Button } from "expano-components";
import { reset, reduxForm } from "redux-form";
import { AsyncSelectDelayData } from "components";
import { updateProductProperties } from "actions/product_group/product";
import getPropertyOptionOptions from "utils/select_options/getPropertyOptionOptions";

const form_name = "product-properties";

const validate = (values) => {
  let errors = {};
  Object.keys(values).forEach((value) => {
    if (!values[value]?.kind) {
      errors = {
        ...errors,
        [value]: { kind: "Pole wymagane" },
      };
    }
  });

  return errors;
};

const ProductPropertiesForm = ({
  data,
  change,
  valid,
  pristine,
  submitting,
  handleSubmit,
  updateProductProperties,
}) => {
  const onSubmit = async (values, dispatch) => {
    try {
      await updateProductProperties(
        Object.keys(values).map((key) => ({
          id: values[key].id,
          property_option_id: values[key].value,
        }))
      );
      dispatch(reset(form_name));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <table>
        <thead>
          <tr>
            <th>Nazwa właściwości</th>
            <th>Wartość właściwości</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {data.map(({ id, property, property_option }) => (
            <tr key={id}>
              <td>{property.internal_name}</td>
              <td>
                <AsyncSelectDelayData
                  onChange={({ value }) =>
                    change(`product_property_${id}`, {
                      value,
                      id,
                    })
                  }
                  getData={() =>
                    getPropertyOptionOptions(property.id)
                  }
                  initValue={
                    property_option?.id
                      ? {
                          label:
                            property_option.value?.pl ||
                            "-",
                          value: property_option.id,
                        }
                      : null
                  }
                />
              </td>
              <td />
              <td />
            </tr>
          ))}
        </tbody>
      </table>
      <div className="create-table__footer">
        <Button
          type="save"
          text="Zapisz"
          disabled={pristine || !valid || submitting}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </form>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateProductProperties: (id, property_option_id) =>
    dispatch(
      updateProductProperties(id, property_option_id)
    ),
});

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm({
    form: form_name,
    validate,
    enableReinitialize: true,
  })(ProductPropertiesForm)
);
