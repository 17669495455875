import React from "react";
import { Button, Input } from "expano-components";
import { Field, reduxForm } from "redux-form";
import { updateProduct } from "actions/product_group/product";
import { connect } from "react-redux";

const InputField = (props) => (
  <Input {...props} input={{ ...props.input, step: "0.01" }} />
);

const getMarkupPercent = (markup) => {
  if (!markup) {
    return "-";
  }

  return [parseFloat(markup * 100).toFixed(2), "%"].join("");
};

const ProductPricesForm = ({
  current_user,
  product,
  pristine,
  submitting,
  handleSubmit,
  updateProduct,
  refetch,
}) => {
  const onSubmit = async (values) => {
    await updateProduct(product.id, values);
    refetch();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row p-20">
        <div className="box-2">
          <Field
            postfix="PLN"
            type="number"
            name="sales_price_pln"
            component={InputField}
          />
          {current_user.permission_display_purchase_price && (
            <p className="text-muted">
              Narzut: {getMarkupPercent(product?.sales_markup_pln)}
            </p>
          )}
        </div>
        <div className="box-2">
          <Field
            postfix="EUR"
            type="number"
            name="sales_price_eur"
            component={InputField}
          />
          {current_user.permission_display_purchase_price && (
            <p className="text-muted">
              Narzut: {getMarkupPercent(product?.sales_markup_eur)}
            </p>
          )}
        </div>
        <div className="box-2">
          <Field
            postfix="CZK"
            type="number"
            name="sales_price_czk"
            component={InputField}
          />
          {current_user.permission_display_purchase_price && (
            <p className="text-muted">
              Narzut: {getMarkupPercent(product?.sales_markup_czk)}
            </p>
          )}
        </div>
        <div className="box-2">
          <Field
            postfix="SEK"
            type="number"
            name="sales_price_sek"
            component={InputField}
          />
          {current_user.permission_display_purchase_price && (
            <p className="text-muted">
              Narzut: {getMarkupPercent(product?.sales_markup_sek)}
            </p>
          )}
        </div>
        <div className="box-2">
          <Field
            postfix="GBP"
            type="number"
            name="sales_price_gbp"
            component={InputField}
          />
          {current_user.permission_display_purchase_price && (
            <p className="text-muted">
              Narzut: {getMarkupPercent(product?.sales_markup_gbp)}
            </p>
          )}
        </div>
        <div className="box-2">
          <Field
            postfix="HUF"
            type="number"
            name="sales_price_huf"
            component={InputField}
          />
          {current_user.permission_display_purchase_price && (
            <p className="text-muted">
              Narzut: {getMarkupPercent(product?.sales_markup_huf)}
            </p>
          )}
        </div>
      </div>
      <div className="dashboard__form__footer" style={{ borderTop: "none" }}>
        <Button
          type="save"
          disabled={pristine || submitting}
          text="Zapisz"
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </form>
  );
};

export default connect(
  ({ current_user }) => ({ current_user }),
  (dispatch) => ({
    updateProduct: (id, values) => dispatch(updateProduct(id, values)),
  })
)(
  reduxForm({
    form: "product_price_form",
    enableReinitialize: true,
  })(ProductPricesForm)
);
