import { toastr } from "react-redux-toastr";
import { client } from "utils/api";

export const productGroupExport = async (id) => {
  try {
    const { message } = await client.post(
      `/channel_product_groups/${id}/export_product_group`
    );
    toastr.success("Sukces", message);
  } catch (error) {
    toastr.error(
      "Błąd",
      error?.response?.data?.error ||
        "Eksport się nie powiódł"
    );
  }
};

export const productExport = async (id) => {
  try {
    const { message } = await client.post(
      `/channel_products/${id}/export_product`
    );
    toastr.success("Sukces", message);
  } catch (error) {
    toastr.error(
      "Błąd",
      error?.response?.data?.error ||
        "Eksport sie nie powiódł"
    );
  }
};
