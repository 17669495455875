import { toastr } from "react-redux-toastr";
import { client, handleErrors } from "utils/api";

export const getPrototype = (id) => async (
  dispatch,
  getState
) => {
  try {
    const {
      prototype: { status },
    } = getState();

    if (status === "invalid") {
      dispatch({ type: "PROTOTYPE_LOADING" });
    }

    const { data } = await client.get(`/prototypes/${id}?context=ui_show`);
    dispatch({
      type: "PROTOTYPE_SUCCESS",
      data,
    });
  } catch (error) {
    dispatch({ type: "PROTOTYPE_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podzczas pobierania"
    );
  }
};

export const createPrototype = (values) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        data: { id },
      } = await client.post(`/prototypes`, values);
      toastr.success("Sukces", "Dodano prototyp");

      resolve(id);
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd");

      reject(handleErrors(error.response.data.errors));
    }
  });

export const updatePrototype = (id, values) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      await client.put(`/prototypes/${id}`, values);

      await dispatch(getPrototype(id));
      toastr.success(
        "Sukces",
        "Prototyp został zaktualizowany"
      );
      resolve(id);
    } catch (err) {
      console.log(err);
      toastr.error("Błąd", "Wystąpił błąd");
      reject(handleErrors(err.response.data.errors));
    }
  });

export const deletePrototype = (id) =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/prototypes/${id}`);
      toastr.success("Sukces", "Usunięto prototyp");
      resolve(id);
    } catch (error) {
      toastr.error(
        "Błąd",
        "Wystąpił błąd podzczas usuwania"
      );
      reject(handleErrors(error?.response?.data?.errors));
    }
  });

export const resetPrototypeEdit = () => (dispatch) =>
  dispatch({ type: "PROTOTYPE_RESET" });

export const createPropertyPrototype = (values) => (
  dispatch,
  getState
) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        prototype: {
          data: { id: prototype_id },
        },
      } = getState();
      await client.post("/property_prototypes", values);
      await dispatch(getPrototype(prototype_id));
      toastr.success(
        "Sukces",
        "Właściwość prototypu została dodana"
      );
      resolve();
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd");
      reject(handleErrors(error.response.data.errors));
    }
  });

export const deletePropertyPrototype = (id) => (
  dispatch,
  getState
) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        prototype: {
          data: { id: prototype_id },
        },
      } = getState();
      await client.delete(`/property_prototypes/${id}`);
      await dispatch(getPrototype(prototype_id));

      toastr.success(
        "Sukces",
        "Usunięto właściwość prototypu"
      );
      resolve();
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd");
      reject(handleErrors(error?.response?.data?.errors));
    }
  });
