import React, { useState, useEffect } from "react";
import {
  Field,
  reduxForm,
  SubmissionError,
  reset,
} from "redux-form";
import { connect } from "react-redux";
import {
  Button,
  ModalDelete,
  Input,
} from "expano-components";

import { Main, PageLoader, Dashboard } from "components";
import {
  getPrototype,
  updatePrototype,
  deletePrototype,
  resetPrototypeEdit,
  deletePropertyPrototype,
} from "actions/prototypes";

import { ReactComponent as PlatformIcon } from "icons/platform.svg";

import AddPropertyPrototype from "./modals/AddPropertyPrototype";

const InputField = (props) => <Input {...props} />;

const PrototypeEdit = ({
  match: {
    params: { id },
  },
  history,
  submitting,
  handleSubmit,
  dispatch,
  pristine,
  prototype,
  initialValues,
  getPrototype,
  updatePrototype,
  deletePropertyPrototype,
}) => {
  const [show_delete_modal, setShowDeleteModal] = useState(
    false
  );
  const [
    show_add_property_modal,
    setShowAddPropertyModal,
  ] = useState(false);

  const [
    property_prototype_to_delete,
    setPropertyPrototypeToDelete,
  ] = useState({ id: null, name: null });

  useEffect(() => {
    getPrototype(id);
    return () => {
      resetPrototypeEdit();
      dispatch(reset("edit-prototype"));
    };
  }, [id]);

  const onSubmit = ({ name }) =>
    updatePrototype(id, {
      name,
    }).catch((err) => {
      throw new SubmissionError(err);
    });

  const handleDelete = async () => {
    await deletePrototype(id);
    setShowDeleteModal(false);
    history.push("/prototypes");
  };

  const handleDeletePropertyPrototype = async () => {
    await deletePropertyPrototype(
      property_prototype_to_delete.id
    );
    setPropertyPrototypeToDelete({
      id: null,
      name: null,
    });
  };

  if (
    prototype.status === "invalid" ||
    prototype.status === "loading"
  )
    return <PageLoader />;
  if (prototype.status === "failure") {
    return <p>Error</p>;
  }
  const { name, property_prototypes } = prototype.data;
  return (
    <Main
      page={{
        name,
        icon: <PlatformIcon />,
        breadcrumbs: [
          {
            name: "Prototypy",
            path: "/prototypes",
          },
          { name },
        ],
        buttons: (
          <Button
            type="delete"
            text="Usuń prototyp"
            onClick={() => setShowDeleteModal(true)}
          />
        ),
      }}>
      {show_delete_modal && (
        <ModalDelete
          handleDelete={handleDelete}
          handleClose={() => setShowDeleteModal(false)}
          name={`prototyp ${initialValues.name}?`}
        />
      )}
      {property_prototype_to_delete?.id && (
        <ModalDelete
          handleDelete={handleDeletePropertyPrototype}
          handleClose={() =>
            setPropertyPrototypeToDelete({
              id: null,
              name: null,
            })
          }
          name={`właściwość prototypu ${property_prototype_to_delete.name}?`}
        />
      )}
      {show_add_property_modal && (
        <AddPropertyPrototype
          prototype_id={id}
          handleClose={() => setShowAddPropertyModal(false)}
        />
      )}
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <Dashboard
            header={{
              icon: "info",
              title: "Podstawowe dane",
            }}
            footer={
              <Button
                type="save"
                text="Zapisz"
                disabled={pristine || submitting}
                onClick={handleSubmit(onSubmit)}
              />
            }>
            <form className="dashboard__form">
              <Field
                name="name"
                label="Nazwa"
                component={InputField}
                type="text"
              />
            </form>
          </Dashboard>
        </div>
        <div className="column-half">
          <Dashboard
            header={{
              icon: "property",
              title: "Właściwości",
            }}
            footer={
              <Button
                type="add"
                text="Dodaj właściwość"
                onClick={() =>
                  setShowAddPropertyModal(true)
                }
              />
            }>
            {property_prototypes?.length > 0 && (
              <table className="table">
                <thead className="table__header table-head">
                  <tr>
                    <th>Właściwość</th>
                    <th>Działania</th>
                  </tr>
                </thead>
                <tbody className="table__body table-body">
                  {property_prototypes.map(
                    ({ id, property }) => (
                      <tr key={id}>
                        <td>{property?.internal_name}</td>
                        <td>
                          <Button
                            type="delete-bin"
                            onClick={() =>
                              setPropertyPrototypeToDelete({
                                id,
                                name:
                                  property?.internal_name,
                              })
                            }
                          />
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            )}
          </Dashboard>
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = ({ prototype }) => ({
  prototype,
  initialValues: { name: prototype?.data?.name },
});

const mapDispatchToProps = (dispatch) => ({
  resetPrototypeEdit: () => dispatch(resetPrototypeEdit()),
  getPrototype: (id) => dispatch(getPrototype(id)),
  updatePrototype: (id, data) =>
    dispatch(updatePrototype(id, data)),
  deletePropertyPrototype: (id) =>
    dispatch(deletePropertyPrototype(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "edit-prototype",
    enableReinitialize: true,
  })(PrototypeEdit)
);
