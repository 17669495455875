import { toastr } from "react-redux-toastr";
import { client } from "utils/api";

export const getPlatforms = () => (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    try {
      const { platforms } = getState();
      if (platforms.status === "success") {
        resolve(platforms.data);
        return;
      }
      dispatch({ type: "PLATFORMS_LOADING" });
      const { data } = await client.get(
        "/platforms?context=ui_index&per_page=100"
      );
      dispatch({
        type: "PLATFORMS_SUCCESS",
        data,
      });
      resolve(data);
    } catch (error) {
      dispatch({ type: "PLATFORMS_FAILURE" });
      toastr.error(
        "Błąd",
        "Wystąpił błąd podczas pobierania"
      );
      reject(error);
    }
  });
