import React from "react";
import { Button } from "expano-components";
import { Images, Header } from "../components";

const ImageSection = ({
  id,
  handleDelete,
  description_section_products,
  position,
  disabled,
  inherited,
  type,
}) => {
  const images_data = {
    description_section_products: description_section_products?.filter(
      ({ product: { status } }) => status !== "discontinued"
    ),
  };

  return (
    <div
      className={`dashboard product_card_section ${
        disabled ? "disabled" : ""
      }`}>
      <Header
        id={id}
        attributes={{ position, type }}
        inherited={inherited}
      />
      <div className="product_card_section__content product_card_section__image">
        <div className="dashboard__form">
          <Images {...images_data} />
        </div>
        <div className="dashboard__form__footer">
          <div className="box">
            <Button
              type="delete"
              text="Usuń"
              onClick={() => handleDelete({ id, position })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageSection;
