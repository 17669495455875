import React from "react";
import CreatableSelect from "react-select/creatable";

const Select = ({ label, placeholder = "", onChange, defaultValue = [] }) => {
  return (
    <div className='form-group'>
      <div className='inp-wrapper'>
        {label && <label>{label}</label>}
        <CreatableSelect
          components={{ DropdownIndicator: null }}
          isMulti
          onChange={value => {
            let data = "";
            if (value?.length > 0) {
              data = value.map(({ value }) => value).join(",");
            }
            return onChange(data);
          }}
          placeholder={placeholder}
          styles={customStyles}
          defaultValue={defaultValue}
        />
      </div>
    </div>
  );
};

export default Select;

const customStyles = {
  control: styles => ({
    ...styles,
    borderColor: "#E2E5EC",
  }),
  option: provided => ({
    ...provided,
    fontFamily: "Poppins",
    fontSize: "13px",
  }),
  singleValue: provided => ({
    ...provided,
    fontFamily: "Poppins",
    fontSize: "13px",
    padding: "4px",
  }),
  multiValue: styles => ({
    ...styles,
    borderRadius: 3,
    margin: "4px",
    padding: "1px 7px 1px 0",
    flexDirection: "row-reverse",
    backgroundColor: "#F3F6F9",
  }),
  multiValueLabel: styles => ({
    ...styles,
    fontSize: "11px",
    lineHeight: "20px",
    backgroundColor: "#F3F6F9",
    maxWidth: "200px",
  }),
  multiValueRemove: styles => ({
    ...styles,
    color: "#566374",
  }),
  indicatorSeparator: styles => ({ ...styles, display: "none" }),
  placeholder: provided => ({
    ...provided,
    fontFamily: "Poppins",
    fontSize: "13px",
    color: "#a2a5b9"
  }),
};
