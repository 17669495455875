import React from "react";
import {
  Field,
  reduxForm,
  SubmissionError,
} from "redux-form";

import { connect } from "react-redux";
import { Input } from "expano-components";
import { createPropertyOption } from "actions/properties";

import { ReactComponent as ApproveIcon } from "icons/approve.svg";
import { ReactComponent as DeclineIcon } from "icons/decline.svg";

const InputField = (props) => <Input {...props} />;

const PropertyOptionNew = ({
  id,
  handleSubmit,
  handleClose,
  langs,
  createPropertyOption,
}) => {
  const onSubmit = (values) =>
    createPropertyOption({
      ...values,
      property_id: id,
    })
      .then(() => handleClose())
      .catch((err) => {
        throw new SubmissionError(err);
      });

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      className="property-options__form">
      <div className="form-row grid-4">
        {langs.map(({ code }) => (
          <Field
            key={code}
            name={`value_${code}`}
            component={InputField}
          />
        ))}

        <div className="form-group">
          <button className="btn btn-icon" type="submit">
            <ApproveIcon />
          </button>
          <button
            type="button"
            className="btn btn-icon"
            onClick={handleClose}>
            <DeclineIcon />
          </button>
        </div>
      </div>
    </form>
  );
};

export default connect(null, (dispatch) => ({
  createPropertyOption: (data) =>
    dispatch(createPropertyOption(data)),
}))(
  reduxForm({ form: "create-property-option" })(
    PropertyOptionNew
  )
);
