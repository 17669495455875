import { client } from "utils/api";

const getCategories = (q) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.get(
        `/root_taxons?context=list${
          q ? `&q[name_matches]=%25${q}%25` : ""
        }`
      );

      const options = data.map(({ name }) => ({
        label: name,
        value: name,
      }));
      resolve(options);
    } catch (error) {
      console.log(error);
      reject([]);
    }
  });

export default getCategories;
