import React from "react";
import { ReactComponent as ProductIcon } from "icons/product.svg";
import { ReactComponent as SettingsIcon } from "icons/settings.svg";

const nav = [
  {
    name: "Katalog",
    type: "group",
    icon: <ProductIcon />,
    items: [
      { name: "Produkty", path: "/products" },
      {
        name: "Kategorie",
        path: "/platforms/platform_taxons",
      },
      { name: "Właściwości", path: "/properties" },
      { name: "Prototypy", path: "/prototypes" },
    ],
  },
  {
    name: "Zarządzanie",
    type: "group",
    icon: <SettingsIcon />,
    items: [
      {
        name: "Ustawienia sprzedaży",
        path: "/settings",
      },
    ],
  },
];

export default nav;
