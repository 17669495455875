import React, { useState } from "react";
import {
  Field,
  reduxForm,
  SubmissionError,
} from "redux-form";
import { Button, Input } from "expano-components";
import { Main, Dashboard } from "components";

import { createProperty } from "actions/properties";

import { ReactComponent as PropertyIcon } from "icons/property.svg";
import property_langs from "utils/property_langs";

const InputField = (props) => <Input {...props} />;

const PropertyNew = ({
  handleSubmit,
  pristine,
  history,
}) => {
  const [active_tab, setTab] = useState("pl");

  const onSubmit = (values) =>
    createProperty(values)
      .then((id) => history.push(`/properties/${id}`))
      .catch((err) => {
        throw new SubmissionError(err);
      });

  return (
    <Main
      page={{
        name: "Właściwości",
        icon: <PropertyIcon />,
        breadcrumbs: [
          {
            name: "Właściwości",
            path: "/properties",
          },
          { name: "Dodaj właściwość" },
        ],
      }}>
      <Dashboard
        footer={
          <>
            <Button
              type="cancel"
              onClick={() => history.push("/properties")}
            />
            <Button
              type="save"
              disabled={pristine}
              onClick={handleSubmit(onSubmit)}
            />
          </>
        }>
        <form
          className="dashboard__form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate>
          <Field
            name="internal_name"
            label="Nazwa wewnętrzna"
            component={InputField}
            type="text"
          />
          <div className="languages-form">
            <ul className="lang-tabs">
              {property_langs.map(
                ({ code, name, flag }) => (
                  <li
                    key={code}
                    className={`lang-tabs__item${
                      active_tab === code ? " active" : ""
                    }`}>
                    <button
                      className="lang-tabs__item__button"
                      type="button"
                      onClick={() => {
                        setTab(code);
                        setTimeout(() => {
                          document
                            .querySelector(
                              ".lang-panels__item.active input"
                            )
                            .focus();
                        }, 100);
                      }}>
                      <span
                        className={`lang-tabs__item__flag flag-icon flag-icon-${flag}`}
                      />
                      <span className="lang-tabs__item__title">
                        {name}
                      </span>
                    </button>
                  </li>
                )
              )}
            </ul>
            <div className="lang-panels">
              {property_langs.map(({ code }) => (
                <div
                  className={`lang-panels__item ${
                    active_tab === code ? " active" : ""
                  }`}
                  key={code}>
                  <Field
                    name={`external_name_${code}`}
                    label={`Nazwa zewnętrzna - ${code}`}
                    component={InputField}
                    type="text"
                  />
                </div>
              ))}
            </div>
          </div>
        </form>
      </Dashboard>
    </Main>
  );
};

export default reduxForm({
  form: "create-property",
  enableReinitialize: true,
})(PropertyNew);
