import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { PageLoader, Main } from "components";
import { getPlatforms } from "actions/platforms";
import getPlatformColor from "utils/getPlatformColor";

import { ReactComponent as CategoryIcon } from "icons/category.svg";

const CategoriesMain = ({ platforms, getPlatforms }) => {
  useEffect(() => {
    getPlatforms();
  }, []);
  if (
    ["invalid", "loading", "failure"].includes(
      platforms.status
    )
  ) {
    return <PageLoader />;
  }

  return (
    <Main
      page={{
        name: "Kategorie",
        icon: <CategoryIcon />,
        breadcrumbs: [
          {
            name: "Kategorie",
          },
        ],
      }}>
      <ul className="category-list">
        {platforms?.data?.length > 0 &&
          platforms.data.map(({ name, id }) => (
            <li className="category-item" key={id}>
              <Link
                to={`/platforms/${id}/platform_taxons`}
                className="category-item__name"
                style={{ color: getPlatformColor(name) }}>
                {name}
              </Link>
            </li>
          ))}
      </ul>
    </Main>
  );
};

export default connect(
  ({ platforms }) => ({ platforms }),
  (dispatch) => ({
    getPlatforms: () => dispatch(getPlatforms()),
  })
)(CategoriesMain);
