import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "expano-components";
import { Modal, AsyncSelect } from "components";

import { assignPropertiesFromPrototype } from "actions/product_group/property_settings";
import getPrototypeOptions from "utils/select_options/getPrototypeOptions";

const AddPrototypeModal = ({
  product_group_id,
  handleClose,
  assignPropertiesFromPrototype,
}) => {
  const [prototype_id, setPrototypeId] = useState(null);

  return (
    <Modal
      header={{ title: "Dodaj prototyp" }}
      handleClose={handleClose}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="add-green"
            disabled={!prototype_id}
            text="Dodaj"
            onClick={() =>
              assignPropertiesFromPrototype({
                product_group_id,
                prototype_id,
              }).then(() => handleClose())
            }
          />
        </>
      }>
      <AsyncSelect
        label="Prototyp"
        onChange={({ value }) => setPrototypeId(value)}
        getData={getPrototypeOptions}
      />
    </Modal>
  );
};

export default connect(
  ({ product_group }) => ({
    product_group_id: product_group.data?.id,
  }),
  (dispatch) => ({
    assignPropertiesFromPrototype: (values) =>
      dispatch(assignPropertiesFromPrototype(values)),
  })
)(AddPrototypeModal);
