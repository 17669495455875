import { toastr } from "react-redux-toastr";
import { client } from "utils/api";

export const updateProductGroupMockup = (
  id,
  mockup
) => async (dispatch) => {
  try {
    await client.put(`/product_groups/${id}`, { mockup });
    dispatch({
      type: "PRODUCT_GROUP_UPDATE_MOCKUP",
      mockup,
    });
    toastr.success(
      "Sukces",
      "Makieta została zaktualizowana"
    );
  } catch (error) {
    toastr.error(
      "Błąd",
      "Wystąpił błąd podzczas aktualiozwania makiety"
    );
  }
};
