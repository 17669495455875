import React from "react";
import { AsidePanel, ProductGroupPanel } from "./components";

const DangerousInfo = () => (
  <div className="main-wrapper">
    <AsidePanel />
    <ProductGroupPanel />
  </div>
);

export default DangerousInfo;
