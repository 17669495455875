import React, { useState } from "react";
import {
  Field,
  reduxForm,
  SubmissionError,
} from "redux-form";
import { connect } from "react-redux";
import {
  Input,
  Button,
  ModalDelete,
} from "expano-components";
import {
  deletePropertyOption,
  updatePropertyOption,
} from "actions/properties";
import { ReactComponent as ApproveIcon } from "icons/approve.svg";
import { ReactComponent as DeclineIcon } from "icons/decline.svg";

const InputField = (props) => <Input {...props} />;

const EditPropertyOptionRow = ({
  id,
  handleSubmit,
  initialValues,
  updatePropertyOption,
  deletePropertyOption,
}) => {
  const [show_delete_modal, setShowDeleteModal] = useState(
    false
  );
  const [show_edit, setShowEdit] = useState(false);

  const onSubmit = ({ property_id, value }) => {
    const data = {
      property_id,
    };
    Object.keys(value).map((code) => {
      data[`value_${code}`] = value[code];
      return data;
    });
    return updatePropertyOption(id, {
      ...data,
    })
      .then(() => setShowEdit(false))
      .catch((err) => {
        throw new SubmissionError(err);
      });
  };

  const handleDelete = async () => {
    await deletePropertyOption(id);
    setShowDeleteModal(false);
  };

  return !show_edit ? (
    <>
      {show_delete_modal && (
        <ModalDelete
          handleDelete={handleDelete}
          handleClose={() => setShowDeleteModal(false)}
          name="wartość właściwości?"
        />
      )}
      <tr>
        <td>{initialValues.value.pl}</td>
        <td>{initialValues.value.de}</td>
        <td>{initialValues.value.en}</td>
        <td>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}>
            <Button
              type="edit"
              onClick={() => setShowEdit(true)}
            />
            <Button
              type="delete-bin"
              onClick={() => setShowDeleteModal(true)}
            />
          </div>
        </td>
      </tr>
    </>
  ) : (
    <tr>
      <td style={{ paddingRight: "30px" }}>
        <Field name={`value[pl]`} component={InputField} />
      </td>
      <td style={{ paddingRight: "30px" }}>
        <Field name={`value[de]`} component={InputField} />
      </td>
      <td style={{ paddingRight: "30px" }}>
        <Field name={`value[en]`} component={InputField} />
      </td>
      <td>
        <button
          type="button"
          className="btn btn-icon"
          onClick={handleSubmit(onSubmit)}>
          <ApproveIcon />
        </button>
        <button
          className="btn btn-icon"
          type="button"
          onClick={() => setShowEdit(false)}>
          <DeclineIcon />
        </button>
      </td>
    </tr>
  );
};

export default connect(null, (dispatch) => ({
  updatePropertyOption: (id, data) =>
    dispatch(updatePropertyOption(id, data)),
  deletePropertyOption: (id) =>
    dispatch(deletePropertyOption(id)),
}))(
  reduxForm({ enableReinitialize: true })(
    EditPropertyOptionRow
  )
);
