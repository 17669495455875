import "react-markdown-editor-lite/lib/index.css";

import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { withRouter, Link } from "react-router-dom";
import { Login, TopBar, Sidebar } from "expano-components";
import { handleLogin, handleLogout } from "actions/account";
import { getCurrentUser } from "actions/current_user";
import { PageLoader } from "components";
import { toggleSidebar } from "actions/sidebar";

import Router from "../Router";
import nav from "./nav";

const app = "pim";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
      cacheTime: 30 * 1000, //30s
      keepPreviousData: true,
    },
  },
});

const App = ({
  current_user,
  getCurrentUser,
  sidebar,
  toggleSidebar,
  history,
  location,
}) => {
  useEffect(() => {
    getCurrentUser();
  }, []);

  if (
    ["invalid", "loading"].includes(current_user.status)
  ) {
    return <PageLoader />;
  }
  if (current_user.status === "failure") {
    return (
      <Login
        app={app}
        handleLogin={(data) => {
          handleLogin(data);
        }}
      />
    );
  }
  const { email, company } = current_user;

  return (
    <QueryClientProvider client={queryClient}>
      <Sidebar
        app={app}
        Link={Link}
        location={location}
        nav={nav}
        is_open={sidebar.is_open}
      />
      <main className="main-container">
        <TopBar
          handleLogout={handleLogout}
          is_open={sidebar.is_open}
          toggleSidebar={toggleSidebar}
          history={history}
          currentUser={{ email, company: company?.name }}
        />
        <Router />
      </main>
    </QueryClientProvider>
  );
};

const mapStateToProps = ({ current_user, sidebar }) => ({
  current_user,
  sidebar,
});

const mapDispatchToProps = (dispatch) => ({
  toggleSidebar: () => dispatch(toggleSidebar()),
  getCurrentUser: () => dispatch(getCurrentUser()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(App));
