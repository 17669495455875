import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import {
  AsidePanel,
  ProductGroupPanel,
} from "./components";

const ProductGroupCountryKeywords = ({
  match: {
    params: { product_group_id, type },
  },
}) => {
  if (!type) {
    return (
      <Redirect
        to={`/product_groups/${product_group_id}/country_keywords/PL`}
      />
    );
  }
  return (
    <div className="main-wrapper">
      <AsidePanel />
      <ProductGroupPanel />
    </div>
  );
};

export default withRouter(ProductGroupCountryKeywords);
