import React, { useState } from "react";
import { connect } from "react-redux";
import { ReactComponent as PlusIcon } from "icons/plus.svg";
import {
  AddPrototypeModal,
  AddPropertyModal,
  PropertySettingLine,
  ImportPropertiesModal,
} from "./components";

const PropertySettings = ({ property_settings }) => {
  const [is_open_import_modal, handleOpenImportModal] = useState(false);
  const [is_open_prototype_modal, handleOpenPrototypeModal] = useState(false);
  const [is_open_property_modal, handleOpenPropertyModal] = useState(false);

  return (
    <>
      {is_open_import_modal && (
        <ImportPropertiesModal
          handleClose={() => handleOpenImportModal(false)}
        />
      )}
      {is_open_prototype_modal && (
        <AddPrototypeModal
          handleClose={() => handleOpenPrototypeModal(false)}
        />
      )}
      {is_open_property_modal && (
        <AddPropertyModal handleClose={() => handleOpenPropertyModal(false)} />
      )}
      <div className="create-table">
        <table>
          <thead>
            <tr>
              <th>Nazwa właściwości</th>
              <th>Typ właściwości</th>
              <th />
              <th>
                <button
                  type="button"
                  className="btn btn-sm btn-blue"
                  style={{ marginRight: 10 }}
                  onClick={() => handleOpenImportModal(true)}
                >
                  <span>Importuj właściwości</span>
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-blue"
                  onClick={() => handleOpenPrototypeModal(true)}
                >
                  <span>Dodaj prototyp</span>
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {property_settings.length > 0 &&
              property_settings.map((item) => (
                <PropertySettingLine {...item} key={item.id} />
              ))}
            <tr>
              <td>
                <button
                  className="create-table-btn"
                  type="button"
                  onClick={() => handleOpenPropertyModal(true)}
                >
                  <PlusIcon />
                  <span>Dodaj właściwość</span>
                </button>
              </td>
              <td />
              <td />
              <td />
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default connect(
  ({
    product_group: {
      data: { property_settings },
    },
  }) => ({
    property_settings,
  })
)(PropertySettings);
