import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { PanelLoader } from "components";
import { getPlatforms } from "actions/platforms";
import PlatformTaxonsList from "./components/PlatformTaxonsList";
import Aside from "./components/Aside";

const PlatformTaxons = ({
  platforms,
  getPlatforms,
  history,
  match: {
    params: { product_group_id, type_id },
  },
}) => {
  useEffect(() => {
    getPlatforms().then((data) => {
      if (!type_id && data.length > 0) {
        history.push(
          `/product_groups/${product_group_id}/platform_taxons/platforms/${data[0].id}`
        );
      }
    });
  }, []);

  if (
    ["invalid", "loading", "failure"].includes(
      platforms.status
    )
  ) {
    return <PanelLoader />;
  }

  return (
    <div className="main-wrapper">
      <div className="box-300">
        <Aside />
      </div>
      <PlatformTaxonsList />
    </div>
  );
};

export default connect(
  ({ platforms }) => ({
    platforms,
  }),
  (dispatch) => ({
    getPlatforms: () => dispatch(getPlatforms()),
  })
)(withRouter(PlatformTaxons));
