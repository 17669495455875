import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Editor from "react-markdown-editor-lite";
import MarkdownIt from "markdown-it";
import { reduxForm, Field } from "redux-form";
import { Button, Input } from "expano-components";
import { updateDescriptionSection } from "actions/product_group/description_sections";

import CommentsModal from "./components/CommentsModal";
import AiModal from "./components/AiModal";

const mdParser = new MarkdownIt();

const Text = ({
  id,
  content,
  description_section_comments = [],
  position,
  handleSubmit,
  pristine,
  valid,
  submitting,
  change,
  updateDescriptionSection,
  inherited,
}) => {
  const [
    is_comments_modal_open,
    handleOpenCommentsModal,
  ] = useState(false);

  const [is_ai_modal_open, handleOpenAiModal] = useState(
    false
  );

  const [text, setText] = useState("");

  useEffect(() => {
    setText(content || "");
  }, [content]);

  const onSubmit = ({ content }) =>
    updateDescriptionSection(id, { content });

  return (
    <>
      {is_comments_modal_open && (
        <CommentsModal
          handleClose={() => handleOpenCommentsModal(false)}
          content={mdParser.render(text)}
          id={id}
          position={position}
        />
      )}
      {is_ai_modal_open && (
        <AiModal
          handleClose={() => handleOpenAiModal(false)}
          id={id}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <div className="textarea-wrapper">
            <div className="text-section-label">
              <label>Opis</label>
              <button
                type="button"
                className="btn-comments"
                onClick={() =>
                  handleOpenCommentsModal(true)
                }
              >
                <span className="name">Uwagi</span>
                <span
                  className={`number ${
                    description_section_comments.length > 0
                      ? "danger"
                      : ""
                  }`}
                >
                  {description_section_comments.length}
                </span>
              </button>
            </div>
            <Field
              component={(props) => <Input {...props} />}
              type="hidden"
              name="content"
            />
            <Editor
              plugins={["header", "fonts", "link"]}
              value={text}
              config={{
                view: {
                  menu: true,
                  md: true,
                  html: false,
                  fullScreen: false,
                  hideMenu: false,
                },
              }}
              style={{ height: "280px" }}
              onChange={({ text }) => {
                change("content", text);
                setText(text);
              }}
            />
          </div>
        </div>
        <div className="df ai-c">
          {!!!inherited && (
            <Button
              type="save"
              text="Zapisz"
              disabled={pristine || !valid || submitting}
              onClick={handleSubmit(onSubmit)}
            />
          )}
          <button
            type="button"
            className="btn btn-sm btn-blue m-l-10"
            onClick={() => handleOpenAiModal(true)}
          >
            <span>Generuj AI</span>
          </button>
        </div>
      </form>
    </>
  );
};

export default connect(null, (dispatch) => ({
  updateDescriptionSection: (id, data) =>
    dispatch(updateDescriptionSection(id, data)),
}))(
  reduxForm({
    enableReinitialize: true,
  })(Text)
);
