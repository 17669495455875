import React, { useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Button, Checkbox, Input } from "expano-components";
import { Modal } from "components";
import { updateProductGroupCountryKeyword } from "actions/product_group/country_keywords";

const validate = (values) => {
  let errors = {};
  if (!values.name) {
    errors.name = "Pole wymagane";
  }

  return errors;
};

const EditKeywordModal = ({
  id,
  product_group_id,
  valid,
  pristine,
  handleClose,
  handleSubmit,
  updateProductGroupCountryKeyword,
}) => {
  const [is_loading, setLoading] = useState(false);

  const onSubmit = async ({
    name,
    volumen,
    is_title,
    is_used,
  }) => {
    try {
      setLoading(true);
      await updateProductGroupCountryKeyword(
        id,
        product_group_id,
        {
          name,
          volumen,
          is_title,
          is_used,
        }
      );
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Modal
      header={{ title: "Aktualizuj slowo kluczowe" }}
      handleClose={handleClose}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="save"
            disabled={!valid || pristine || is_loading}
            text={
              is_loading ? "Proszę czekać..." : "Zapisz"
            }
            onClick={handleSubmit(onSubmit)}
          />
        </>
      }>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="submit" style={{ display: "none" }} />
        <Field
          label="Nazwa"
          name="name"
          component={Input}
        />
        <Field
          type="number"
          label="Wolumen"
          name="volumen"
          component={Input}
        />
        <Field
          label="Czy tytułowe?"
          name="is_title"
          component={Checkbox}
        />
        <Field
          label="Czy użyte?"
          name="is_used"
          component={Checkbox}
        />
      </form>
    </Modal>
  );
};

export default connect(null, (dispatch) => ({
  updateProductGroupCountryKeyword: (
    id,
    product_group_id,
    values
  ) =>
    dispatch(
      updateProductGroupCountryKeyword(
        id,
        product_group_id,
        values
      )
    ),
}))(
  reduxForm({
    form: "update-product-group-country-keyword",
    validate,
    enableReinitialize: true,
  })(EditKeywordModal)
);
