import { toastr } from "react-redux-toastr";
import { client } from "utils/api";

export const uploadProductGroupImages = async ({
  product_group_id,
  files,
}) => {
  try {
    await Promise.all(
      files.map(async (image) => {
        const body = new FormData();
        body.append("image", image);
        body.append("product_group_id", product_group_id);
        await client.post(`/product_group_images/`, body, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      })
    );
    toastr.success(
      "Sukces",
      files.length > 1
        ? "Zdjęcia zostały dodane"
        : "Zdjęcie zostało dodane"
    );
  } catch (error) {
    toastr.error(
      "Błąd",
      files.length > 1
        ? "Wystąpił błąd podczas wgrywania zdjęć"
        : "Wystąpił błąd podczas wgrywania zdjęcia"
    );

    throw new Error(error.response?.data?.error);
  }
};

export const updateProductGroupImage = async (
  id,
  image
) => {
  try {
    const body = new FormData();
    body.append("image", image);
    await client.put(`/product_group_images/${id}`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    toastr.success("Sukces", "Zdjęcie zostało zmienione");
  } catch (error) {
    toastr.error("Błąd", "Nie udało się zmienić zdjęcia");
    throw new Error(error.response?.data?.error);
  }
};

export const deleteProductGroupImages = async (ids) => {
  try {
    await Promise.all(
      ids.map((id) =>
        client.delete(`/product_group_images/${id}`)
      )
    );
    toastr.success(
      "Sukces",
      ids.length > 1
        ? "Zdjęcia zostały usuniete"
        : "Zdjęcie zostało usuniete"
    );
  } catch (error) {
    toastr.error(
      "Błąd",
      ids.length > 1
        ? "Wystąpił błąd podczas usuwania zdjęć"
        : "Wystąpił błąd podczas usuwania zdjęcia"
    );
    throw new Error(error.response?.data?.error);
  }
};

export const setProductGroupGlobalImage = async (id) => {
  try {
    await client.post(
      `/product_group_images/${id}/mark_as_global`
    );
    toastr.success(
      "Sukces",
      "Zdjęcie globalne zostało zmienione"
    );
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd");
    throw new Error(error.response?.data?.error);
  }
};

