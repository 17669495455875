import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import { reducer as toastr } from "react-redux-toastr";

import current_user from "./current_user";
import property from "./property";
import prototype from "./prototype";
import product_group from "./product_group";
import channel_product_group from "./channel_product_group";
import platforms from "./platforms";
import platform_taxons from "./platform_taxons";
import platform_taxon from "./platform_taxon";
import settings from "./settings";
import setting from "./setting";
import sidebar from "./sidebar";

export default combineReducers({
  current_user,
  channel_product_group,
  form,
  platforms,
  platform_taxon,
  platform_taxons,
  product_group,
  property,
  prototype,
  sidebar,
  toastr,
  settings,
  setting,
});
