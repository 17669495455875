import React from "react";
import { connect } from "react-redux";
import { submit } from "redux-form";
import { Button } from "expano-components";

import ProductForm from "./components/ProductForm";

import { ReactComponent as InfoIcon } from "icons/info.svg";

const BasicData = ({
  channel,
  channel_products,
  dispatch,
}) => {
  const { platform } = channel;
  const handleSubmit = () => {
    dispatch(
      submit("channel-product-group-basic-data-form")
    );
    if (channel_products?.length > 0) {
      channel_products.forEach(({ id }) => {
        dispatch(
          submit(`channel-product-basic-data-form-${id}`)
        );
      });
    }
  };

  return (
    <div className="dashboard">
      <header className="dashboard__header dashboard__header--edit">
        <div className="icon__container">
          <InfoIcon />
        </div>
        <h2 className="dashboard__title heading">
          Tytuły ofert
        </h2>
        {platform?.country_code && (
          <div className="dashboard__header-lang default-text">
            Język kanału:
            <span className="ml-10">
              {platform.country_code}
            </span>
            <span
              className={`ml-10 flag-icon flag-icon-${
                platform.country_code === "EN"
                  ? "gb"
                  : platform.country_code.toLowerCase()
              }`}
            />
          </div>
        )}
      </header>
      <div className="dashboard__form__submit">
        <div style={{ paddingLeft: 30 }}>
          {channel_products.map((item) => (
            <ProductForm
              {...item}
              key={item.id}
              initialValues={{
                id: item.id,
                name: item.name,
                subname: item.subname,
                keyword_list: item.keyword_list,
              }}
              form={`channel-product-basic-data-form-${item.id}`}
            />
          ))}
        </div>
      </div>
      <div className="dashboard__form__footer">
        <Button
          type="save"
          text="Zapisz"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default connect(
  ({
    channel_product_group: {
      data: { channel_products, channel },
    },
  }) => ({
    channel_products,
    channel,
  })
)(BasicData);
