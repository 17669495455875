import { toastr } from "react-redux-toastr";
import { client } from "utils/api";
import { getChannelProductGroup } from "actions/product_group/channel_product_group";

export const createDescriptionSection = (values) => async (
  dispatch,
  getState
) => {
  try {
    const {
      id: channel_product_group_id,
    } = getState().channel_product_group.data;

    await client.post("/description_sections", values);

    await dispatch(
      getChannelProductGroup(channel_product_group_id)
    );

    toastr.success("Sukces", "Sekcja została dodana");
  } catch (error) {
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas dodawania sekcji"
    );
    throw error;
  }
};

export const updateDescriptionSection = (
  id,
  values
) => async (dispatch, getState) => {
  try {
    const {
      id: channel_product_group_id,
    } = getState().channel_product_group.data;

    await client.put(`/description_sections/${id}`, values);
    await dispatch(
      getChannelProductGroup(channel_product_group_id)
    );

    toastr.success(
      "Sukces",
      "Sekcja została zaktualizowana"
    );
  } catch (error) {
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas aktualizowania sekcji"
    );
    throw error;
  }
};

export const deleteDescriptionSection = (id) => async (
  dispatch,
  getState
) => {
  try {
    const {
      id: channel_product_group_id,
    } = getState().channel_product_group.data;

    await client.delete(`/description_sections/${id}`);

    await dispatch(
      getChannelProductGroup(channel_product_group_id)
    );
    toastr.success("Sukces", "Sekcja została usunięta");
  } catch (error) {
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas usuwania sekcji"
    );
    throw error;
  }
};

export const moveUpDescriptionSection = (id) => async (
  dispatch,
  getState
) => {
  try {
    const {
      id: channel_product_group_id,
    } = getState().channel_product_group.data;

    await client.put(`/description_sections/${id}/move_up`);

    await dispatch(
      getChannelProductGroup(channel_product_group_id)
    );
    toastr.success(
      "Sukces",
      "Sekcja została przeniesiona w górę"
    );
  } catch (error) {
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas przenoszenia sekcji"
    );
    throw error;
  }
};

export const moveDownDescriptionSection = (id) => async (
  dispatch,
  getState
) => {
  try {
    const {
      id: channel_product_group_id,
    } = getState().channel_product_group.data;

    await client.put(
      `/description_sections/${id}/move_down`
    );

    await dispatch(
      getChannelProductGroup(channel_product_group_id)
    );
    toastr.success(
      "Sukces",
      "Sekcja została przeniesiona w dół"
    );
  } catch (error) {
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas przenoszenia sekcji"
    );
    throw error;
  }
};

export const copyDescriptionSection = (
  id,
  copy_from_id
) => async (dispatch, getState) => {
  try {
    const {
      id: channel_product_group_id,
    } = getState().channel_product_group.data;

    await client.post(
      `/description_sections/${id}/copy_section`,
      {
        copy_from_id,
      }
    );
    await dispatch(
      getChannelProductGroup(channel_product_group_id)
    );
    toastr.success("Sukces", "Sekcja została skopiowana");
  } catch (error) {
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas kopiowania sekcji"
    );
    throw error;
  }
};

export const updateDescriptionSectionProductGroupImage = (
  id,
  data,
  notify = true
) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      await client
        .put(
          `/description_section_product_groups/${id}`,
          data
        )
        .then(({ data }) => {
          notify &&
            toastr.success(
              "Sukces",
              "Sekcja została zaktualizowana"
            );

          resolve();
        })
        .catch((err) => {
          reject(err.response.data.error);
          toastr.error(
            "Błąd",
            "Wystąpił błąd podczas aktualizowania sekcji"
          );
        });
    } catch (error) {
      toastr.error(
        "Błąd",
        "Wystąpił błąd podczas aktualizowania sekcji"
      );
      reject();
    }
  });
};

export const updateDescriptionSectionProductImage = (
  id,
  values
) => async (dispatch, getState) => {
  try {
    const {
      id: channel_product_group_id,
    } = getState().channel_product_group.data;

    await client.put(
      `/description_section_products/${id}`,
      values
    );

    await dispatch(
      getChannelProductGroup(channel_product_group_id)
    );
    toastr.success(
      "Sukces",
      "Sekcja została zaktualizowana"
    );
  } catch (error) {
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas aktualizowania sekcji"
    );
    throw error;
  }
};

export const getPrompt = async (id, mode) => {
  try {
    const data = await client.get(
      `/description_sections/${id}/fetch_ai_content_prompt${
        mode ? `?mode=${mode}` : ""
      }`
    );
    toastr.success("Sukces", "Prompt został wygenerowany");
    return data;
  } catch (error) {
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas generowania prompt"
    );
    throw error;
  }
};

export const createAiDescription = ({
  id,
  mode,
  prompt,
}) => async (dispatch, getState) => {
  try {
    const {
      id: channel_product_group_id,
    } = getState().channel_product_group.data;

    await client.post(
      `/description_sections/${id}/generate_ai_content`,
      { mode, prompt }
    );

    await dispatch(
      getChannelProductGroup(channel_product_group_id)
    );

    toastr.success(
      "Sukces",
      "Opis został wygenerowany"
    );
  } catch (error) {
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas generowania opisu"
    );
    throw error;
  }
};
