import React from "react";
import { Label } from "expano-components";
import { client, stringifyQuery } from "utils/api";
import section_type_options from "utils/select_options/section_type_options";

const getDescriptionSections = (
  q = null,
  channel_product_group_ids
) => {
  const query = {
    context: "ui_copy_section",
    "q[type_not_eq]":
      "DescriptionSection::InheritedSection",
    per_page: 10,
    "q[channel_product_group_id_in]": channel_product_group_ids,
  };
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.get(
        `/description_sections?${stringifyQuery({
          ...query,
          "q[channel_product_group_channel_identifier_or_content_matches]": q
            ? `%25${q}%25`
            : undefined,
        })}

        `
      );
      const options = data.map(
        ({
          id,
          position,
          type,
          content,
          channel_product_group: {
            channel: { shop, platform },
          },
        }) => ({
          label: (
            <div>
              <Label
                type="double"
                left={{
                  text: shop.label_text,
                  color: `#${shop.label_color}`,
                }}
                right={{
                  text: platform.label_text,
                  color: `#${platform.label_color}`,
                }}
              />
              Sekcja {position} (
              {
                section_type_options.find(
                  ({ value }) => value === type
                )?.label
              }
              ){" "}
              <span
                style={{
                  fontSize: 10,
                  lineHeight: "12px",
                  color: "#a2a5b9",
                }}>
                {content?.slice(0, 50)}
              </span>
            </div>
          ),
          value: id,
        })
      );
      resolve(options);
    } catch (error) {
      console.log(error);
      reject([]);
    }
  });
};

export default getDescriptionSections;
