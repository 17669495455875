import React, {
  useState,
  useCallback,
  useEffect,
} from "react";
import { NativeTypes } from "react-dnd-html5-backend";
import { connect } from "react-redux";

import update from "immutability-helper";
import { Button } from "expano-components";
import { PanelLoader } from "components";

import { client } from "utils/api";
import { setChanelProduct360ImagesOrder } from "actions/product_group/channel_product360_images";

import GridImage from "./components/GridImage";
import OrderListItem from "./components/OrderListItem";

import { ReactComponent as GalleryIcon } from "icons/gallery.svg";

const ModalContainer = ({
  id,
  gallery360,
  handleClose,
  setChanelProduct360ImagesOrder,
}) => {
  const [is_saving, setSaving] = useState(false);
  const [is_loading, setLoading] = useState(true);
  const [dustbins, setDustbins] = useState([]);
  const [droppedBoxNames, setDroppedBoxNames] = useState(
    []
  );

  const isDropped = (order) => {
    return !!droppedBoxNames.find(
      (box) => box.order === order
    );
  };

  const [boxes] = useState(
    gallery360.map((item) => {
      return {
        order: item.position,
        id: item.id,
        type: NativeTypes.FILE,
      };
    })
  );

  const handleDrop = useCallback(
    (index, item) => {
      const { order } = item;
      if (
        !!droppedBoxNames.find((box) => box.index === index)
      ) {
        const el = droppedBoxNames.find(
          (box) => box.index === index
        );
        const item_index = droppedBoxNames.indexOf(el);
        setDroppedBoxNames(
          droppedBoxNames.splice(item_index, 1)
        );
      }
      setDroppedBoxNames(
        update(droppedBoxNames, {
          $push: [{ order, index }],
        })
      );
      setDustbins(
        update(dustbins, {
          [index]: {
            lastDroppedItem: {
              $set: { ...item },
            },
          },
        })
      );
    },
    [droppedBoxNames, dustbins]
  );

  const handleRemove = useCallback(
    ({ id, order }) => {
      try {
        const target_dustbins = dustbins.find(
          ({ lastDroppedItem }) =>
            lastDroppedItem?.id === id
        );
        const index_dustbins = dustbins.indexOf(
          target_dustbins
        );

        const boxes = droppedBoxNames.filter(
          (item) => item.order !== order
        );
        dustbins[index_dustbins].lastDroppedItem = null;
        setDustbins(dustbins);
        setDroppedBoxNames(boxes);
      } catch (error) {
        console.log(error);
        return;
      }
    },
    [droppedBoxNames, dustbins]
  );

  const getData = async () => {
    const generateData = (data) => {
      const all_data = data.map((item) => {
        try {
          const ordered_item = gallery360.find(
            (image) =>
              image[`product_image`] &&
              image[`product_image`].id === item.id
          );
          return {
            ...item,
            data_360: ordered_item
              ? {
                  order: ordered_item.position,
                  id: ordered_item.id,
                  type: [NativeTypes.FILE],
                }
              : null,
          };
        } catch (error) {
          console.log(error);
          return {
            ...item,
          };
        }
      });
      const ordered = all_data
        .filter(({ data_360 }) => !!data_360)
        .sort(
          (item1, item2) =>
            item1.data_360.order - item2.data_360.order
        );
      const un_ordered = all_data.filter(
        ({ data_360 }) => !!!data_360
      );
      return [...ordered, ...un_ordered];
    };

    const updateState = (data) => {
      setDustbins(
        data.map((data, index) => ({
          accepts: [NativeTypes.FILE],
          lastDroppedItem: data.data_360
            ? { ...data.data_360 }
            : null,
          data,
        }))
      );
      setDroppedBoxNames(
        data
          .map(
            (data, index) =>
              data.data_360 && {
                order: data.data_360.order,
                index,
              }
          )
          .filter(Boolean)
      );
      setLoading(false);
    };
    const { data } = await client.get(
      `/product_images?sort[order]=asc&sort[column]=id&q[product_id_eq]=${id}context=ui_product_360&per_page=100`
    );
    updateState(generateData(data));
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSubmit = async () => {
    try {
      setSaving(true);
      const data = boxes.map(({ id }) => {
        const ordered_item = dustbins.find(
          ({ lastDroppedItem }) =>
            lastDroppedItem?.id === id
        );
        return {
          id,
          product_image_id: ordered_item?.data?.id || null,
        };
      });
      await setChanelProduct360ImagesOrder(data);
      setSaving(false);
      handleClose();
    } catch (error) {
      console.log(error);
      setSaving(false);
    }
  };

  return (
    <div className="rotate_wrapper">
      <>
        <div className="rotate_images">
          <header className="popup__header">
            <div className="icon__container">
              <GalleryIcon />
            </div>
            <h2 className="dashboard__title heading">
              Ustal kolejność widoku 360&#176;
            </h2>
            <Button type="close" onClick={handleClose} />
          </header>
          <section className="popup__body">
            {is_loading ? (
              <PanelLoader />
            ) : (
              <div className="rotate_images__grid">
                {dustbins.length > 0 ? (
                  dustbins.map(
                    (
                      { accepts, data, lastDroppedItem },
                      index
                    ) => (
                      <GridImage
                        accept={accepts}
                        data={data}
                        lastDroppedItem={lastDroppedItem}
                        onDrop={(item) =>
                          handleDrop(index, item)
                        }
                        key={index}
                        removeOrder={handleRemove}
                      />
                    )
                  )
                ) : (
                  <p>Brak zdjęć</p>
                )}
              </div>
            )}
          </section>
          <div
            className="popup__footer"
            style={{
              padding: "10px 15px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}>
            <Button type="cancel" onClick={handleClose} />
            <Button
              disabled={is_loading || is_saving}
              type="save"
              text="Zapisz"
              onClick={handleSubmit}
            />
          </div>
        </div>
        <div className="rotate_list">
          <div className="rotate_list__header">
            <p>Drag & drop labels</p>
          </div>
          <div className="rotate_list__grid">
            {boxes.map(({ order, type, id }, index) => (
              <OrderListItem
                order={order}
                id={id}
                type={type}
                isDropped={isDropped(order)}
                key={index}
              />
            ))}
          </div>
        </div>
      </>
    </div>
  );
};

export default connect(null, (dispatch) => ({
  setChanelProduct360ImagesOrder: (data) =>
    dispatch(setChanelProduct360ImagesOrder(data)),
}))(ModalContainer);
