import React, { useState } from "react";
import { connect } from "react-redux";
import { url } from "utils/api";
import { autoAssignChannelProduct360Image } from "actions/product_group/channel_product";

import ModalRotate from "./components/ModalRotate";

const RotateGrid = ({
  gallery360,
  id,
  autoAssignChannelProduct360Image,
  channel_product_id,
}) => {
  const [show_rotate_modal, showRotateModal] = useState(
    false
  );

  return (
    <>
      {show_rotate_modal && (
        <ModalRotate
          gallery360={gallery360}
          id={id}
          handleClose={() => showRotateModal(false)}
        />
      )}
      <div className="rotate-box">
        <div className="rotate-box__grid">
          {gallery360
            .sort((a, b) => a.position - b.position)
            .map(({ product_image }, index) => (
              <div className="rotate-box" key={index}>
                {product_image?.image && (
                  <div
                    className="rotate-box__background"
                    style={{
                      backgroundImage: `url(${url}${product_image.image})`,
                    }}
                  />
                )}
                <span>{index + 1}</span>
              </div>
            ))}
        </div>
        <div className="rotate-box__action">
          <button
            type="button"
            className="btn btn-lg btn-blue"
            onClick={() => showRotateModal(true)}>
            <span>Zarządzaj 360&#176;</span>
          </button>
          <button
            type="button"
            className="btn-link"
            onClick={() =>
              autoAssignChannelProduct360Image(
                channel_product_id
              )
            }>
            Przypisz automatycznie
          </button>
        </div>
      </div>
    </>
  );
};

export default connect(null, (dispatch) => ({
  autoAssignChannelProduct360Image: (id) =>
    dispatch(autoAssignChannelProduct360Image(id)),
}))(RotateGrid);
