import { toastr } from "react-redux-toastr";
import { client } from "utils/api";
import { getProductGroup } from "actions/product_group";

export const updateProduct = (id, values) => async (dispatch, getState) => {
  try {
    const {
      product_group: {
        data: { id: product_group_id },
      },
    } = getState();
    await client.put(`/products/${id}`, values);

    await dispatch(getProductGroup(product_group_id));
    toastr.success("Sukces", "Produkt został zaktualizowany");
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd podczas aktualizowania produktu");
  }
};

export const updateProductProperties = (data) => async (dispatch, getState) => {
  try {
    const {
      product_group: {
        data: { id: product_group_id },
      },
    } = getState();

    await Promise.all(
      data.map(({ id, property_option_id }) =>
        client.put(`/product_properties/${id}`, {
          property_option_id,
        })
      )
    );
    await dispatch(getProductGroup(product_group_id));

    toastr.success("Sukces", "Produkt został zaktualizowany");
  } catch (error) {
    toastr.error("Błąd", error?.response?.data?.message || "Wystąpił błąd");
  }
};

export const refreshPrice = (product_id) => (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        product_group: {
          data: { id: product_group_id },
        },
      } = getState();
      await client.post(`/products/${product_id}/refresh_purchase_price`);
      await dispatch(getProductGroup(product_group_id));
      resolve(product_id);
    } catch (error) {
      reject(error);
    }
  });

export const bulkUpdateProducts = (products) => async(dispatch, getState) => {
  try {
    const {
      product_group: {
        data: { id: product_group_id },
      },
    } = getState();

    const promises = Object.keys(products).map(product_id => client.put(`/products/${product_id}`, products[product_id]))
    await Promise.all(promises)
    await dispatch(getProductGroup(product_group_id));
    toastr.success("Sukces", "Ceny produktów został zaktualizowane");
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd podczas aktualizowania cen produktów");
  }
};
