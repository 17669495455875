import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "expano-components";
import { Modal, AsyncSelect } from "components";
import getDescriptionSections from "utils/select_options/getDescriptionSections";

const CopyDescriptionSectionModal = ({
  channel_product_group_ids,
  handleClose,
  handleCopy,
}) => {
  const [data, setData] = useState(null);

  return (
    <Modal
      size="lg"
      handleClose={handleClose}
      header={{ title: "Kopiuj sekcje" }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            disabled={!data}
            type="save"
            text="Kopiuj"
            onClick={() => handleCopy(data)}
          />
        </>
      }>
      <AsyncSelect
        getData={(q) =>
          getDescriptionSections(
            q,
            channel_product_group_ids
          )
        }
        onChange={({ value }) => setData(value)}
      />
    </Modal>
  );
};

export default connect(
  ({
    product_group: {
      data: { channel_product_groups },
    },
  }) => ({
    channel_product_group_ids: channel_product_groups.map(
      ({ id }) => id
    ),
  })
)(CopyDescriptionSectionModal);
