import { client } from "utils/api";

const getProductGroupOptions = (q) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.get(
        `/product_groups?context=list&per_page=50${
          q ? `&q[name_or_sku_matches]=%25${q}%25` : ""
        }`
      );

      const options = data.map(({ id, name,sku }) => ({
        label: `[${sku}] ${name}`,
        value: id,
      }));

      resolve(options);
    } catch (error) {
      console.log(error);
      reject([]);
    }
  });

export default getProductGroupOptions;
