import React from "react";
import { connect } from "react-redux";
import { updateProductGroup } from "actions/product_group";
import { Button, Checkbox, Textarea } from "expano-components";
import { Field, reduxForm } from "redux-form";

const TextAreaField = (props) => (
  <Textarea
    {...props}
    input={{
      ...props.input,
      rows: 7,
    }}
  />
);

const ProductGroupPanel = ({
  product_group,
  updateProductGroup,
  handleSubmit,
  pristine,
  valid,
  submitting,
}) => {
  const onSubmit = async (values) => {
    try {
      await updateProductGroup(product_group.id, values);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form className="create-table" onSubmit={handleSubmit(onSubmit)}>
      <div className="row m-b-10">
        <div className="box-3">
          <Field
            label="Produkt niebezpieczny"
            name="is_dangerous"
            component={Checkbox}
          />
        </div>
      </div>
      {product_group.is_dangerous && (
        <>
          <Field
            name="safety_warnings"
            label="Ostrzeżenia dotyczące bezpieczeństwa"
            component={TextAreaField}
          />
          <Field
            name="possible_risks"
            label="Możliwe zagrożenia"
            component={TextAreaField}
          />
          <Field
            name="instructions_for_use"
            label="Instrukcje dotyczące użytkowania"
            component={TextAreaField}
          />
        </>
      )}
      <div className="create-table__footer">
        <Button
          type="save"
          text="Zapisz"
          disabled={pristine || !valid || submitting}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </form>
  );
};

export default connect(
  ({ product_group: { data: product_group } }) => ({
    product_group,
    initialValues: {
      is_dangerous: product_group.is_dangerous,
      safety_warnings: product_group.safety_warnings,
      possible_risks: product_group.possible_risks,
      instructions_for_use: product_group.instructions_for_use,
    },
  }),
  (dispatch) => ({
    updateProductGroup: (id, values) =>
      dispatch(updateProductGroup(id, values)),
  })
)(
  reduxForm({
    form: "product_dangerous_info",
    enableReinitialize: true,
  })(ProductGroupPanel)
);
