const section_type_options = [
  {
    value: "DescriptionSection::ImageTextSection",
    label: "Grafika | Tekst",
  },
  {
    value: "DescriptionSection::TextImageSection",
    label: "Tekst | Grafika",
  },
  {
    value: "DescriptionSection::ImageSection",
    label: "Grafika",
  },
  {
    value: "DescriptionSection::TextSection",
    label: "Tekst",
  },
  {
    value: "DescriptionSection::BundleComponentsSection",
    label: "Komponenty bundla",
  },
];

export default section_type_options;
