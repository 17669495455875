import React from "react";
import { connect } from "react-redux";
import { Button, Select, Input } from "expano-components";
import { Field, reduxForm } from "redux-form";
import { updateProduct } from "actions/product_group/product";

const price_group_label_options = [
  { label: "PG20", value: "PG20" },
  { label: "PG50", value: "PG50" },
  { label: "PG100", value: "PG100" },
  { label: "PGMAX", value: "PGMAX" },
];

const ProductPackageForm = ({
  valid,
  bundle_weight,
  change,
  pristine,
  submitting,
  product_id,
  handleSubmit,
  updateProduct,
  initialValues,
}) => {
  const onSubmit = (values) => updateProduct(product_id, values);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <table>
        <tbody>
          <tr>
            <td>Waga</td>
            <td>
              <Field
                name="weight"
                component={Input}
                type="number"
                postfix="kg"
              />
            </td>
            {bundle_weight ? (
              <td>
                <span>Waga bundla: {bundle_weight}kg</span>
                <button
                  type="button"
                  className="btn btn-sm btn-blue m-l-10"
                  onClick={() => change("weight", bundle_weight)}
                >
                  Uzupełnij
                </button>
              </td>
            ) : (
              <td />
            )}

            <td />
          </tr>
          <tr>
            <td>Wysokość</td>
            <td>
              <Field
                name="package_height"
                component={Input}
                type="number"
                postfix="cm"
              />
            </td>
            <td />
            <td />
          </tr>
          <tr>
            <td>Szerokość</td>
            <td>
              <Field
                name="package_width"
                component={Input}
                type="number"
                postfix="cm"
              />
            </td>
            <td />
            <td />
          </tr>
          <tr>
            <td>Głębokość</td>
            <td>
              <Field
                name="package_depth"
                component={Input}
                postfix="cm"
                type="number"
              />
            </td>
            <td />
            <td />
          </tr>
          <tr>
            <td>
              <p>Kod GPC</p>
              <p className="text-info">Wymagany do generowania GTIN (EAN)</p>
            </td>
            <td>
              <Field name="gpc_code" component={Input} type="text" />
            </td>
            <td />
            <td />
          </tr>
          <tr>
            <td>Grupa cenowa</td>
            <td>
              <Field
                name="price_group_label"
                component={Select}
                options={price_group_label_options}
                defaultValue={price_group_label_options?.find(
                  ({ value }) => value === initialValues.price_group_label
                )}
                handleChange={(value) => change("price_group_label", value)}
              />
            </td>
            <td />
            <td />
          </tr>
        </tbody>
      </table>
      <div className="create-table__footer">
        <Button
          type="save"
          text="Zapisz"
          disabled={pristine || !valid || submitting}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </form>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateProduct: (id, values) => dispatch(updateProduct(id, values)),
});

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm({
    enableReinitialize: true,
  })(ProductPackageForm)
);
