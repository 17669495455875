import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Label, Select } from "expano-components";
import { Modal } from "components";
import { copyGalleryImagesFromChannelProductGroup } from "actions/product_group/channel_product_gallery_image";

const ImportGalleryModal = ({
  channel_product_groups,
  channel_product_group_id,
  handleClose,
  copyGalleryImagesFromChannelProductGroup,
}) => {
  const [is_importing, setImporting] = useState(false);
  const [cloned_channel_product_group_id, setClonedChannelProductGroupId] =
    useState(null);

  const handleImport = async () => {
    try {
      setImporting(true);
      await copyGalleryImagesFromChannelProductGroup({
        channel_product_group_id,
        cloned_channel_product_group_id,
      });
      setImporting(false);
      handleClose();
    } catch (error) {
      setImporting(false);
    }
  };

  const renderChannelOptions = ({ id, channel }) => ({
    label: (
      <>
        <Label
          type="double"
          left={{
            text: channel.shop?.label_text,
            color: `#${channel.shop?.label_color}`,
          }}
          right={{
            text: channel.platform?.label_text,
            color: `#${channel.platform?.label_color}`,
          }}
        />
        <span>{channel.identifier}</span>
      </>
    ),
    value: id,
  });

  const options = [
    {
      label: "Amazon",
      options: channel_product_groups
        .filter(({ channel: { platform } }) =>
          [
            4, 6, 22, 23, 24, 26, 34, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46,
            47,
          ].includes(platform.id)
        )
        .map(renderChannelOptions),
    },
    {
      label: "Allegro",
      options: channel_product_groups
        .filter(({ channel: { platform } }) =>
          [3, 8, 31, 32, 35, 36].includes(platform.id)
        )
        .map(renderChannelOptions),
    },
    {
      label: "Ebay",
      options: channel_product_groups
        .filter(({ channel: { platform } }) =>
          [1, 16, 17, 19, 27, 28].includes(platform.id)
        )
        .map(renderChannelOptions),
    },
    {
      label: "Erli",
      options: channel_product_groups
        .filter(({ channel: { platform } }) => [9, 33].includes(platform.id))
        .map(renderChannelOptions),
    },
    {
      label: "Wsf",
      options: channel_product_groups
        .filter(({ channel: { platform } }) =>
          [2, 7, 14, 15, 18, 20, 21, 25].includes(platform.id)
        )
        .map(renderChannelOptions),
    },
  ];

  return (
    <Modal
      header={{ title: "Importuj galerię produktową" }}
      handleClose={handleClose}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="save"
            disabled={is_importing || !cloned_channel_product_group_id}
            text={is_importing ? "Proszę czekać..." : "Importuj"}
            onClick={handleImport}
          />
        </>
      }
    >
      <Select
        label="Wybierz kanał do sklonowania"
        handleChange={(value) => setClonedChannelProductGroupId(value)}
        options={options}
      />
    </Modal>
  );
};

export default connect(
  ({ product_group }) => ({
    channel_product_groups: product_group.data?.channel_product_groups,
  }),
  (dispatch) => ({
    copyGalleryImagesFromChannelProductGroup: (values) =>
      dispatch(copyGalleryImagesFromChannelProductGroup(values)),
  })
)(ImportGalleryModal);
