import React, { useState } from "react";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { Button } from "expano-components";
import { stringifyQuery } from "utils/api";
import { PanelLoader } from "components";
import useQueryHook from "utils/useQueryHook";
import {
  PlatformTaxonItem,
  AddPlatformTaxonModal,
} from "./components";

const query = {
  context: "ui_product_group",
  per_page: 30,
};

const PlatformTaxonsList = ({
  match: {
    params: { product_group_id, type_id },
  },
}) => {
  const [
    is_open_add_platform_taxon_modal,
    handleOpenPlatformTaxonModal,
  ] = useState(false);

  const {
    is_loading,
    is_fetching,
    data: { data },
    refetch,
  } = useQueryHook({
    query_keys: [`prices_${type_id}`],
    path: `/platform_taxon_product_groups?${stringifyQuery({
      ...query,
      "q[product_group_id_eq]": product_group_id,
      "q[platform_taxon_platform_id_eq]": type_id,
    })}`,
    enabled: !!type_id,
  });

  if (!type_id) {
    return null;
  }

  if (is_loading) {
    return <PanelLoader />;
  }

  return (
    <div
      className={classNames("dashboard box-full", {
        fetching: is_fetching,
      })}>
      {is_open_add_platform_taxon_modal && (
        <AddPlatformTaxonModal
          platform_id={type_id}
          product_group_id={product_group_id}
          refetch={refetch}
          handleClose={() =>
            handleOpenPlatformTaxonModal(false)
          }
        />
      )}
      <header className="dashboard__header dashboard__header--right">
        <Button
          type="add"
          text="Dodaj kategorię"
          onClick={() => handleOpenPlatformTaxonModal(true)}
        />
      </header>
      {data?.length > 0 && (
        <div className="dashboard__body">
          <ul className="platform-taxons-list">
            {data
              .sort((item, next) => item.id - next.id)
              .map((item, index) => (
                <PlatformTaxonItem
                  key={item.id}
                  index={index + 1}
                  refetch={refetch}
                  {...item}
                />
              ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default withRouter(PlatformTaxonsList);
