import React, { useState } from "react";
import { connect } from "react-redux";
import { checkProductCardView } from "utils/helpers";
import GalleryItem from "./components/GalleryItem";

import { ReactComponent as GalleryIcon } from "icons/gallery.svg";
import ImportGalleryModal from "./components/ImportGalleryModal";

const Gallery = ({ channel_product_group_id, channel, channel_products }) => {
  const [is_open_import_gallery_modal, setOpenImportGalleryModal] =
    useState(false);
  const show_gallery = checkProductCardView({
    type: "products_gallery",
    channel_type: channel?.type,
  });
  return (
    <div className="dashboard">
      {is_open_import_gallery_modal && (
        <ImportGalleryModal
          channel_product_group_id={channel_product_group_id}
          handleClose={() => setOpenImportGalleryModal(false)}
        />
      )}
      <header className="dashboard__header dashboard__header--edit">
        <div className="icon__container">
          <GalleryIcon />
        </div>
        <h2 className="dashboard__title heading">Galeria zdjęć</h2>
        <button
          type="button"
          className="btn btn-sm btn-blue"
          onClick={() => setOpenImportGalleryModal(true)}
        >
          <span>Importuj galerię</span>
        </button>
      </header>
      <div className="photo-gallery">
        <ul className="gallery-list">
          {show_gallery &&
            channel_products.map(
              ({
                product,
                id,
                channel_product_gallery_images,
                channel_product360_images,
              }) => (
                <GalleryItem
                  data={product}
                  key={id}
                  id={id}
                  images={channel_product_gallery_images}
                  gallery360={channel_product360_images}
                />
              )
            )}
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  channel_product_group: {
    data: { id, channel_products, channel },
  },
}) => ({
  channel_products,
  channel,
  channel_product_group_id: id,
});

export default connect(mapStateToProps, null)(Gallery);
