import React, { useState, useEffect } from "react";
import {
  Field,
  reduxForm,
  SubmissionError,
} from "redux-form";
import { connect } from "react-redux";
import {
  Button,
  ModalDelete,
  Input,
} from "expano-components";
import { Main, PageLoader, Dashboard } from "components";
import {
  getProperty,
  updateProperty,
  deleteProperty,
  resetProperty,
} from "actions/properties";
import property_langs from "utils/property_langs";

import AddPropertyOption from "./components/AddPropertyOption";
import EditPropertyOptionRow from "./components/EditPropertyOptionRow";

import { ReactComponent as PropertyIcon } from "icons/property.svg";
import { ReactComponent as ListIcon } from "icons/list.svg";

const InputField = (props) => <Input {...props} />;

const PropertyEdit = ({
  match: {
    params: { id },
  },
  history,
  submitting,
  handleSubmit,
  pristine,
  property,
  initialValues,
  getProperty,
  resetProperty,
  updateProperty,
}) => {
  const [active_tab, setTab] = useState("pl");
  const [show_delete_modal, setShowDeleteModal] = useState(
    false
  );
  const [
    show_add_property_option_form,
    setShowAddPropertyOptionForm,
  ] = useState(false);

  useEffect(() => {
    getProperty(id);
    return () => resetProperty();
  }, [id]);

  const onSubmit = (values) => {
    const data = {
      internal_name: values.internal_name,
    };
    Object.keys(values.external_name).map((code) => {
      data[`external_name_${code}`] =
        values.external_name[code];
      return data;
    });
    return updateProperty(id, data).catch((err) => {
      throw new SubmissionError(err);
    });
  };

  const handleDelete = async () => {
    await deleteProperty(id);
    setShowDeleteModal(false);
    history.push("/properties");
  };

  if (
    ["invalid", "loading", "failure"].includes(
      property.status
    )
  ) {
    return <PageLoader />;
  }

  const { property_options, internal_name } = property.data;

  return (
    <Main
      page={{
        name: "Właściwości",
        icon: <PropertyIcon />,
        breadcrumbs: [
          {
            name: "Właściwości",
            path: "/properties",
          },
          { name: internal_name },
        ],
        buttons: (
          <Button
            type="delete"
            text="Usuń właściwość"
            onClick={() => setShowDeleteModal(true)}
          />
        ),
      }}>
      {show_delete_modal && (
        <ModalDelete
          handleDelete={handleDelete}
          handleClose={() => setShowDeleteModal(false)}
          name={`właściwość ${internal_name}?`}
        />
      )}

      <Dashboard
        footer={
          <Button
            type="save"
            text="Zapisz"
            disabled={pristine || submitting}
            onClick={handleSubmit(onSubmit)}
          />
        }>
        <form
          className="dashboard__form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate>
          <Field
            name="internal_name"
            label="Nazwa wewnętrzna"
            component={InputField}
            type="text"
          />
          <div className="languages-form">
            <ul className="lang-tabs">
              {property_langs.map(
                ({ code, name, flag }) => (
                  <li
                    key={code}
                    className={`lang-tabs__item${
                      active_tab === code ? " active" : ""
                    }`}>
                    <button
                      className="lang-tabs__item__button"
                      type="button"
                      onClick={() => {
                        setTab(code);
                        setTimeout(() => {
                          document
                            .querySelector(
                              ".lang-panels__item.active input"
                            )
                            .focus();
                        }, 100);
                      }}>
                      <span
                        className={`lang-tabs__item__flag flag-icon flag-icon-${flag}`}
                      />
                      <span className="lang-tabs__item__title">
                        {name}
                      </span>
                    </button>
                  </li>
                )
              )}
            </ul>
            <div className="lang-panels">
              {property_langs.map(({ code }) => (
                <div
                  key={code}
                  className={`lang-panels__item ${
                    active_tab === code ? " active" : ""
                  }`}>
                  <Field
                    name={`external_name[${code}]`}
                    label={`Nazwa zewnętrzna - ${code}`}
                    component={InputField}
                    type="text"
                  />
                </div>
              ))}
            </div>
          </div>
        </form>
      </Dashboard>
      <Dashboard
        header={{
          icon: <ListIcon />,
          title: "Wartość właściwości",
        }}>
        <table className="table">
          <thead className="table__header table-head">
            <tr>
              {property_langs.map(
                ({ code, name, flag }) => (
                  <th key={code} width="25%">
                    <span
                      className={`flag-mr-10 flag-icon flag-icon-${flag}`}
                    />
                    {name}
                  </th>
                )
              )}
              <th>Działania</th>
            </tr>
          </thead>
          {property_options?.length > 0 && (
            <tbody className="table__body table-body">
              {property_options.map((item) => (
                <EditPropertyOptionRow
                  key={item.id}
                  form={`edit-property-option_${item.id}`}
                  initialValues={item}
                  id={item.id}
                />
              ))}
            </tbody>
          )}
        </table>

        {show_add_property_option_form ? (
          <AddPropertyOption
            langs={property_langs}
            id={id}
            handleClose={() =>
              setShowAddPropertyOptionForm(false)
            }
          />
        ) : (
          <div className="property-options__show">
            <Button
              type="add"
              text="Dodaj wartość właściwości"
              onClick={() =>
                setShowAddPropertyOptionForm(true)
              }
            />
          </div>
        )}
      </Dashboard>
    </Main>
  );
};

const mapStateToProps = ({ property }) => ({
  property,
  initialValues: {
    internal_name: property.data?.internal_name,
    external_name: property.data?.external_name,

  },
});
const mapDispatchToProps = (dispatch) => ({
  resetProperty: () => dispatch(resetProperty()),
  getProperty: (id) => dispatch(getProperty(id)),
  updateProperty: (id, values) =>
    dispatch(updateProperty(id, values)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "edit-property",
    enableReinitialize: true,
  })(PropertyEdit)
);
