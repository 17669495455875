import React from "react";
import { withRouter } from "react-router-dom";
import { parseQuery, stringifyQuery } from "utils/helpers";
const Head = ({ data, location, history, sort }) => {
  const query = parseQuery(location.search);
  const column = sort["sort[column]"];
  const order = sort["sort[order]"];
  return (
    <thead className='main-table__head'>
      <tr>
        {data.map(({ label, name, onChange, sortable = true, style = {} }) => {
          if (name === "check_all") {
            return (
              <th key={name} style={{ width: 60 }}>
                <div className='form-group'>
                  <div className='check-group' style={{ margin: 0 }}>
                    <input id='check_all' type='checkbox' onChange={onChange} />
                    <label htmlFor='check_all' className='label-form' style={{ minHeight: 16, margin: 0 }} />
                  </div>
                </div>
              </th>
            );
          }
          return (
            <th
              key={name}
              style={{ ...style, cursor: sortable ? "pointer" : "default" }}
              onClick={() =>
                sortable &&
                history.push(
                  "?" + stringifyQuery({ ...query, page: undefined, "sort[column]": name, "sort[order]": !order || order === "asc" ? "desc" : "asc" })
                )
              }
            >
              <span>{label}</span>
              {sortable && (
                <div className='sort-icons-group'>
                  <svg width='8px' height='6.13px' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512'>
                    <path
                      fill={name === column && order === "asc" ? "#5D78FF" : "#CED6FD"}
                      d='M507.521,427.394L282.655,52.617c-12.074-20.122-41.237-20.122-53.311,0L4.479,427.394
			c-12.433,20.72,2.493,47.08,26.655,47.08h449.732C505.029,474.474,519.955,448.114,507.521,427.394z'
                    />
                  </svg>
                  <svg
                    width='8px'
                    height='6.13px'
                    style={{ transform: "rotate(180deg)" }}
                    xmlns='http://www.w3.org/2000/svg'
                    x='0px'
                    y='0px'
                    viewBox='0 0 512 512'
                  >
                    <path
                      fill={name === column && order === "desc" ? "#5D78FF" : "#CED6FD"}
                      d='M507.521,427.394L282.655,52.617c-12.074-20.122-41.237-20.122-53.311,0L4.479,427.394
			c-12.433,20.72,2.493,47.08,26.655,47.08h449.732C505.029,474.474,519.955,448.114,507.521,427.394z'
                    />
                  </svg>
                </div>
              )}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default withRouter(Head);
