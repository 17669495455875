import React from "react";
import { Label } from "expano-components";

const Header = ({ identifier, shop, platform }) => (
  <div className="dashboard dashboard--nav">
    <header className="dashboard__header dashboard__header--edit dashboard__header--edit--nav">
      <Label
        type="double"
        left={{
          text: shop?.label_text,
          color: `#${shop?.label_color}`,
        }}
        right={{
          text: platform?.label_text,
          color: `#${platform?.label_color}`,
        }}
      />
      <h2 className="dashboard__title heading">
        {identifier}
      </h2>
    </header>
  </div>
);
export default Header;
