import React from "react";
import {
  Field,
  reduxForm,
  SubmissionError,
} from "redux-form";
import { Button, Input } from "expano-components";

import { Main, Dashboard } from "components";

import { createPrototype } from "actions/prototypes";

import { ReactComponent as PlatformIcon } from "icons/platform.svg";

const InputField = (props) => <Input {...props} />;

const PrototypeNew = ({
  handleSubmit,
  pristine,
  history,
}) => {
  const onSubmit = (values) =>
    createPrototype(values)
      .then((id) => {
        history.push(`/prototypes/${id}`);
      })
      .catch((err) => {
        throw new SubmissionError(err);
      });

  return (
    <Main
      page={{
        name: "Prototypy",
        icon: <PlatformIcon />,
        breadcrumbs: [
          {
            name: "Prototypy",
            path: "/prototypes",
          },
          { name: "Dodaj prototyp" },
        ],
      }}>
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <Dashboard
            footer={
              <>
                <Button
                  type="cancel"
                  onClick={() =>
                    history.push("/prototypes")
                  }
                />
                <Button
                  type="save"
                  disabled={pristine}
                  onClick={handleSubmit(onSubmit)}
                />
              </>
            }>
            <form
              className="dashboard__form"
              onSubmit={handleSubmit(onSubmit)}
              noValidate>
              <Field
                name="name"
                label="Nazwa"
                component={InputField}
                type="text"
              />
            </form>
          </Dashboard>
        </div>
      </div>
    </Main>
  );
};

export default reduxForm({
  form: "create-prototype",
  enableReinitialize: true,
})(PrototypeNew);
