import { toastr } from "react-redux-toastr";
import { client } from "utils/api";

export const getSettings = () => (dispatch) => {
  dispatch({ type: "SETTINGS_LOADING" });
  return new Promise(async (resolve, reject) => {
    try {
      const [
        { data: platforms },
        { data: shops },
        { data: channels },
      ] = await Promise.all([
        client.get(
          "/platforms?context=ui_settings&per_page=200"
        ),
        client.get(
          "/shops?context=ui_settings&per_page=200"
        ),
        client.get(
          "/channels?context=ui_settings&per_page=200"
        ),
      ]);
      dispatch({
        type: "SETTINGS_SUCCESS",
        data: {
          platforms,
          shops,
          channels,
        },
      });
      resolve();
    } catch (error) {
      dispatch({ type: "SETTINGS_FAILURE" });
      toastr.error(
        "Błąd",
        "Wystąpił błąd podczas pobierania"
      );
      reject();
    }
  });
};

export const importShoploVariants = (channel_id, file) =>
  new Promise(async (resolve, reject) => {
    let body = new FormData();
    body.append(`file`, file);
    await client
      .post(
        `/shoplo/channels/${channel_id}/import_variants`,
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(() => {
        toastr.success("Suckes", "Pomyślnie zaimportowano");
        resolve();
      })
      .catch((err) => {
        toastr.error(
          "Błąd",
          "Wystąpił błąd podczas importowania"
        );
        reject(err.response.data.error);
      });
  });
