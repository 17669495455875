import React from "react";
import { Button } from "expano-components";
import { Modal } from "components";
import { markAsFeedProductImage } from "actions/product_group/channel_product_gallery_image";

import { ReactComponent as DangerIcon } from "icons/danger.svg";
import { connect } from "react-redux";

const ModalFeedImage = ({
  handleClose,
  product_data,
  image,
  id,
  markAsFeedProductImage
}) => {
  const handleSubmit = async () => {
    markAsFeedProductImage(id)
      .then(handleClose)
      .catch((err) => console.log(err));
  };
  return (
    <Modal
      handleClose={handleClose}
      header={{ title: "Uwaga!", icon: <DangerIcon /> }}
      footer={
        <>
          <Button
            type="cancel"
            text="Nie"
            onClick={handleClose}
          />
          <button
            type="button"
            style={{ width: 100, justifyContent: "center" }}
            className="btn btn-sm btn-green"
            onClick={handleSubmit}>
            <span>Tak</span>
          </button>
        </>
      }>
      <div className="global_image_modal-box">
        <div className="image">
          <img src={image} alt="" />
        </div>
        <div className="text">
          <p>
            To zdjęcie zostanie ustawione jako feed dla:
          </p>
          <p className="product-name">
            {product_data?.name}
          </p>
          <p className="sku">{product_data?.sku}</p>
        </div>
      </div>
      <p>Czy na pewno chcesz to zrobić?</p>
    </Modal>
  );
};

export default connect(null, (dispatch) => ({
  markAsFeedProductImage: (id) =>
    dispatch(markAsFeedProductImage(id)),
}))(ModalFeedImage);
