import React, { useState, useEffect, useRef } from "react";

const ActionsGroup = ({ options }) => {
  const dropdown = useRef(null);
  const [is_open, handleOpen] = useState(false);
  useEffect(() => {
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown]);
  useEffect(() => {
    if (is_open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [is_open]);
  const handleClickOutside = event => {
    if (dropdown.current && !dropdown.current.contains(event.target)) {
      handleOpen(false);
    }
  };

  return (
    <div className='actions-group' ref={dropdown}>
      <button
        type='button'
        className='btn-actions'
        onClick={() => {
          handleOpen(!is_open);
        }}
      >
        <div className='dot' />
        <div className='dot' />
        <div className='dot' />
      </button>
      {is_open && options?.length > 0 && (
        <ul className='actions-dropdown'>
          {options?.map(({ label, icon, onClick }, index) => (
            <li key={index}>
              <button className='actions-dropdown__item' onClick={onClick}>
                {icon}
                <span>{label}</span>
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ActionsGroup;
