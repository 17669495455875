import React from "react";
import { Button } from "expano-components";
import { Images, Text, Header } from "../components";

const TextImageSection = ({
  id,
  content,
  type,
  description_section_comments,
  position,
  handleDelete,
  description_section_products,
  disabled,
  inherited,
}) => {
  const images_data = {
    description_section_products: description_section_products?.filter(
      ({ product: { status } }) => status !== "discontinued"
    ),
  };

  return (
    <div
      className={`dashboard product_card_section ${
        disabled ? "disabled" : ""
      }`}>
      <Header
        id={id}
        attributes={{ position, type }}
        inherited={inherited}
      />
      <div className="product_card_section__content product_card_section__group">
        <div className="dashboard__form">
          <div className="product_card_section__wrapper">
            <div className="section-group__text">
              <Text
                form={`text_image_${id}`}
                initialValues={{ content }}
                content={content}
                id={id}
                description_section_comments={
                  description_section_comments
                }
                position={position}
                inherited={inherited}
              />
            </div>
            <Images {...images_data} />
          </div>
        </div>
        <div className="dashboard__form__footer">
          <div className="box">
            <Button
              type="delete"
              text="Usuń"
              onClick={() => handleDelete({ id, position })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextImageSection;
