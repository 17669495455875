import React from "react";
import AsidePanel from "./components/AsidePanel";
import ProductGroupPricesPanel from "./components/ProductGroupPricesPanel";
import ProductPricesPanel from "./components/ProductPricesPanel";

const Price = () => (
  <div className="main-wrapper">
    <div className="box-300">
      <AsidePanel />
    </div>
    <ProductGroupPricesPanel />
    <ProductPricesPanel />
  </div>
);

export default Price;
