import React from "react";
import {useDrag} from "react-dnd";

const OrderListItem = ({order, type, id, isDropped}) => {

  const [{opacity, background}, drag] = useDrag({
    item: {order, type, id},
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
      background: monitor.isDragging()
        ? "rgba(40, 42, 60, 1)"
        : "rgba(89, 125, 255, 1)",
    }),
  });
  
  return (
    <div
      className="rotate_list__item popup-rotate-box"
      ref={drag}
      style={{
        opacity: isDropped ? 0.4 : opacity,
        background: isDropped ? "rgba(89, 125, 255, 1)" : background,
        pointerEvents: isDropped ? "none" : "auto",
      }}
    >
      {order + 1}
    </div>
  );
};
export default OrderListItem;
