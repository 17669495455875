import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ReactComponent as PIcon } from "icons/p-square.svg";

const AsidePanel = ({
  products,
  history,
  match: {
    params: { product_group_id, type_id },
  },
}) => {
  return (
    <ul className="product-list">
      {products.length > 0 &&
        products.map(({ id, name, sku }) => (
          <li
            className={`product-list__item ${
              Number(type_id) === id ? "active" : ""
            }`}
            key={id}
            onClick={() =>
              history.push(
                `/product_groups/${product_group_id}/aliases/products/${id}`
              )
            }>
            <div className="item-icon">
              <PIcon />
            </div>
            <div className="item-text">
              <p className="title">{name}</p>
              <p>{sku}</p>
            </div>
          </li>
        ))}
    </ul>
  );
};

export default connect(
  ({
    product_group: {
      data: { products },
    },
  }) => ({
    products,
  }),
  null
)(withRouter(AsidePanel));
