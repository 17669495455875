import classNames from "classnames";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  Pagination,
  ModalUploadMultiple,
  ModalDelete,
  Select,
} from "expano-components";
import { PanelLoader } from "components";
import {
  uploadProductImages,
  updateProductImage,
  deleteProductImages,
  setProductGlobalImage,
  setProductNeutralImage,
  setProductForBundleImage
} from "actions/product_group/product_images";
import useQueryHook from "utils/useQueryHook";
import { stringifyQuery, parseQuery } from "utils/api";

import SingleImageRow from "./SingleImageRow";

const query = {
  per_page: 10,
  context: "ui_product_group",
};

const ProductImagesList = ({
  location,
  history,
  match: {
    params: { type_id: product_id, product_group_id },
  },
  products,
}) => {
  const [active_images, setActiveImages] = useState([]);
  const [show_upload_modal, showUploadModal] = useState(false);
  const [show_delete_modal, showDeleteModal] = useState(false);

  useEffect(() => {
    setActiveImages([]);
  }, [product_id]);

  const { page, per_page = 10 } = parseQuery(location.search);

  const product = products?.find(({ id }) => id === Number(product_id));

  const components_options = product?.components?.map(
    ({ id, name, sku, product_group }) => ({
      label: `[${sku}] ${name}`,
      value: `/product_groups/${product_group?.id}/images/products/${id}`,
    })
  );

  const {
    is_loading,
    is_fetching,
    refetch,
    data: { data, meta },
  } = useQueryHook({
    query_keys: [`product_images_${product_id}`, page, per_page],
    path: `/product_images?${stringifyQuery({
      ...query,
      page,
      per_page,
      "q[product_id_eq]": product_id,
    })}`,
  });

  const handleUploadImages = async (files) => {
    await uploadProductImages({
      product_id,
      files,
    });
    showUploadModal(false);
    refetch();
  };

  const handleUpdateImage = async (id, image) => {
    await updateProductImage(id, image);
    refetch();
  };

  const handleDeleteSelectedImages = async () => {
    await deleteProductImages(active_images);
    setActiveImages([]);
    showDeleteModal(false);
    refetch();
  };

  const handleSetGlobalImage = async (id) => {
    await setProductGlobalImage(id);
    refetch();
  };

  const handleSetProductNeutralImage = async (id, value) => {
    await setProductNeutralImage(id, value);
    refetch();
  };

  const handleSetProductForBundleImage = async (id, value) => {
    await setProductForBundleImage(id, value);
    refetch();
  };

  const handleDeleteImage = async (id) => {
    await deleteProductImages([id]);
    refetch();
  };

  const handleSetActiveImage = (id) => {
    setActiveImages(
      active_images.includes(id)
        ? [...active_images].filter((item_id) => item_id !== id)
        : [...active_images, id]
    );
  };

  if (is_loading || (data.length === 0 && is_fetching)) {
    return <PanelLoader />;
  }

  return (
    <div
      className={classNames("dashboard box-full", {
        fetching: is_fetching,
      })}
    >
      {show_delete_modal && (
        <ModalDelete
          handleDelete={handleDeleteSelectedImages}
          handleClose={() => showDeleteModal(false)}
          name="zaznaczone zdjęcia"
        />
      )}
      {show_upload_modal && (
        <ModalUploadMultiple
          headerText="Wgraj zdjęcia"
          handleUpload={handleUploadImages}
          handleClose={() => showUploadModal(false)}
        />
      )}
      <header className="dashboard__header h-space">
        <div className="df v-center">
          {product?.is_bundle ? (
            <div style={{ width: 240 }}>
              <Select
                searchable={false}
                options={[
                  { label: "Wszystkie", value: "all" },
                  ...components_options,
                ]}
                defaultValue={{
                  label: "Komponenty podzespołu",
                  value: null,
                }}
                handleChange={(value) => {
                  if (value === "all") {
                    for (const component of product.components) {
                      if (component.product_group?.id) {
                        window.open(
                          `/product_groups/${component.product_group.id}/images/products/${component.id}`,
                          "_blank"
                        );
                      }
                    }
                  } else {
                    window.open(value, "_blank");
                  }
                }}
              />
            </div>
          ) : null}
        </div>
        <div className="df v-center">
          {active_images.length > 0 && (
            <Button
              className="btn-sm btn-pink m-r-20"
              type="delete"
              text="Usuń zaznaczone"
              onClick={() => showDeleteModal(true)}
            />
          )}
          <Button type="upload" onClick={() => showUploadModal(true)} />
        </div>

        {/* <StatusSelect
          label="Status zdjęć"
          options={[
            {
              label: {
                name: "Brak",
                color: "",
              },
              value: "empty",
            },
            {
              label: {
                name: "Do zrobienia",
                color: "blue",
              },
              value: "to_do",
            },
            {
              label: {
                name: "W trakcie",
                color: "orange",
              },
              value: "in_progress",
            },
            {
              label: {
                name: "Gotowe",
                color: "green",
              },
              value: "ready",
            },
          ]}
        /> */}
      </header>
      {data.length > 0 ? (
        <table className="table">
          <thead className="table__header ntb table-head">
            <tr>
              <th width="80px">
                <div className="form-group">
                  <div className="check-group" style={{ margin: 0 }}>
                    <input
                      id="check_all"
                      checked={
                        data.length > 0 && data.length === active_images.length
                      }
                      type="checkbox"
                      onChange={({ target: { checked } }) => {
                        setActiveImages(
                          checked ? data.map(({ id }) => id) : []
                        );
                      }}
                    />
                    <label
                      htmlFor="check_all"
                      className="label-form"
                      style={{
                        minHeight: 16,
                        margin: 0,
                      }}
                    />
                  </div>
                </div>
              </th>
              <th width="120px">Podgląd</th>
              <th width="50px">ID</th>
              <th>Plik</th>
              <th width="100px">Działania</th>
            </tr>
          </thead>
          <tbody className="table__body table-body">
            {data.map((item) => (
              <SingleImageRow
                key={item.id}
                {...item}
                handleDelete={handleDeleteImage}
                handleUpdate={handleUpdateImage}
                handleSetGlobalImage={handleSetGlobalImage}
                handleSetActiveImage={handleSetActiveImage}
                handleSetProductNeutralImage={handleSetProductNeutralImage}
                handleSetProductForBundleImage={handleSetProductForBundleImage}
                product_data={item.product}
                is_checked={active_images.includes(item.id)}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <div style={{ padding: 20 }}>
          <p>Brak zdjęć</p>
        </div>
      )}
      <Pagination
        data={meta}
        history={history}
        location={location}
        handlePaginate={() => null}
      />
    </div>
  );
};

export default connect(
  ({
    product_group: {
      data: { products },
    },
  }) => ({
    products,
  })
)(withRouter(ProductImagesList));
