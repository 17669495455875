import React, { useState } from "react";
import { Input, Label } from "expano-components";
import { ReactComponent as WebIcon } from "icons/web.svg";
import { exportPriceToChannel } from "actions/product_group/channel_product";

const getChannelProductsByPlatformType = (data, platform_type) =>
  data
    .filter(({ channel: { platform } }) => platform?.type === platform_type)
    .sort((a, b) => a.channel.id - b.channel.id);

const ChannelPricesPanel = ({ data }) => {
  const [loading_id, setLoadingId] = useState(null);
  const allegro_channel_products = getChannelProductsByPlatformType(
    data,
    "Allegro::Platform"
  );

  const amazon_channel_products = getChannelProductsByPlatformType(
    data,
    "Amazon::Platform"
  );

  const ebay_channel_products = getChannelProductsByPlatformType(
    data,
    "Ebay::Platform"
  );

  const wsf_channel_products = getChannelProductsByPlatformType(
    data,
    "Wsf::Platform"
  );

  const erli_channel_products = getChannelProductsByPlatformType(
    data,
    "Erli::Platform"
  );

  return (
    <div className="p-30">
      {[
        { name: "Allegro", items: allegro_channel_products },
        { name: "Amazon", items: amazon_channel_products },
        { name: "Ebay", items: ebay_channel_products },
        { name: "Wsf", items: wsf_channel_products },
        { name: "Erli", items: erli_channel_products },
      ].map(({ name, items }) => (
        <div className="m-b-20" key={name}>
          {items?.[0]?.id && (
            <Label
              type="fill"
              text={name}
              color={items?.[0]?.channel?.platform?.label_color}
            />
          )}
          <div className="row wrap">
            {items.map(
              ({ id, main_offer_url, main_offer_price, price, channel }) => {
                const has_offer_url = Boolean(main_offer_url);
                const is_loading = loading_id === id;
                return (
                  <div className="box-2 channel-price-group" key={id}>
                    <div className="row p-10 ai-fe m-t-10">
                      <Input
                        label={
                          <p className="offer-form-label">
                            <span>{channel?.identifier}</span>
                            {has_offer_url && (
                              <a
                                href={main_offer_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn-offer"
                              >
                                <WebIcon />
                              </a>
                            )}
                          </p>
                        }
                        meta={{}}
                        postfix={channel?.platform?.currency_code}
                        input={{
                          name: id,
                          value: price || "",
                          readOnly: true,
                        }}
                      />
                      {has_offer_url && (
                        <button
                          type="button"
                          className="m-l-10 btn btn-sm btn-blue nowrap"
                          disabled={is_loading || price === main_offer_price}
                          onClick={async () => {
                            try {
                              setLoadingId(id);
                              await exportPriceToChannel(id);
                            } catch (error) {
                              console.log(error);
                            } finally {
                              setLoadingId(null);
                            }
                          }}
                        >
                          <span>Eks.</span>
                        </button>
                      )}
                    </div>
                    <p className="text-muted">
                      Mnożnik: {channel?.price_reference_multiplier}
                    </p>
                  </div>
                );
              }
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChannelPricesPanel;
