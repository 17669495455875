import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { ReactComponent as PGIcon } from "icons/pg-square.svg";
import { ReactComponent as PIcon } from "icons/p-square.svg";

const AsidePanel = ({
  products,
  product_group,
  match: {
    params: { type, type_id, product_group_id },
  },
  history,
}) => {
  return (
    <ul className="product-list">
      <li
        className={`product-list__item ${!type_id ? "active" : ""}`}
        onClick={() =>
          history.push(`/product_groups/${product_group_id}/price`)
        }
      >
        <div className="item-icon">
          <PGIcon />
        </div>
        <div className="item-text">
          <p className="title">{product_group.name}</p>
          <p>{product_group.sku}</p>
        </div>
      </li>
      {products.map(({ id, name, sku }) => (
        <li
          className={`product-list__item ${
            type === "products" && id === Number(type_id) ? "active" : ""
          }`}
          key={id}
          onClick={() =>
            history.push(
              `/product_groups/${product_group_id}/price/products/${id}`
            )
          }
        >
          <div className="item-icon">
            <PIcon />
          </div>
          <div className="item-text">
            <p className="title">{name}</p>
            <p>{sku}</p>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default connect(
  ({
    product_group: {
      data: { channel_product_groups, products, ...product_group },
    },
  }) => ({
    channel_product_groups,
    products,
    product_group,
  })
)(withRouter(AsidePanel));
