import "suneditor/dist/css/suneditor.min.css";

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "expano-components";
// import { StatusSelect } from "components";
import { updateProductGroupMockup } from "actions/product_group/mockup";

import suneditor from "suneditor";
import plugins from "suneditor/src/plugins";

const ProductGroupMockup = ({
  id,
  mockup,
  updateProductGroupMockup,
}) => {
  const [value, setValue] = useState(mockup || "");

  useEffect(() => {
    let editor = suneditor.create("editor", {
      plugins: plugins,
      value: mockup || "",
      buttonList: [
        ["undo", "redo"],
        ["font", "fontSize", "formatBlock"],
        ["paragraphStyle", "blockquote"],
        [
          "bold",
          "underline",
          "italic",
          "strike",
          "subscript",
          "superscript",
        ],
        ["fontColor", "hiliteColor", "textStyle"],
        ["removeFormat"],
        ["outdent", "indent"],
        ["align", "horizontalRule", "list", "lineHeight"],
        ["table", "link", "image"],
        ["fullScreen", "showBlocks"],
      ],
    });
    editor.onChange = function(content) {
      setValue(content);
    };
  }, []);

  const handleSave = () =>
    updateProductGroupMockup(id, value);

  return (
    <div className="dashboard">
      {/* <header className="dashboard__header dashboard__header--right">
        <StatusSelect
          label="Status makiety"
          options={[
            {
              label: {
                name: "Brak",
                color: "",
              },
              value: "empty",
            },
            {
              label: {
                name: "Do zrobienia",
                color: "blue",
              },
              value: "to_do",
            },
            {
              label: {
                name: "W trakcie",
                color: "orange",
              },
              value: "in_progress",
            },
            {
              label: {
                name: "Gotowe",
                color: "green",
              },
              value: "ready",
            },
          ]}
        />
      </header> */}
      <div className="dashboard__body">
        <div id="editor" />
      </div>
      <div className="dashboard__form__footer">
        <Button
          type="save"
          text="Zapisz"
          onClick={handleSave}
        />
      </div>
    </div>
  );
};

export default connect(
  ({
    product_group: {
      data: { id, mockup },
    },
  }) => ({
    id,
    mockup,
  }),
  (dispatch) => ({
    updateProductGroupMockup: (id, mockup) =>
      dispatch(updateProductGroupMockup(id, mockup)),
  })
)(ProductGroupMockup);
