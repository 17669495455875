import React from "react";
import ListItem from "./components/ListItem";

const Tree = ({ head, row_data = [], data }) => {
  if (data?.length < 1) {
    return <p style={{ padding: "0 30px" }}>Brak</p>;
  }

  return (
    <>
      {head?.length > 0 && (
        <table className="table">
          <thead className="table__header table-head">
            <tr>
              {head.map((item, index) => (
                <th
                  key={index}
                  style={{
                    minWidth: index === 0 ? "400px" : "initial",
                    width: `${100 / head.length}%`,
                  }}
                >
                  {item}
                </th>
              ))}
            </tr>
          </thead>
        </table>
      )}
      {data?.length > 0 && (
        <div className="tree">
          <ul className="tree__list">
            {data.map((list_el) => (
              <ListItem
                key={list_el.id}
                name={list_el.name}
                id={list_el.id}
                row_data={row_data}
                level={0}
                tree_box="tree-minus"
                ancestry={list_el.ancestry}
                amazon_product_type_definitions={
                  list_el.amazon_product_type_definitions
                }
              />
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default Tree;
